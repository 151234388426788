import React from 'react'

const PremiumModal = props => {
    return (
        <div className="modal-container">
            <div className="modal-wrapper">
                <span className="icon-times-circle-outline" onClick={() => props.onClose()}/>
                <div className="modal-body">
                    <h1>Unser Angebot für Ihre Veranstaltungen</h1>
                    <p>Bei Fragen stehen wir Ihnen sehr gerne zur Verfügung!
                        +41 61 467 85 55, <a href="mailto:events@emh.ch">events@emh.ch</a></p>


                    <h2>Online Plus</h2>
                    <p>
                        Ihre Veranstaltung erscheint in <em>Swiss Medical Events</em> ganz oben und sticht farblich hervor. Ausserdem wird
                        Ihr Logo angezeigt.
                    </p>

                    <em>Laufzeit Online</em>
                    <p>Dieses Angebot läuft bis zum Datum Ihrer Veranstaltung.</p>
                    <em>Preis</em>
                    <p>800 CHF</p>
                    <em>Technische Daten Logo</em>
                    <p>
                        Masse: 150 px x 120 px
                        Auflösung: 72 dpi
                        Dateiformat: JPG, PNG
                        Dateigrösse: max. 100 KB
                    </p>
                    <h2>Online & Print Plus</h2>
                    <p>
                        Ihre Veranstaltung wird in <em>Swiss Medical Events</em> ganz oben farblich hervorgehoben, zusammen mit Ihrem Logo
                        publiziert. Zusätzlich wird Ihre Veranstaltung in einer Ausgabe des Swiss Medical Forum mit
                        hervorgehobenem
                        Text und in Farbe (orange) präsentiert.
                    </p>

                    <em>Swiss Medical Forum</em>
                    <p>Das Swiss Medical Forum ist die meistgelesene medizinische Fortbildungszeitschrift der Schweiz. Sie
                        erscheint 14-täglich und erreicht mehr als 38'000 Ärztinnen und Ärzte in der Schweiz.
                        <div><a href="https://emh.ch/fileadmin/content/pages/docs/SMF_Mediadaten_2019.pdf" target="_blank">Mehr Informationen</a></div>
                    </p>


                    <em>Laufzeit Online</em>
                    <p>Dieses Angebot läuft bis zum Datum Ihrer Veranstaltung.</p>

                    <em>Laufzeit Print</em>
                    <p>einmalig</p>

                    <em>Preis</em>
                    <p>1’000 CHF</p>

                    <em>Technische Daten Logo</em>
                    <p>
                        Masse: 150 px x 120 px
                        Auflösung: 72 dpi
                        Dateiformat: JPG, PNG,
                        Dateigrösse: max. 100 KB
                    </p>

                    <h2>Online & Print Deluxe </h2>
                    <p>
                        Zusätzlich zur hervorgehobenen Online- und Print-Präsenz, publizieren wir in der von Ihnen
                        gewünschten Ausgabe des Swiss Medical Forum ein von Ihnen gestaltetes Inserat (4-farbig, ¼ Seite
                        quer, 186 × 64 mm).
                    </p>

                    <em>Swiss Medical Forum</em>
                    <p>
                        Das Swiss Medical Forum ist die meistgelesene medizinische Fortbildungszeitschrift der Schweiz. Sie
                        erscheint 14-täglich und erreicht mehr als 38'000 Ärztinnen und Ärzte in der Schweiz.
                        <div><a href="https://emh.ch/fileadmin/content/pages/docs/SMF_Mediadaten_2019.pdf" target="_blank">Mehr Informationen</a></div>
                    </p>

                    <em>Laufzeit Online</em>
                    <p>Dieses Angebot läuft bis zum Datum Ihrer Veranstaltung.</p>
                    <em>Laufzeit Inserat und Hervorhebung</em>
                    <p>einmalig, in gewünschter Ausgabe</p>

                    <em>Preis</em>
                    <p>3’300 CHF</p>

                    <em>Sprachwechsel Inserat</em>
                    <p>Bitte nehmen Sie dafür mit uns Kontakt auf <a href="mailto:events@emh.ch">events@emh.ch</a></p>

                    <em>Technische Daten Inserat</em>
                    <p>
                        Masse: ¼ Seite quer, 186 × 64 mm
                        Auflösung: 300 dpi
                        Dateiformat: PDF
                        Dateigrösse: max. 1 MB
                    </p>

                    <em>Technische Daten Logo</em>

                    <p>
                        Masse: 150 px x 120 px
                        Auflösung: 72 dpi
                        Dateiformat: JPG, PNG,
                        Dateigrösse: max. 1 MB
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PremiumModal
