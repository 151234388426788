import React from 'react'
import Checkbox from './Checkbox'

const CheckboxGroup = props => {
    const handleSelection = item => {
        if (props.selection.indexOf(item) !== -1) {
            props.onChange(props.selection.filter(s => s !== item))
        } else {
            props.onChange([ ...props.selection, item ])
        }
    }

    return (
        <div id={props.id}>
            <div className={`checkboxes ${props.error ? 'validation-error' : ''}`}>
                {props.options.map(o => (
                    <Checkbox
                        key={o}
                        label={o}
                        selected={props.selection.indexOf(o) !== -1}
                        onChange={handleSelection}
                        bold={props.bold ? props.bold.includes(o) : false}
                    />
                ))}
            </div>
            <div className="validation-text">
                {props.error}
            </div>
        </div>
    )
}

CheckboxGroup.defaultProps = {
    options: [],
    selection: [],
    onChange: () => {}
}

export default CheckboxGroup
