import React, {useState, useEffect} from 'react'
import {passwordRules} from '../constants/constants'

export const PasswordHint = () => {
    return (
        <div style={{fontSize: '.75em'}}>
            <p style={{marginTop: '1em', marginBottom: '0.5em', fontWeight: 'bold'}}>{passwordRules.header}</p>
            <ul>
                {passwordRules.rules.map(val => (<li>{val}</li>))}
            </ul>
        </div>
    )
}