import React from 'react'

const ValidatedCheckbox = props => {
    const handleToggle = () => {
        if (!props.readOnly)
            props.onChange(props.label)
    }

    return (
        <div style={props.style} id={props.id} className={`form-group checkbox-group`}>
            <div className={`${props.error ? 'validation-error' : ''}`}>
                {props.selected ? (
                    <span className={`${props.flag ? 'icon-check-circle' : 'icon-check-circle-filled'} ${props.readOnly ? 'readonly' : ''}`} onClick={handleToggle}>
                    <span className="path1" />
                    <span className="path2" />
                </span>
                ) : (
                    <span className={`${props.flag ? 'icon-times-circle-outline' : 'icon-circle'}  ${props.readOnly ? 'readonly' : ''}`} onClick={handleToggle} />
                )}
                {props.bold}
                <label style={{marginLeft: '0.5em'}} className={`${props.bold ? 'bold':''} ${props.readOnly ? 'readonly' : ''}`} onClick={handleToggle}>{props.label}</label>
                <div className="validation-text" style={{display: props.error ? 'flex' : 'none'}}>
                    {props.error}
                </div>
            </div>
        </div>
    )
}

ValidatedCheckbox.defaultProps = {
    onChange: () => {}
}

export default ValidatedCheckbox
