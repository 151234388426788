import React, { useState, useEffect } from 'react'
import history from 'react-dom'
import FloatingTextBox from '../components/FloatingTextBox'
import axios from 'axios'
import useAdminAccountInformation from '../hooks/AdminAccountInformation'

const defaultFormData = {
    email: '',
    password: ''
}

const AdminLoginModal = props => {
    const [ formData, setFormData ] = useState(defaultFormData)
    const [ validationError, setValidationError ] = useState('');
    const augmentFormData = field => data => setFormData(state => ({ ...state, [field]: data }))

    const [ dirty, setDirty ] = useState(false);

    const [ account , refresh] = useAdminAccountInformation()

    const loginAction = async () => {
        const route = '__authentication[Neos][Flow][Security][Authentication][Token][UsernamePassword]'
        const params = {
            email: `[username]=${formData.email}`,
            password : `[password]=${formData.password}`
        }
        await axios.post(`/authenticate?${route}${params.email}&${route}${params.password}`)
            .then(
                ok => {
                    console.log('accoutn', account)
                    if (!ok.status === 200)
                        setValidationError(true)

                    refresh()
                    setDirty(true)

                }).catch( error => {
                    setValidationError(true)
                    console.log('An error occured on login', account)
                })
    }

    useEffect(() => {
        if (account && account.roles) {
            if (account.roles.includes('Emh.EventManager:Admin'))
                props.onLoginSuccess()
            else
                dirty && setValidationError(true)
        }
    }, [account])

    return (
        <div className="modal-container">
            <div className="modal-wrapper">
                {/*<span className="icon-times-circle-outline" onClick={() => props.onClose()} />*/}
                <div className="modal-body">
                    <h1>Admin</h1>
                    <h2>Anmeldung</h2>
                    <FloatingTextBox
                        value={formData.email}
                        placeholder="Benutzername (E-Mail)"
                        onChange={augmentFormData('email')}
                        className={validationError ? 'validation-error':''}
                        error={validationError ? 'Anmeldung nicht erfolgreich':null}
                    />
                    <FloatingTextBox
                        value={formData.password}
                        placeholder="Passwort"
                        mask
                        onChange={augmentFormData('password')}
                        onEnter={loginAction}
                        className={validationError ? 'validation-error':''}
                        error={validationError ? 'Anmeldung nicht erfolgreich':null}

                    />
                    <div className="form-options">
                        {/*<button data-button="flat" onClick={props.switchToRecover}>Passwort vergessen?</button>*/}
                        <button data-button onClick={loginAction}>Anmelden</button>
                    </div>
                    </div>
            </div>
        </div>
    )
}

export default AdminLoginModal
