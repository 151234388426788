import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import axios from "axios";
import {Card, CardTitle} from "reactstrap";
import DataTable from "react-data-table-component";
import {Button, Chip, FontIcon, SelectField, TextField, Layovers} from "react-md";
import {DateRangePicker} from "react-dates";
import {DatePickerPresets} from './ReviewList'
import removeFromList from '../../pages/Manager'

import * as moment from 'moment';
import useFilter from "../../hooks/Filter";

import {premiumLabelMappings} from '../../constants/constants'

const EventList = ({location, history}) => {
    const [data, setData] = useState([]);
    const [visibleData, setVisibleData] = useState([]);

    const [loading, setLoading] = useState(true)

    const [filter, setFilter] = useFilter('admin_eventlist_filter', defaultFilterData)

    useEffect(() => {
        loadEvents()
    }, [])

    useEffect(() => {
        if (filter) {
            if (!data.length)
                loadEvents()
            else
                filterEvents(data)
        }
    }, [filter])

    const loadEvents = async () => {
        filterEvents(await fetchEvents())
    }

    const fetchEvents = async (remove) => {
        const {data} = await axios.get('/api/admin/events/')

        if (remove || location && location.state && location.state.remove) {
            if (!remove && location.state && location.state.remove)
                remove = location.state.remove

            if (remove)
                data.forEach((e, i) => {
                    if (e.id === remove)
                        data.splice(i, 1)
                })
        }

        if (location.state && location.state.new) {
            data.push(location.state.new)
        }

        data.sort((a, b) => {
            return new Date(a.creationDate) - new Date(b.creationDate)
        })

        setLoading(false)
        const eventsFormatted = formatEvents(data)
        setData(eventsFormatted)
        setVisibleData(eventsFormatted)
        return eventsFormatted

    }

    const filterEvents = (events) => {
        console.log('filtering', filter)

        let visibleData = events.filter(e => {
            let criteria = {}

            criteria.premium = filter.premium ? e.premium === filter.premium : true
            criteria.type = filter.type ? e.type === filter.type : true
            criteria.user = filter.client ? e.user.email === filter.client : true
            criteria.query = filter.query ? e.title.toLowerCase().search(filter.query.toLowerCase()) > -1 || e.description.toLowerCase().search(filter.query.toLowerCase()) > -1 : true

            const startDate = e.start ? new Date(e.start) : null
            if (startDate) startDate.setHours(0, 0, 0, 0)

            const endDate = e.end ? new Date(e.end) : null
            if (endDate) endDate.setHours(0, 0, 0, 0)

            const filterFrom = filter.startDate && typeof filter.startDate === 'number' ? new Date(filter.startDate) : null
            const filterTo = filter.endDate && typeof filter.endDate === 'number' ? new Date(filter.endDate) : null
            filterFrom && filterFrom.setHours(0, 0, 0, 0)
            filterTo && filterTo.setHours(0, 0, 0, 0);

            criteria.date = filterFrom ?
                endDate >= filterFrom
                && (filterTo ? startDate <= filterTo : true)
                : true;

            criteria.department = filter.department && !filter.department.toLowerCase().includes('alle Fachgebiete') ? e.departments.includes(filter.department) : true

            //logical AND of all criteria
            return Object.values(criteria).reduce((acc, curr) => {
                return  acc && curr
            }, true);
        })
        console.log('visibleData', visibleData)
        setVisibleData(visibleData)
    }


    const viewEvent = (event) => {
        history.push(`/admin/review/event/${event.webId}`, {event: event, init: '/admin/events'})
    }

    const editEvent = (event) => {
        history.push(`/admin/event/${event.webId}`, {event: event, init: '/admin/events'})
    }

    const premiumInstances = [...new Set(data.map(({premium}) => premium))]
    const premiumOptions = premiumInstances.map((e) => ({value: e, label: premiumLabelMappings[e]}))

    const typeOptions = [...new Set(data.map(({type}) => type))]
    const clientOptions = [...new Set(data.map(({user}) => user.email))]
    const statusOptions = [...new Set(data.map(({status}) => status))]
    const allDepartments = data.reduce((results, {departments}) => results.concat(departments), [])
    const departmentOptions = [...new Set(allDepartments)]

    const deleteEvent = async (event) => {
        await axios.delete(`/api/events/${event.id}`)
        fetchEvents(event.id)
    }

    const premiumColor = (premium => {
        switch (premium) {
            case 'onlinePlus':
                return '#C8E6C9'
            case 'print':
                return '#B3E5FC'
            case 'printPlus':
                return '#E1BEE7'
        }
    })

    const columns = [
        {
            name: 'Angelegt',
            selector: 'creationDate',
            format: row => {
                const date = row.creationDate.substring(0, row.creationDate.indexOf(' '))
                const time = row.creationDate.substring(row.creationDate.indexOf(' '))
                const timeParts = time.split(':')
                const dateParts = date.split('-');
                return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]} - ${timeParts[0]}:${timeParts[1]}`
            },
            sortable: true,
            width: '140px',
            compact:true

        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            width: '60px'
        },
        {
            name: 'Premium',
            selector: 'premium',
            sortable: true,
            cell: row => (row.premium !== 'free' && (
                    <div className="colorbox"
                         style={{backgroundColor: premiumColor(row.premium)}}>{premiumLabel(row.premium)}</div>
                )
            ),
            width: '120px',

        },
        {
            name: 'Typ',
            selector: 'type',
            sortable: true,
            width: '100px'
        },
        {
            name: 'Titel',
            selector: 'title',
            sortable: true,
            width: '220px'
        },
        {
            name: 'Von',
            selector: 'start',
            format: row => {
                const dateParts = row.start.split('-');
                return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`
            },
            sortable: true,
            width: '90px'

        },
        {
            name: 'Bis',
            selector: 'end',
            format: row => {
                const dateParts = row.end.split('-');
                return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`
            },
            sortable: true,
            width: '90px'

        },
        {
            name: 'Sprache',
            selector: 'languagesJoined',
            sortable: true,
            width: '60px'
        },
        {
            name: 'Fachgebiet',
            selector: 'departmentsJoined',
            sortable: true,
            width: '100px'
        },
        {
            name: 'Credits',
            selector: 'creditsCount',
            sortable: true,
            width: '50px'
        },
        {
            allowOverflow: true,
            cell: row => (
                <>
                    <Button icon onClick={() => viewEvent(row)}>remove_red_eye</Button>
                    <Button icon onClick={() => editEvent(row)} >edit</Button>
                    <Button icon onClick={() => deleteEvent(row)}>delete</Button>
                </>
            ),
            width: '140px'
        },
        {
            name: 'PDF',
            allowOverflow: true,
            cell: row => (
                <>
                    {row.premium === 'printPlus' && (
                        <Button icon href={row.assets.printFile} target="_blank" rel="noopener noreferrer"
                                >save_alt</Button>)}
                </>
            ),
            compact: true,
            width: '40px'
        },
        {
            name: 'Ausgabe SMF',
            selector: 'issue',
            sortable: true
        }
    ];

    return (
        <div className="site-content">
            <Card body>
                {!loading && (data.length ? <CardTitle>Alle Veranstaltungen</CardTitle> : '')}

                <DataTable
                    columns={columns}
                    data={visibleData}
                    defaultSortField="creationDate"
                    defaultSortAsc={false}
                    progressPending={loading}
                    progressComponent={<div>Veranstaltungen werden geladen...</div>}
                    noDataComponent={<div>Keine Veranstaltungen gefunden</div>}
                    subHeader
                    subHeaderComponent={
                        data.length ? (
                            <Filters onChange={criteria => setFilter({...filter, ...criteria})}
                                     onClear={() => setFilter(defaultFilterData)}
                                     premiumOptions={premiumOptions}
                                     typeOptions={typeOptions}
                                     clientOptions={clientOptions}
                                     statusOptions={statusOptions}
                                     departmentOptions={departmentOptions}
                                     filterState={filter}

                            />
                        ) : ''
                    }
                    pagination
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[10, 50, 100, 300, 1000]	}
                    paginationComponentOptions={{rowsPerPageText: 'Einträge pro Seite:', rangeSeparatorText: 'von'}}
                    overflowY
                />
            </Card>
        </div>
    )
}

const formatEvents = (data) => {
    return data.map(e => {
        e.languagesJoined = e.languages.map(e => e.slice(0, 1)).join(', ')
        e.departmentsJoined = e.departments.join(', ')
        e.creditsCount = e.credits.reduce((acc, current) => {
            return acc + parseInt(current.count) ? parseInt(current.count) : 0
        }, 0)

        switch (e.status) {
            case 'review':
                e.status = 'Überprüfen';
                break;
            case 'online':
                e.status = 'Online';
                break;
            case 'offline':
                e.status = 'Offline';
                break;
            case 'rejected':
                e.status = 'Abgelehnt';
                break;
            case 'modified':
                e.status = 'Modifiziert';
                break;
        }
        return e
    })
}
const premiumLabel = (premium => {
    switch (premium) {
        case 'onlinePlus':
            return 'Online Plus'
        case 'print':
            return 'Print Plus'
        case 'printPlus':
            return 'Print Deluxe'
    }
})

export default withRouter(EventList);
export {premiumLabel, formatEvents}

const defaultFilterData = {
    status: '',
    premium: '',
    type: '',
    query: '',
    startDate: null,
    endDate: null,
    client: '',
    department: ''
}

const Filters = props => {
    const [focusedInput, setFocusedInput] = useState(null)

    const augmentFilterData = field => data => {
        props.onChange({[field]: data})
    }

    const setDates = (start, end) => {
        props.onChange({startDate: start, endDate: end})
    }

    return (
        <div className="filter-bar">
            {!Object.values(props.filterState).find(e => e && e !== '' ) ? (
                <div className="filter filters-label">Filter</div>
            ) : (
                <div className="filter button-clear">
                    <div onClick={() => props.onClear()}>Filter löschen</div>
                </div>
            )}

            <div className="filter filter-status">
                <SelectField
                    id="select-status"
                    // label="Premium"
                    placeholder="Status"
                    menuItems={props.statusOptions}
                    value={props.filterState.status}
                    onChange={augmentFilterData('status')}
                    inputClassName={props.filterState.status ? 'filter-active' : ''}
                />
            </div>
            <div className="filter filter-premium">
                <SelectField
                    id="select-premium"
                    // label="Premium"
                    placeholder="Premium"
                    menuItems={props.premiumOptions}
                    value={props.filterState.premium}
                    onChange={augmentFilterData('premium')}
                    inputClassName={props.filterState.premium ? 'filter-active' : ''}

                />
            </div>
            <div className="filter filter-type">
                <SelectField
                    id="select-type"
                    // label="Filter"
                    placeholder="Typ"
                    menuItems={props.typeOptions}
                    value={props.filterState.type}
                    onChange={augmentFilterData('type')}
                    inputClassName={props.filterState.type ? 'filter-active' : ''}

                />
            </div>
            <div className="filter filter-query">
                <TextField
                    id="placeholder-only-title"
                    placeholder="Textsuche"
                    leftIcon={<FontIcon>search</FontIcon>}
                    value={props.filterState.query}
                    onChange={augmentFilterData('query')}
                    inputClassName={props.filterState.query ? 'filter-active' : ''}
                />
            </div>
            <div className="filter filter-date">
                <DateRangePicker
                    small
                    noBorder
                    hideKeyboardShortcutsPanel
                    showClearDates={false}
                    renderDayContents={null}
                    focusedInput={focusedInput}
                    startDate={props.filterState.startDate ? moment(props.filterState.startDate) : null}
                    startDateId="id-startdate"
                    startDatePlaceholderText="Von"
                    endDatePlaceholderText="Bis"
                    endDate={props.filterState.endDate ? moment(props.filterState.endDate) : null}
                    endDateId="id-enddate"
                    onDatesChange={({startDate, endDate}) => setDates(startDate, endDate)}
                    onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                    isOutsideRange={day => false}
                    displayFormat="DD.MM.YYYY"
                    renderCalendarInfo={() => (<DatePickerPresets onChange={(start, end) => setDates(moment(start), moment(end))}></DatePickerPresets>)}
                />
            </div>
            <div className="filter filter-department">
                <SelectField
                    id="select-fachgebiet"
                    placeholder="Fachgebiet"
                    menuItems={props.departmentOptions}
                    value={props.filterState.department}
                    onChange={augmentFilterData('department')}
                    inputClassName={props.filterState.department ? 'filter-active' : ''}
                    position={SelectField.Positions.TOP_RIGHT}
                />
            </div>
            {/*<div className="filter">*/}
                {/*<SelectField*/}
                    {/*id="select-client"*/}
                    {/*// label="Kunde"*/}
                    {/*placeholder="Kunde"*/}
                    {/*menuItems={props.clientOptions}*/}
                    {/*value={props.filterState.client}*/}
                    {/*onChange={augmentFilterData('client')}*/}
                    {/*inputClassName={props.filterState.client ? 'filter-active' : ''}*/}
                    {/*position={SelectField.Positions.TOP_RIGHT}*/}
                {/*/>*/}
            {/*</div>*/}
        </div>
    )
}

