import React, {useState} from 'react'
import FloatingTextBox from '../components/FloatingTextBox'
import axios from 'axios'

const defaultFormData = {
    username: ''
}

const RecoverModal = props => {
    const [formData, setFormData] = useState(defaultFormData)

    const [unknownEmail, setUnknownEmail] = useState(false)
    const augmentFormData = field => data => setFormData(state => ({...state, [field]: data}))

    const recovery = async () => {
        setUnknownEmail(false)
        const res =  await axios.put('/api/user/info', {
            data: {
                email: formData.email, changepassword: true
            }
        })
        if (res.status === 200) {
            props.onClose()
        }
        else {
            setUnknownEmail(true)
        }
    }

    return (
        <div className="modal-container">
            <div className="modal-wrapper">
                <span className="icon-times-circle-outline" onClick={() => props.onClose()}/>
                <div className="modal-body">
                    <h1>Passwort vergessen</h1>
                    <h2>Passwort zurücksetzen</h2>
                    <FloatingTextBox
                        value={formData.email}
                        placeholder="E-Mail Adresse"
                        onChange={augmentFormData('email')}
                        error={unknownEmail ? 'Diese E-Mail Adresse ist uns leider nicht bekannt':null} />
                    <div className="form-options">
                        <button data-button="flat" onClick={props.switchModal}>Zurück zum Login</button>
                        <button data-button onClick={() => recovery()}>Zurücksetzen</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecoverModal
