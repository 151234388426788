import React from 'react'
import { monthNames } from '../constants/constants'

export const formatDates = (start, end) => {
    const startDate = new Date(start)
    const startDay = start && startDate.getDate().toString().padStart(2, '0')
    const startMonth = start && monthNames[startDate.getMonth()]
    const startYear = start && startDate.getFullYear()

    const endDate = new Date(end)
    const endDay = end && endDate.getDate().toString().padStart(2, '0')
    const endMonth = end && monthNames[endDate.getMonth()]
    const endYear = end && endDate.getFullYear()

    if (!(start && end)) {
        return ''
    } else if (startDate.getTime() === endDate.getTime() || !end) {
        return `${startDay}. ${startMonth} ${startYear}`
    } else if (end && !start) {
        return `${endDay}. ${endMonth} ${endYear}`
    } else if (startDate.getMonth() === endDate.getMonth()) {
        return `${startDay}. – ${endDay}. ${startMonth} ${startYear}`
    } else if (startYear === endYear) {
        return `${startDay}. ${startMonth} – ${endDay}. ${endMonth} ${startYear}`
    } else {
        return `${startDay}. ${startMonth} ${startYear} – ${endDay}. ${endMonth} ${endYear}`
    }
}

const EventCard = ({ event, backend, canEdit }) => {
    return (
        <div className={`event-card ${event.highlightOnline ? 'is-top' : ''}`}>
            <a href={backend ? canEdit ? `/manager/event/${event.webId}` : null : `/event/${event.webId}`}>
                <div className="event-meta">
                    <div style={{maxWidth: '80%'}}>
                        <b>{event.type}</b><span className="has-departments"> {event.departments.length ? '—' :''} </span>{event.departments.join(', ')}
                    </div>
                    <span className={`event-credits ${event.credits && event.credits.length ? 'has-credits' : ''}`}>
                        {event.credits && event.credits.length ? 'Credits' : 'keine Credits'}
                        {event.credits && event.credits.length && event.credits.find(c => c.confirmed) ? (
                            <span className="icon-check-circle">
                                <span className="path1" />
                                <span className="path2" />
                            </span>
                        ) : event.credits && event.credits.length ? (
                            <span className="icon-check-circle">
                                <span className="path1" />
                            </span>
                        ): ''}
                    </span>
                </div>
                <div className="event-body">
                    <div>
                        <div className="event-date">
                            {formatDates(event.start, event.end) ? formatDates(event.start, event.end) : (<i>Veranstaltung hat noch kein Datum</i>)}
                            {event.startTime && event.endTime && `, ${event.startTime}–${event.endTime}`}
                        </div>
                        <div>
                            <h2>{event.title}</h2>
                            {event.address && (
                                <div className="event-location">
                                    <b>{event.address.city}, {event.address.country} {event.venue ? '—' : ''} </b> {event.venue}
                                </div>
                                )
                            }
                        </div>
                    </div>
                    {event.preview && (
                        <img src={event.preview} alt={event.title} />
                    )}
                </div>
            </a>
        </div>
    )
}

EventCard.defaultProps = {
    event: {}
}

export default EventCard
