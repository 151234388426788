import React from 'react'

const Dropdown = ({ items, onSelect, onMouseEnter, onMouseLeave }) => {
    if (!items.length) {
        return null
    }

    return (
        <div className="dropdown" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <ul className="items">
                {items.map(i => (
                    <li
                        key={i}
                        onClick={() => onSelect(i)}>
                        {i}
                    </li>
                ))}
            </ul>
        </div>
    )
}

Dropdown.defaultProps = {
    items: [],
    onSelect: () => {},
    onMouseEnter: () => {},
    onMouseLeave: () => {}
}

export default Dropdown
