import React, {useState, useEffect, useRef} from 'react'
import {Link, NavLink, withRouter} from 'react-router-dom'
import FloatingTextBox from '../components/FloatingTextBox'
import FloatingTextArea from '../components/FloatingTextArea'
import FloatingDatePicker from '../components/FloatingDatePicker'
import FloatingTimePicker from '../components/FloatingTimePicker'
import FloatingAddressBox from '../components/FloatingAddressBox'
import FloatingSingleSelect from '../components/FloatingSingleSelect'
import FloatingMultiSelect from '../components/FloatingMultiSelect'
import RadioGroup from '../components/RadioGroup'
import CheckboxGroup from '../components/CheckboxGroup'
import Checkbox from '../components/Checkbox'
import Credit from '../components/Credit'
import ImageUpload from '../components/ImageUpload'
import FileUpload from '../components/FileUpload'
import ProfileModal from '../modals/ProfileModal'
import PremiumModal from '../modals/PremiumModal'
import PremiumInfoModal from '../modals/PremiumInfoModal'
import {validateProfile, validateEmail} from '../pages/Profile'
import useAccountInformation from '../hooks/AccountInformation'
import useClickOutside from '../hooks/ClickOutside'
import logo from '../images/logo.svg'
import isURL from 'validator/lib/isURL'

import {defaultFormData, testFormData, defaultUploadData, defaultCredit, languageOptions, typeOptions} from '../constants/EventForm/constants'

import exampleOnlinePlus from '../images/example-online-plus.jpg'
import examplePrintDeluxe from '../images/example-print-deluxe.jpg'
import examplePrintPlus from '../images/example-print-plus.jpg'

import axios from 'axios'
import FormData from 'form-data'
import {departments, premiumHints, issuesSMF} from '../constants/constants'
import {Helmet} from "react-helmet";

import useNotifications, {notifications} from '../hooks/Notifications'
import useGoogleAnalytics, {userActions} from "../hooks/GoogleAnalytics";



const issueOptions = issuesSMF.filter(e => new Date(e.deadline) > new Date().setHours(0,0,0,0)).map(e => e.value)

const EventForm = (props) => {
    const [formData, setFormData] = useState(defaultFormData)
    const [event, setEvent] = useState(defaultFormData)
    const [missing, setMissing] = useState([])
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [creditErrors, setCreditErrors] = useState([])
    const [uploadData, setUploadData] = useState(defaultUploadData)
    const [premiumModalContent, setPremiumModalContent] = useState(null)
    const [signupError, setSignupError] = useState(null);
    const [isSidebarVisible, setIsSidebarVisible] = useClickOutside('sidebar')

    const [isProfileModalVisible, setIsProfileModalVisible] = useState(false)
    const [isPremiumModalVisible, setIsPremiumModalVisible] = useState(false)
    const [isPremiumInfoModalVisible, setIsPremiumInfoModalVisible] = useState(false)

    const [account, refresh] = useAccountInformation()
    const [user, setCurrentUser] = useState(account)

    const firstErrorRef = useRef(null)

    const [emitGAEvent] = useGoogleAnalytics()

    const augmentFormData = field => data => setFormData(state => ({...state, [field]: data}))

    const [noTitleError, setNoTitleError] = useState(false)

    const [createNotification, renderNotifications] = useNotifications()

    const editEvent = async id => {
        let event = await axios.get(`/api/events/${id}`)
        let formData = transformEvent(event.data)

        console.log('formdata', formData)
        setFormData(formData)
        setEvent(formData)
        setUploadData([])
    }

    const transformEvent = event => {
        event.deadline = (new Date(event.deadline)).getTime()
        event.end = (new Date(event.end)).getTime()
        event.start = (new Date(event.start)).getTime()
        event.speakers = event.speakers.join('\n')
        event.address = event.address ? event.address : null
        return event
    }

    const validate = (draft) => {
        let notEmpty = draft ? ['title'] :  ['title', 'type', 'departments', 'languages', 'description', 'venue', 'mail', 'company', 'printSummary', 'dataNotice']
        let notNull = draft ? ['start', 'end'] :  ['start', 'end', 'address']

        if (formData.type === typeOptions.online) {
            notEmpty = notEmpty.filter(item => item !== 'venue')
            notNull = notNull.filter(item => item !== 'address')
        }

        const emptyValues = notEmpty.filter(f => !formData[f].length)
        const nullValues = notNull.filter(f => !formData[f])
        const missingResources = []
        if ((formData.premium === 'onlinePlus' || formData.premium === 'print' || formData.premium === 'printPlus') && !(uploadData.preview || formData.preview))
            missingResources.push('preview')

        if (formData.premium === 'printPlus') {
            if (!(uploadData.printFile || formData.printFile)) {
                missingResources.push('printFile')
            }
            if (!formData.issue) {
                emptyValues.push('issue')
            }
        }

        let missingCredits = [];
        if (formData.credits.length) {
            formData.credits.forEach((e, i) => {
                if (!e.count) {
                    missingCredits.push({pos: i})
                    if (!emptyValues.includes('credits'))
                        emptyValues.push('credits')
                }
            })
        }

        setCreditErrors(missingCredits);

        const allMissing = emptyValues.concat(nullValues).concat(missingResources);

        if (formData.start && formData.end && (formData.start === formData.end)) {
            if (!formData.endTime)
                allMissing.unshift('endTime')

            if (!formData.startTime)
                allMissing.unshift('startTime')
        }

        setMissing(allMissing)

        const eMailOk = draft ? true : validateEmail(formData.mail);
        if (!draft) setInvalidEmail(!eMailOk)


        console.log('uploaddata', uploadData)
        console.log('formData', formData)
        const signupOk = isURL(formData.signup)
        setSignupError(!signupOk ? 'Bitte geben Sie einen gültigen Link ein' : '')

        return !allMissing.length && eMailOk && (!formData.signup || formData.signup && signupOk)
    }


    const formatAndPost = async (event, draft, profileData) => {
        await refresh()

        if (!draft && (!account || !validateProfile(profileData ? profileData : account))) {
            setIsProfileModalVisible(true)
            // console.log('setIsProfileModalVisible')
            return
        }

        event = formatEvent(event)
        console.log('event about to send', event)
        console.log('daft?', draft)
        console.log(`${event.id ? 'put' : 'post'} request,  event id: ${event.id} `)
        if (draft)
            event.status = 'draft'
        else
            delete event.status

        let response;
        if (event.id)
            response = await axios.put(`/api/events/${event.id}`, {data: event})
        else
            response = await axios.post('/api/events', {data: event})

        if (response) {
            event.id = response.data.id
            console.log('response', response)
        }

        console.log('uploadData to upload?', uploadData)

        const uploads = Object.keys(uploadData).length
        if (uploads) {
            let uploadCount = 0;
            console.log('have files to upload')
            //iterate over object properties

            if (uploadData.preview) {
                uploadCount++
                response.data.preview = await uploadResource(event.id, 'preview')
            }

            if (uploadData.printFile) {
                uploadCount++
                response.data.printFile = await uploadResource(event.id, 'printFile')
            }

            // response = await axios.get(`/api/events/${event.id}`)
        }

        createNotification(draft ? notifications.draft : notifications.review)
        props.history.push('/manager', { new: response ? response.data : null, renderNotifications: true})
    }

    const uploadResource = async (eventId, type) => {
        const asset = uploadData[type]
        const formData = new FormData()
        if (asset) {
            formData.append('file', asset.file)
            const {data} = await axios.post(`/api/events/${eventId}/assets/${type}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return data
        }
    }

    const formatDate = date => {
        if (!date) return null
        const d = new Date(date)
        const month = (d.getMonth() + 1).toString().padStart(2, '0')
        const day = d.getDate().toString().padStart(2, '0')
        const year = d.getFullYear()
        return [year, month, day].join('-')
    }

    const formatEvent = event => {
        event.deadline = event.deadline ? formatDate(event.deadline) : null
        event.signup = event.signup || null
        event.end = formatDate(event.end)
        event.start = formatDate(event.start)
        event.speakers = !Array.isArray(event.speakers) ? formatSpeakers(event.speakers) : event.speakers
        return event
    }

    const canBookOption = (option) => {
        let allowed = true;
        if (event.status === 'review')
            allowed = false
        if (event.status === 'online') {
            let restricted = []
            if (event.premium === 'onlinePlus') {
                restricted = ['onlinePlus']
            } else if (event.premium === 'print') {
                restricted = ['onlinePlus', 'print']
            } else if (event.premium === 'printPlus') {
                restricted = ['onlinePlus', 'print', 'printPlus']
            }

            if (event.premium === option && !(formData.premium === event.premium))
                restricted.splice(restricted.indexOf(option), 1)

            console.log('restricted', restricted)

            if (restricted.includes(option)) {
                allowed = false
            }
        }

        return allowed
    }

    const scrollToFormElement = element => {
        firstErrorRef.current = document.getElementById(`eventform-${element}`)
        if (firstErrorRef)
            scrollToRef(firstErrorRef)
    }

    const scrollToRef = (ref) => {
        window.scrollTo(0, ref.current.offsetTop - 100)
    }

    const handleRemoveAsset = type => {
        formData[type] && formData.id && axios.delete(`/api/events/${formData.id}/assets/${type}`) && setFormData({
            ...formData,
            [type]: null
        })
    }

    const notifiyAssetUploaded = () => {
        createNotification(notifications.fileUpload)
    }

    const formatSpeakers = speakers => speakers.length ? speakers.split('\n') : []

    useEffect(() => {
        setCurrentUser(account)
    }, [account])

    useEffect(() => {
        console.log(formData)
        if (!formData.dataNotice)
            setFormData({...formData, dataNotice: [] })
    }, [formData])

    useEffect(() => {
        console.log('creditErrors', creditErrors)
    }, [creditErrors])

    useEffect(() => {
        if (event.status === 'online' && !formData.premium)
            formData.premium = event.premium
    }, [formData.premium])


    useEffect(() => {
        console.log('credits', formData.credits)
    }, [formData.credits])


    useEffect(() => {
        if (props.match.params.id) {
            editEvent(props.match.params.id)
        }

    }, [])

    useEffect(() => () => {
        Object.keys(uploadData).forEach(key => {
            if (uploadData[key]) {
                URL.revokeObjectURL(uploadData[key].preview)
            }
        })
    }, [])

    useEffect(() => {
        if (formData.start && !formData.end) {
            augmentFormData('end')(formData.start)
        }
    }, [formData.start])

    useEffect(() => {
        console.log('missing', missing)
        // console.log('first missing', missing[0])
        if (missing.length) {
            scrollToFormElement(missing[0])
        } else if (invalidEmail) {
            scrollToFormElement('mail')
        }
    }, [missing, invalidEmail])

    useEffect(() => {
        if (formData.premium === 'onlinePlus')
            setFormData({...formData, highlightOnline: true, highlightPrint: false, highlightPrintPlus: false})
        else if (formData.premium === 'print')
            setFormData({...formData, highlightOnline: true, highlightPrint: true, highlightPrintPlus: false})
        else if (formData.premium === 'printPlus')
            setFormData({...formData, highlightOnline: true, highlightPrint: true, highlightPrintPlus: true})
        else if (formData.premium === '')
            setFormData({...formData, highlightOnline: false, highlightPrint: false, highlightPrintPlus: false})
    }, [formData.premium])

    const onLogout = async () => {
        await axios.get('/logout')
        props.history.push('/')
        createNotification(notifications.logout)
    }

    return (
        <>
            <Helmet>
                <title>{`${formData.title ? formData.title : 'Event eintragen'} - EMH Kongresskalender`}</title>
            </Helmet>
            <header className="site-header">
                <div>
                    <a href="/" className="header-link">
                        <div className="wrap">
                            <img src={logo} className="logo" alt="EMH Logo"/>
                            <h1 className="serif">Swiss Medical Events</h1>
                            <h2 className={'header-sub-title'}>Kongresse, Seminare<br />Weiter- und Fortbildung</h2>
                        </div>
                    </a>
                    <span className="icon-bars show-mobile" onClick={() => setIsSidebarVisible(state => !state)}/>
                </div>
                <div>
                    <div className="wrap">
                        <nav className="site-nav">
                            <ul>
                                <li><a href="https://emh.ch" target="_blank">Über uns</a></li>
                                <li><button onClick={() => emitGAEvent(userActions.enterEvent) || props.history.push('/manager/event')}>Event eintragen</button></li>
                            </ul>
                        </nav>
                        <div className="backdrop"/>
                    </div>
                </div>
            </header>
            <main className="site-main manager">
                <div className={`sidebar ${isSidebarVisible ? 'is-visible' : ''}`}>
                    <div className="wrap">
                        <div className="sidebar-header">
                            <span className="sidebar-close icon-times-circle-outline"
                                  onClick={() => setIsSidebarVisible(state => !state)}/>
                        </div>
                        <nav className="sidebar-nav">
                            <ul>
                                <li>
                                    <span style={{cursor: 'default'}} className="icon-account"/>
                                    <div>
                                        {account && (
                                            <>
                                                <p className="name">{user ? user.firstname : ''} {user ? user.lastname : ''}</p>
                                                <p className="user">{account.email}</p>
                                            </>
                                        )}
                                        <p className="logout"><a style={{cursor: 'pointer'}}  onClick={onLogout}>Abmelden</a></p>
                                    </div>
                                </li>
                                <li>
                                    <NavLink className="nav-link" to="/manager">
                                        <span className="icon-calendar"/>
                                        Event Manager
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="nav-link" to="/manager/profile">
                                        <span className="icon-badge"/>
                                        Ihr Profil
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="backdrop"/>
                <div className="site-content event-manager">
                    <a href="/manager" className="back-link">
                        <span className="icon-arrow-left"/>
                        Zurück
                    </a>
                    <h2 className="h1">Event eintragen</h2>
                    <div className="event-group">
                        <div className="wrap">
                            <h2>Allgemeine Informationen</h2>
                            <FloatingTextBox
                                id="eventform-title"
                                value={formData.title}
                                placeholder="Titel*"
                                onChange={augmentFormData('title')}
                                error={missing.includes('title') ? 'Bitte ausfüllen' : null}
                                max={250}
                            />
                            <div className="control-group datepickers">
                                <FloatingDatePicker
                                    id="eventform-start"
                                    value={formData.start}
                                    placeholder="Beginnt am*"
                                    onChange={augmentFormData('start')}
                                    error={missing.includes('start') ? 'Bitte ausfüllen' : null}
                                />
                                <FloatingDatePicker
                                    id="eventform-end"
                                    value={formData.end}
                                    placeholder="Endet am*"
                                    onChange={augmentFormData('end')}
                                    error={missing.includes('end') ? 'Bitte ausfüllen' : null}
                                />
                            </div>
                            {(formData.start && formData.end && formData.start === formData.end) ? (
                                <div className="control-group">
                                    <FloatingTimePicker
                                        id="eventform-startTime"
                                        value={formData.startTime}
                                        placeholder="Beginnt um*"
                                        onChange={augmentFormData('startTime')}
                                        error={missing.includes('startTime') ? 'Bitte ausfüllen' : null}/>
                                    <FloatingTimePicker
                                        id="eventform-endTime"
                                        value={formData.endTime}
                                        placeholder="Endet um*"
                                        onChange={augmentFormData('endTime')}
                                        error={missing.includes('endTime') ? 'Bitte ausfüllen' : null}/>
                                </div>
                            ):''}
                            <label>Art der Veranstaltung*</label>
                            <RadioGroup
                                id="eventform-type"
                                selection={formData.type}
                                options={Object.values(typeOptions)}
                                onChange={augmentFormData('type')}
                                error={missing.includes('type') ? 'Bitte ausfüllen' : null}
                            />
                            <div className="eventform-departments-group">
                                <FloatingMultiSelect
                                    id="eventform-departments"
                                    options={departments}
                                    selection={formData.departments.includes('Alle Fachgebiete') ? [] : formData.departments}
                                    placeholder={formData.departments.includes('Alle Fachgebiete') ? ' Alle Fachgebiete' :'Fachgebiete*'}
                                    onChange={augmentFormData('departments')}
                                    error={missing.includes('departments') ? 'Bitte ausfüllen' : null}
                                    disabled={formData.departments.includes('Alle Fachgebiete')}/>
                                <CheckboxGroup
                                    id="eventform-alldepartemnts"
                                    selection={formData.departments.includes('Alle Fachgebiete') ? ['Betrifft alle Fachgebiete'] : []}
                                    options={['Betrifft alle Fachgebiete']}
                                    onChange={(val) => { console.log('val', val) || setFormData({...formData, departments: val.length ? ['Alle Fachgebiete'] : []})}}/>
                            </div>
                            <label>Sprachen*</label>
                            <CheckboxGroup
                                id="eventform-languages"
                                selection={formData.languages}
                                options={languageOptions}
                                onChange={augmentFormData('languages')}
                                error={missing.includes('languages') ? 'Bitte ausfüllen' : null}
                            />
                        </div>
                    </div>
                    <div className="event-group">
                        <div className="wrap">
                            <h2>Kongressinformationen</h2>
                            <p>Ihr Basiseintrag wird kostenlos in <em>Swiss Medical Events</em> und einmalig in unserer
                                Zeitschrift Swiss Medical Forum publiziert.</p>
                            <FloatingTextArea
                                id="eventform-description"
                                value={formData.description}
                                placeholder="Beschreibung*"
                                onChange={augmentFormData('description')}
                                error={missing.includes('description') ? 'Bitte ausfüllen' : null}/>
                            <FloatingTextArea
                                value={formData.speakers}
                                placeholder="Speaker"
                                help="Bitte einen Speaker pro Zeile eingeben."
                                onChange={augmentFormData('speakers')}
                                error={missing.includes('speakers') ? 'Bitte ausfüllen' : null}/>
                            <div className="control-group">
                                <FloatingTextBox
                                    value={formData.website}
                                    placeholder="Webseite"
                                    onChange={augmentFormData('website')}
                                    max={250}/>
                                <FloatingTextBox
                                    value={formData.program}
                                    placeholder="Link zum Programm"
                                    onChange={augmentFormData('program')}
                                    max={250}/>
                            </div>
                            <FloatingTextArea
                                id="eventform-printSummary"
                                value={formData.printSummary}
                                placeholder="Kurzbeschreibung für Printversion*"
                                help="Hinweis: Hier bitte nur Kurzbeschreibung zum Event. Titel, Datum, Adresse und Kontakt werden automatisch aus den Formulardaten ergänzt. Zeilenumbrüche sind nicht erlaubt."
                                maxLength={250}
                                preventLineBreaks
                                onChange={augmentFormData('printSummary')}
                                error={missing.includes('printSummary') ? 'Bitte ausfüllen' : null}/>
                        </div>
                    </div>
                    <div className="event-group">
                        <div className="wrap">
                            <h2>Premium</h2>
                            <p>Soll Ihre Veranstaltung in <em>Swiss Medical Events</em> oder in der Printversion im Kongresskalender des
                                Swiss Medical Forum herausstechen? Dann buchen Sie eines der folgenden Angebote.<br/>
                                <button data-button="flat" onClick={() => setIsPremiumInfoModalVisible(true)}>
                                    Mehr erfahren
                                </button>
                            </p>
                            <div className={`top-option ${!canBookOption('onlinePlus') ? 'readonly' : ''}`}>
                                <Checkbox
                                    selected={formData.premium === 'onlinePlus'}
                                    label="Online Plus"
                                    readOnly={!canBookOption('onlinePlus')}
                                    onChange={() => setFormData(state => ({
                                        ...state,
                                        premium: state.premium === 'onlinePlus' ? '' : 'onlinePlus'
                                    }))}/>
                                <div className="top-option-description">
                                    <p>Ihre Veranstaltung erscheint in <em>Swiss Medical Events</em> oben und sticht farblich hervor.
                                        Ausserdem wird Ihr Logo angezeigt.</p>
                                </div>
                            </div>
                            {formData.premium === 'onlinePlus' && (
                                <>
                                    <div className="uploads">
                                        <div className="full-width">
                                            <PremiumFlags {...this} setPremiumModalContent={setPremiumModalContent}
                                                          setIsPremiumModalVisible={setIsPremiumModalVisible}
                                                          highlightOnline
                                                          highlightPrint={false} highlightPrintPlus={false}>
                                            </PremiumFlags>
                                        </div>
                                    </div>
                                    <div className="uploads">
                                        <label className="no-mb">Preis</label>
                                    </div>
                                    <div className="uploads">
                                        <p>800 CHF</p>
                                    </div>
                                    <OnlinePremiumSection
                                        onRemove={handleRemoveAsset}
                                        uploaded={formData}
                                        uploadData={uploadData}
                                        setUploadData={setUploadData}
                                        setPremiumModalContent={setPremiumModalContent}
                                        setIsPremiumModalVisible={setIsPremiumModalVisible}
                                        error={missing.includes('preview') ? 'Bitte Logo bereitstellen' : ''}
                                        notifiyAssetUploaded={notifiyAssetUploaded}

                                    />
                                </>
                            )}
                            <div className={`top-option ${!canBookOption('print') ? 'readonly' : ''}`}>
                                <Checkbox
                                    selected={formData.premium === 'print'}
                                    label="Online & Print Plus"
                                    readOnly={!canBookOption('print')}
                                    onChange={() => setFormData(state => ({
                                        ...state,
                                        premium: state.premium === 'print' ? '' : 'print'
                                    }))}/>
                                <div className="top-option-description">
                                    <p>Ihre Veranstaltung wird in <em>Swiss Medical Events</em> ganz oben farblich hervorgehoben zusammen
                                        mit Ihrem Logo publiziert. Zusätzlich wird Ihre Veranstaltung in einer Ausgabe des Swiss
                                        Medical Forum mit hervorgehobenem Text und in Farbe (orange) präsentiert.</p>
                                </div>
                            </div>
                            {formData.premium === 'print' && (
                                <>
                                    <div className="uploads">
                                        <div className="full-width">
                                            <PremiumFlags {...this} setPremiumModalContent={setPremiumModalContent}
                                                          setIsPremiumModalVisible={setIsPremiumModalVisible}
                                                          highlightOnline
                                                          highlightPrint highlightPrintPlus={false}>
                                            </PremiumFlags>
                                        </div>
                                    </div>
                                    <div className="uploads">
                                        <label className="no-mb">Preis</label>
                                    </div>
                                    <div className="uploads">
                                        <p>1'000 CHF</p>
                                    </div>
                                    <OnlinePremiumSection
                                        uploaded={formData}
                                        uploadData={uploadData}
                                        setUploadData={setUploadData}
                                        setPremiumModalContent={setPremiumModalContent}
                                        setIsPremiumModalVisible={setIsPremiumModalVisible}
                                        onRemove={handleRemoveAsset}
                                        error={missing.includes('preview') ? 'Bitte Logo bereitstellen' : ''}
                                        notifiyAssetUploaded={notifiyAssetUploaded}
                                    />
                                </>
                            )}
                            <div className={`top-option ${!canBookOption('printPlus') ? 'readonly' : ''}`}>
                                <Checkbox
                                    selected={formData.premium === 'printPlus'}
                                    label="Online & Print Deluxe"
                                    readOnly={!canBookOption('printPlus')}
                                    onChange={() => setFormData(state => ({
                                        ...state,
                                        premium: state.premium === 'printPlus' ? '' : 'printPlus'
                                    }))}/>
                                <div className="top-option-description">
                                    <p>Zusätzlich zur hervorgehobenen Online- und Print-Präsenz, publizieren wir in der
                                        von Ihnen gewünschten Ausgabe des Swiss Medical Forum ein von Ihnen gestaltetes
                                        Inserat (4-farbig, ¼ A4 Seite quer, 186 × 64 mm).</p>
                                </div>
                            </div>
                            {formData.premium === 'printPlus' && (
                                <>
                                    <div className="uploads">
                                        <div className="full-width">
                                            <PremiumFlags {...this} setPremiumModalContent={setPremiumModalContent}
                                                          setIsPremiumModalVisible={setIsPremiumModalVisible}
                                                          highlightOnline
                                                          highlightPrint highlightPrintPlus/>
                                        </div>
                                    </div>
                                    <div className="uploads">
                                        <label className="no-mb">Preis</label>
                                    </div>
                                    <div className="uploads">
                                        <p>3'300 CHF</p>
                                    </div>

                                    <div className="uploads">
                                        <div className="full-width">
                                            <div className="ausgabe">
                                                <FloatingSingleSelect
                                                    id="eventform-issue"
                                                    readOnly={true}
                                                    options={issueOptions}
                                                    selection={formData.issue}
                                                    placeholder="Gewünschte Ausgabe*"
                                                    onChange={augmentFormData('issue')}
                                                    error={missing.includes('issue') ? 'Bitte Ausgabe auswählen' : ''}
                                                />
                                            </div>
                                            <label>Druck-Datei für Werbe-Inserat im Heft</label>
                                            <FileUpload
                                                id="eventform-printFile"
                                                onRemove={handleRemoveAsset}
                                                file={uploadData.printFile ? uploadData.printFile : formData.printFile}
                                                onSelect={file => setUploadData(state => ({
                                                    ...state,
                                                    printFile: {file: file}
                                                })) || notifiyAssetUploaded()
                                                }
                                                onClear={() => {
                                                    setUploadData(state => ({...state, printFile: null}))
                                                }}
                                                error={missing.includes('printFile') ? 'Bitte Druck-Datei bereitstellen' : ''}
                                            />
                                            <div className="help-text">
                                                <p>
                                                    Hinweis: 186 × 64 mm / ¼ Seite quer / PDF / 300 dpi / 1 MB
                                                    <br/>
                                                    <button data-button="flat"
                                                            onClick={() => setPremiumModalContent(premiumHints.highlightPrintPlus) || setIsPremiumModalVisible(true)}>
                                                        Anzeige-Beispiel
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <OnlinePremiumSection
                                        id="eventform-preview"
                                        uploaded={formData}
                                        uploadData={uploadData}
                                        setUploadData={setUploadData}
                                        setPremiumModalContent={setPremiumModalContent}
                                        setIsPremiumModalVisible={setIsPremiumModalVisible}
                                        onRemove={handleRemoveAsset}
                                        error={missing.includes('preview') ? 'Bitte Logo bereitstellen' : ''}
                                        notifiyAssetUploaded={notifiyAssetUploaded}
                                    />
                                </>
                            )}
                        </div>
                    </div>

                    {formData.type !== typeOptions.online && (
                        <div className="event-group">
                            <div className="wrap">
                                <h2>Veranstaltungsort</h2>
                                <div className="control-group">
                                    <FloatingTextBox
                                        id="eventform-venue"
                                        value={formData.venue}
                                        placeholder="Firma/Unternehmen*"
                                        onChange={augmentFormData('venue')}
                                        error={missing.includes('venue') ? 'Bitte ausfüllen' : null}/>
                                    <FloatingTextBox
                                        value={formData.room}
                                        placeholder="Raum/Gebäude"
                                        onChange={augmentFormData('room')}
                                        error={missing.includes('room') ? 'Bitte ausfüllen' : null}/>
                                </div>
                                <FloatingAddressBox
                                    id="eventform-address"
                                    selection={formData.address}
                                    placeholder="Adresse*"
                                    onChange={augmentFormData('address')}
                                    error={missing.includes('address') ? 'Bitte ausfüllen' : null}/>
                            </div>
                        </div>
                    )}

                    <div className="event-group">
                        <div className="wrap">
                            <h2>Veranstalter</h2>
                            <div className="control-group">
                                <FloatingTextBox
                                    id="eventform-company"
                                    value={formData.company}
                                    placeholder="Veranstalter*"
                                    onChange={augmentFormData('company')}
                                    error={missing.includes('company') ? 'Bitte ausfüllen' : null}
                                    max={250}/>
                                <FloatingTextBox
                                    value={formData.contact}
                                    placeholder="Kontaktperson"
                                    onChange={augmentFormData('contact')}
                                    error={missing.includes('contact') ? 'Bitte ausfüllen' : null}
                                    max={250}/>
                            </div>
                            <div className="control-group">
                                <FloatingTextBox
                                    value={formData.phone}
                                    placeholder="Telefon"
                                    onChange={augmentFormData('phone')}
                                    max={250}/>
                                <FloatingTextBox
                                    id="eventform-mail"
                                    value={formData.mail}
                                    placeholder="E-Mail*"
                                    onChange={augmentFormData('mail')}
                                    error={missing.includes('mail') ? 'Bitte ausfüllen' : invalidEmail ? 'Bitte geben Sie eine gültige E-Mail Adresse ein' : null}
                                    max={250}/>
                            </div>
                        </div>
                    </div>
                    <div className="event-group">
                        <div className="wrap">
                            <h2>Anmeldung</h2>
                            <div className="control-group">
                                <FloatingDatePicker
                                    value={formData.deadline}
                                    placeholder="Anmeldeschluss"
                                    onChange={augmentFormData('deadline')}/>
                                <FloatingTextBox
                                    value={formData.signup}
                                    placeholder="Link zur Anmeldung"
                                    onChange={augmentFormData('signup')}
                                    error={signupError}
                                    max={250}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="event-group">
                        <div className="wrap">
                            <h2>Credits</h2>
                            {formData.credits.map((credit, index) => (
                                <Credit
                                    key={index}
                                    value={credit}
                                    onChange={value =>
                                        console.log('change to!', value)
                                        || setFormData({
                                        ...formData,
                                        credits: formData.credits.map((c, i) => i === index ? value : c)
                                    })
                                    }
                                    onDelete={() => setFormData({
                                        ...formData,
                                        credits: formData.credits.map((c, i) => i === index ? null : c).filter(c => !!c)
                                    })}
                                    error={creditErrors.length && creditErrors.filter(e => e.pos === index).length ? 'Bitte ausfüllen' : null}
                                />
                            ))}
                            <button
                                data-button
                                onClick={() => setFormData({
                                    ...formData,
                                    credits: [...formData.credits, {...defaultCredit}]
                                })}>
                                Credits hinzufügen
                            </button>
                            {/*<button data-button="flat" onClick={() => setFormData(defaultFormData) || props.history.push('/manager')}>*/}
                                {/*<span className="icon-times-circle-outline"/>*/}
                            {/*</button>*/}
                        </div>
                    </div>
                    <div className="event-group">
                        <div className="wrap">
                            <h2>Einwilligung zur Datenspeicherung</h2>
                            <p>Ich willige ein, dass meine Angaben zur Kontaktaufnahme und Zuordnung für eventuelle Rückfragen dauerhaft elektronisch gespeichert werden.</p>
                            <CheckboxGroup
                                id="eventform-dataNotice"
                                selection={formData.dataNotice}
                                options={['Einverstanden']}
                                onChange={augmentFormData('dataNotice')}
                                error={missing.includes('dataNotice') ? 'Bitte einwilligen' : null}
                            />

                        </div>
                    </div>

                    <div className="page-controls">
                        <div className="wrap">
                            <div>
                                <button data-button="flat"
                                        onClick={() => setFormData(defaultFormData) || props.history.push('/manager')}>
                                    Abbrechen
                                </button>
                                <button data-button="flat"
                                        onClick={() => setFormData(defaultFormData) || props.history.push('/manager')}>
                                    <span className="icon-times-circle"/></button>
                            </div>
                            <div>
                                {/*<button data-button="flat" onClick={() => setFormData(testFormData)}>Testdaten</button>*/}
                                <button data-button="flat">
                                    {formData.status !== 'online' && (
                                        <span className="show-desktop" onClick={() => validate('draft') && formatAndPost(formData, true)}>Entwurf speichern</span>)}
                                    <span className="show-mobile"
                                          onClick={() => validate('draft') && formatAndPost(formData, true)}>Speichern</span>
                                </button>
                                <button data-button
                                        onClick={() => validate() && formatAndPost(formData, false)}>Veröffentlichen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {isProfileModalVisible && (
                <ProfileModal
                    onClose={() => setIsProfileModalVisible(false)}
                    onConfirm={(profiledata) => setIsProfileModalVisible(false) || formatAndPost(formData, false, profiledata)}
                    account={account}
                />
            )}
            {isPremiumModalVisible && (
                <PremiumModal
                    content={premiumModalContent}
                    onClose={() => setIsPremiumModalVisible(false)}/>
            )}
            {isPremiumInfoModalVisible && (
                <PremiumInfoModal
                    onClose={() => setIsPremiumInfoModalVisible(false)}/>
            )}
        </>
    )
}

const OnlinePremiumSection = ({id, uploaded, uploadData, setUploadData, setPremiumModalContent, setIsPremiumModalVisible, onRemove, error, notifiyAssetUploaded}) => {
    return (
        <div className="uploads">
            <div id="eventform-preview">
                <label>Logo</label>
                <ImageUpload
                    file={uploadData.preview ? uploadData.preview : uploaded.preview ? {preview: uploaded.preview} : uploadData.preview}
                    onSelect={file => setUploadData(state => ({
                        ...state,
                        preview: {file: file, preview: URL.createObjectURL(file)}
                    })) || notifiyAssetUploaded()
                    }
                    onRemove={onRemove}
                    onClear={() => {
                        uploadData.preview && URL.revokeObjectURL(uploadData.preview.preview)
                        setUploadData(state => ({...state, preview: null}))
                    }}
                    error={error}
                />
                <div className="help-text">
                    <p>
                        Hinweis: 190 x 110 px / JPG,PNG / 1 MB
                        <br/>
                        <button data-button="flat"
                                onClick={() => setPremiumModalContent(premiumHints.highlightOnline) || setIsPremiumModalVisible(true)}>
                            Anzeige-Beispiel
                        </button>
                    </p>
                </div>
            </div>
        </div>
    )
}

const PremiumFlags = props => {
    return (
        <div className="premiumFlags">
            <div className="form-group-container">
                <Checkbox
                    selected={props.highlightOnline}
                    label="Online hervorheben"
                    readOnly={true}
                    flag={true}
                />
                <button data-button="flat"
                        onClick={() => props.setPremiumModalContent(premiumHints.highlightOnline) || props.setIsPremiumModalVisible(true)}>
                    (Anzeige-Beispiel)
                </button>
            </div>
            <div className="form-group-container">
                <Checkbox
                    selected={props.highlightPrint}
                    label="Print hervorheben"
                    readOnly={true}
                    flag={true}
                />
                <button data-button="flat"
                        onClick={() => props.setPremiumModalContent(premiumHints.highlightPrint) || props.setIsPremiumModalVisible(true)}>
                    (Anzeige-Beispiel)
                </button>
            </div>
            <div className="form-group-container">
                <Checkbox
                    selected={props.highlightPrintPlus}
                    label="Inserat publizieren"
                    readOnly={true}
                    flag={true}
                />
                <button data-button="flat"
                        onClick={() => props.setPremiumModalContent(premiumHints.highlightPrintPlus) || props.setIsPremiumModalVisible(true)}>
                    (Anzeige-Beispiel)
                </button>
            </div>
        </div>
    )
}

export default withRouter(EventForm)
