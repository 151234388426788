import React, {useState, useEffect} from 'react'
import {Link, NavLink, withRouter} from 'react-router-dom'
import EventCard from '../components/EventCard'
import IconButton from '../components/IconButton'
import Paginate from '../components/Paginate'
import useAccountInformation from '../hooks/AccountInformation'
import useClickOutside from '../hooks/ClickOutside'
import logo from '../images/logo.svg'
import axios from 'axios'
import ProfileModal from "../modals/ProfileModal";
import ConfirmDeleteModal from '../modals/ConfirmDeleteModal'
import {Helmet} from "react-helmet";
import useNotifications from "../hooks/Notifications";
import {CookiesNotice} from "../components/CookiesNotice";
import useHiddenNotice from "../hooks/Cookie";
import useGoogleAnalytics, {userActions} from "../hooks/GoogleAnalytics";
import {useGlobalState} from "../App";
import {notifications} from '../constants/constants'

const Manager = (props) => {
    const [events, setEvents] = useState([])
    const [isDirty, setIsDirty] = useState(true)
    const [visibleEvents, setVisibleEvents] = useState([])
    const [showPastEvents, setShowPastEvents] = useState(false)
    const [isProfileModalVisible, setIsProfileModalVisible] = useState(false)

    const [isNavVisible, setIsNavVisible] = useClickOutside('site-nav')
    const [isSidebarVisible, setIsSidebarVisible] = useClickOutside('manager')

    const [ isDeleteModalVisible, setIsDeleteModalVisible ] = useState(false)
    const [eventToDelete, setEventToDelete] = useState()

    const [account, refresh, setUser] = useAccountInformation()
    const [user, setCurrentUser] = useState(account)

    const [createNotification, renderNotifications, renderIfDirty] = useNotifications()

    const [ noticeHidden ] = useHiddenNotice()

    const [notificationsDirty, setNotificationsDirty] = useGlobalState('notificationsDirty');

    const [emitGAEvent] = useGoogleAnalytics()

    const getFutureEvents = events => {
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        return events.filter(event => {
            const hasDates = event.start && event.end
            return !hasDates || (event.end ? event.end >= today : event.start >= today)
        })
    }

    const fetchEvents = async () => {
        let {data} = await axios.get('/api/manager/events')
        // console.log('got events', data)

        if (props.location.state && props.location.state.new) {
            data = removeFromListById(data, props.location.state.new.id)
            data.push(props.location.state.new)
        }

        const eventsData = data.map(e => transformDates(e))
        // console.log('transformed events', eventsData)

        setEvents(eventsData)
        setVisibleEvents(sortEvents(getFutureEvents(eventsData)))
    }

    const deleteEvent = async () => {
        setIsDeleteModalVisible(false)
        const id = eventToDelete.id
        await axios.delete(`/api/events/${id}`)
        const eventList = events.filter(e => e.id !== id);
        setEvents(eventList)
        setVisibleEvents(sortEvents(getFutureEvents(eventList)))
        createNotification(notifications.deleted, null, eventToDelete.title)
    }

    const duplicateEvent = async event => {
        let copy = deepCopy(event); //deep copy
        let eventList = deepCopy(events); //deep copy
        eventList = eventList.map(e => transformDates(e))

        copy.status = 'draft'
        copy.duplicated = true
        delete copy.id
        delete copy.webId
        await axios.post('/api/events/', {data: formatDates(copy)}).then((res) => {
            if (res.status === 200) {
                eventList.push(transformDates(res.data))
                setEvents(eventList)
                if (!showPastEvents)
                    setVisibleEvents(sortEvents(getFutureEvents(eventList)))
                else
                    setVisibleEvents(sortEvents(eventList))

                createNotification(notifications.duplicated)
            }
        })
    }

    const deepCopy = object => JSON.parse(JSON.stringify(object))

    const transformDates = event => {
        event.creationDate = new Date(event.creationDate)
        if (event.start  && !(event.start instanceof Date)) event.start = new Date(event.start)
        if (event.end && !(event.end instanceof Date)) event.end = new Date(event.end)
        if (event.deadline && event.deadline.length && !(event.deadline instanceof Date)) event.deadline = new Date(event.deadline)
        return event
    }

    const formatDates = data => {
        data.start = formatDate(data.start)
        data.end = formatDate(data.end)
        if (data.deadline && data.deadline.length)
            data.deadline = formatDate(data.deadline)
        return data
    }

    const formatDate = date => {
        const d = new Date(date)
        const month = (d.getMonth() + 1).toString().padStart(2, '0')
        const day = d.getDate().toString().padStart(2, '0')
        const year = d.getFullYear()
        return [year, month, day].join('-')
    }

    const profileModalAction = async (formData) => {
        const data = {...(formData ? formData : account), firstLogin: true};
        await axios.post('/api/user/info', {data: data})
        setUser(account)
        refresh()
        setIsProfileModalVisible(false)
    }

    const sortEvents =  (events) => (events.sort((a, b) => b.creationDate - a.creationDate))

    useEffect(() => {
        setIsProfileModalVisible(account && account.firstLogin)
        setCurrentUser(account)
    }, [account])

    useEffect(() => {
        setVisibleEvents(sortEvents(showPastEvents ? events : getFutureEvents(events)))
    }, [showPastEvents])

    useEffect(() => {
        fetchEvents()
        setIsDirty(false)
    }, [])


    useEffect(() => {
        if (props.location.state && props.location.state.new) {
            let data = deepCopy(events)
            data = removeFromListById(data, props.location.state.new.id)
            data.push(formatDates(props.location.state.new))
            setEvents(data)
            setVisibleEvents(sortEvents(getFutureEvents(data)))
        }
    }, [props.location.state && props.location.state.new])

    useEffect(() => {
        // if (props.location.state && props.location.state.renderNotifications) {
        //     renderIfDirty()
        //     props.history.replace('/manager', null)
        // }

        console.log('props.location.state', props.location.state)
    }, [props.location.state && props.location.state.renderNotifications ])

    useEffect(() => {
        if (!isDirty && props.location.state && props.location.state.render) {
            fetchEvents()
            setIsDirty(false)
        }
    }, [props.location.state && props.location.state.render])


    const onLogout = async () => {
        await axios.get('/logout')
        props.history.push('/')
        createNotification(notifications.logout)
    }

    return (
        <div className="manager-main">
            <Helmet>
                <title>Manager - Swiss Medical Events</title>
            </Helmet>
            <header className="site-header">
                <div>
                    <a href="/" className="header-link">
                        <div className="wrap">
                            <img src={logo} className="logo" alt="EMH Logo"/>
                            <h1 className="serif">Swiss Medical Events</h1>
                            <h2 className={'header-sub-title'}>Kongresse, Seminare<br />Weiter- und Fortbildung</h2>
                        </div>
                    </a>
                    <span className="icon-bars show-mobile" onClick={() => setIsNavVisible(state => !state)}/>
                </div>
                <div>
                    <div className="wrap">
                        <nav className="site-nav">
                            <ul>
                                <li><a href="http://emh.ch" target="_blank">Über uns</a></li>
                                <li><button onClick={() => emitGAEvent(userActions.enterEvent) || props.history.push('/manager/event')}>Event eintragen</button></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
            <main className="site-main manager">
                <div className={`sidebar ${isNavVisible ? 'is-visible' : ''}`}>
                    <div className="wrap">
                        <div className="sidebar-header">
                            <span className="sidebar-close icon-times-circle-outline"
                                  onClick={() => setIsSidebarVisible(state => !state)}/>
                        </div>
                        <nav className="sidebar-nav">
                            <ul>
                                <li>
                                    <span style={{cursor: 'default'}} className="icon-account"/>
                                    <div>
                                        {account && (
                                            <>
                                                <p className="name">{user ? user.firstname : ''} {user ? user.lastname : ''}</p>
                                                <p className="user">{account.email}</p>
                                            </>
                                        )}
                                        <p className="logout"><a style={{cursor: 'pointer'}}  onClick={onLogout}>Abmelden</a></p>
                                    </div>
                                </li>
                                <li>
                                    <NavLink className="nav-link highlighted" to="/manager">
                                        <span className="icon-calendar"/>
                                        Event Manager
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="nav-link" to="/manager/profile">
                                        <span className="icon-badge"/>
                                        Ihr Profil
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="backdrop"/>
                <div className="site-content event-manager">
                    <div className="wrap">
                        <div className="result-header">
                            <h2 className="h1 count">Event Manager</h2>
                            <a data-button href="/manager/event">Neues Event</a>
                        </div>
                    </div>
                    <div className="result-list management-list">
                        <div className="wrap">
                            <h2>Liste Ihrer Events</h2>
                            <Paginate
                                items={visibleEvents}
                                itemRenderer={event => (
                                    <div className="management-item">
                                        <EventCard
                                            canEdit={event.status !== 'review'}
                                            key={event.title}
                                            event={event}
                                            backend={true}/>
                                        <div className="options">
                                            <div className={`status-${event.status}`}>
                                                {event.status === 'draft' ? 'Entwurf' : event.status === 'review' ? 'Wird überprüft' : 'Veröffentlicht'}
                                            </div>
                                            <div>
                                                {event.status !== 'review' && (
                                                    <IconButton
                                                        icon="pen"
                                                        message="Bearbeiten"
                                                        onClick={() => props.history.push(`/manager/event/${event.webId}`)}/>
                                                )}
                                                <IconButton
                                                    icon="trash"
                                                    message="Löschen"
                                                    onClick={() => setEventToDelete(event) || setIsDeleteModalVisible(true)}/>
                                                <IconButton
                                                    icon="clone"
                                                    message="Duplizieren"
                                                    onClick={() => duplicateEvent(event)}/>
                                                {event.status !== 'review' ? (
                                                    <IconButton
                                                        icon="external"
                                                        message="Vorschau"
                                                        href={`/event/${event.webId}`}
                                                    />
                                                ) : ''}
                                            </div>
                                        </div>
                                    </div>
                                )}/>
                            <button
                                data-button
                                onClick={() => setShowPastEvents(state => !state)}>
                                Vergangene Events {showPastEvents ? 'ausblenden' : 'anzeigen'}
                            </button>
                        </div>
                    </div>
                </div>
            </main>
            {account && account.firstLogin && isProfileModalVisible && (
                <ProfileModal
                    firstLogin={true}
                    onClose={() => profileModalAction()}
                    onConfirm={(data) => profileModalAction(data)}
                    account={account}
                />
            )}
            {isDeleteModalVisible && (
                <ConfirmDeleteModal
                    onDismiss={() => setIsDeleteModalVisible(false)}
                    onConfirm={(data) => deleteEvent()}
                    event={eventToDelete}
                />
            )}
        </div>
    )
}

const removeFromListById = (data, id) => {
    data.forEach((e, i) => {
        if (e.id === id)
            data.splice(i, 1)
    })
    return data;
}

export default withRouter(Manager)
export {removeFromListById}