import React, {useState, useEffect, useRef} from 'react'
import useClickOutside from '../hooks/ClickOutside'
import AgbModal from '../modals/AgbModal'
import ImpressumModal from '../modals/ImpressumModal'
import PolicyModal from '../modals/PolicyModal'
import PremiumInfoModal from '../modals/PremiumInfoModal'

const Footer = (props) => {
    const [isAgbModalVisible, setIsAgbModalVisible] = useClickOutside('modal-wrapper')
    const [isImpressumModalVisible, setIsImpressumModalVisible] = useClickOutside('modal-wrapper')
    const [isPolicyModalVisible, setIsPolicyModalVisible] = useClickOutside('modal-wrapper')

    const [isPremiumInfoModalVisible, setIsPremiumInfoModalVisible] = useClickOutside('modal-wrapper')

    return (
        <>
            <footer className="site-footer">
                <div className="wrap">
                    <div className="footer-column">
                        <h2 className="serif">Swiss Medical Events</h2>
                        <p>Der Veranstaltungskalender für Kongresse, Seminare, Weiter- und Fortbildung in der Medizin.</p>
                        <p>© EMH Schweizerischer Ärzteverlag AG</p>
                    </div>
                    <div className="footer-column">
                        <h2>Kontakt</h2>
                        <address>
                            EMH Schweizerischer Ärzteverlag<br/>
                            Farnsburgerstrasse 8<br/>
                            CH-4132 Muttenz<br/>
                            Tel. +41 61 467 85 55<br/>
                            <a href="mailto:events@emh.ch">events@emh.ch</a>
                        </address>
                    </div>
                    <div className="footer-column">
                        <h2>Service</h2>
                        <ul className="unstyled-list">
                            <li>
                                <button data-button="flat"
                                        onClick={() => {
                                            props.setIsLoginVisible(true)
                                        }}>
                                    Event eintragen
                                </button>
                            </li>
                            <li>
                                <button data-button="flat"
                                        onClick={() => {
                                            setIsPremiumInfoModalVisible(true)
                                        }}>
                                    Inserieren
                                </button>
                            </li>
                            <li>
                                <p></p>
                            </li>
                            <li>
                                <button data-button="flat"
                                        onClick={() => setIsAgbModalVisible(true)}>
                                    AGB
                                </button>
                            </li>
                            <li>
                                <button data-button="flat"
                                        onClick={() => setIsPolicyModalVisible(true)}>
                                    Disclaimer & Datenschutz
                                </button>
                            </li>
                            <li>
                                <button data-button="flat"
                                        onClick={() => setIsImpressumModalVisible(true)}>
                                    Impressum
                                </button>
                            </li>
                            <li>
                                <p></p>
                            </li>
                            <li>
                                <a target="_blank" rel="noopener noreferrer" href="https://emh.ch/lesen">
                                    <span className="icon-external"/>
                                    EMH Zeitschriften
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
            {isPremiumInfoModalVisible && (
                <PremiumInfoModal onClose={() => {
                    setIsPremiumInfoModalVisible(false)
                }}></PremiumInfoModal>)}
            {isAgbModalVisible && (
                <AgbModal onClose={() => {
                    setIsAgbModalVisible(false)
                }}>
                </AgbModal>)}
            {isImpressumModalVisible && (
                <ImpressumModal onClose={() => {
                    setIsImpressumModalVisible(false)
                }}>
                </ImpressumModal>)}
            {isPolicyModalVisible && (
                <PolicyModal onClose={() => {
                    setIsPolicyModalVisible(false)
                }}>
                </PolicyModal>)}
        </>
    )
}

export default Footer
