import React from 'react'

const PremiumModal = props => {
    return (
        <div className="modal-container">
            <div className="modal-wrapper">
                <span className="icon-times-circle-outline" onClick={() => props.onClose()}/>
                <div className="modal-body">
                    <h1>{props.content.title}</h1>
                    <h2>{props.content.heading}</h2>
                    {/*<p>{props.content.text}</p>*/}
                    <div className="images">
                        {props.content.images.map(image => (
                            <div className={`image size-${image.size}`}>
                                <h3>{image.title}</h3>
                                <img src={image.image} alt={image.title}/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PremiumModal
