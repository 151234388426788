import React, {useEffect, useState} from 'react'
import useAccountInformation from '../hooks/AccountInformation'
import { defaultFormData, validateProfile, ProfileForm, getMissing } from '../pages/Profile'
import axios from "axios";
import {notifications} from "../constants/constants";
import useNotifications from "../hooks/Notifications";

const ProfileModal = props => {
    const [ account, refresh, setUser ] = useAccountInformation()

    const [ formData, setFormData ] = useState(defaultFormData)
    const [missing, setMissing] = useState([])
    const [createNotification, renderNotifications] = useNotifications()


    const checkAndPostRequest = async () => {
        const missingFields = getMissing(formData)
        console.log('missing dataaa', missingFields)
        if (missingFields.length) {
            setMissing(missingFields)
            console.log('abort')
            return;
        }

        let data = formData;
        await axios.post('/api/user/info', { data: data })
        // setUser(data)
        // console.log('account dataaa', account)
        // refresh()
        createNotification(notifications.userdataUpdated)
        props.onConfirm(data)
    }

    // useEffect(() => {
    //     console.log('account dataaa', account)
    //     // if (account && validateProfile(account))
    //     //     props.onConfirm()
    // }, [account])

    useEffect(() => {
        setFormData({...props.account, dataNotice: [props.account.policyAccepted ? props.account.policyAccepted : null]})
    }, [])


    return (
        <div className="modal-container">
            <div className="modal-wrapper">
                <span className="icon-times-circle-outline" onClick={props.onClose} />
                <div className="modal-body">
                    <h1>Profil</h1>
                    {props.firstLogin ? (
                        <p>Bitte stellen Sie sicher, dass alle erforderlichen Felder korrekt ausgefüllt sind.</p>
                    ) : (
                        <p>Bitte stellen Sie sicher, dass alle erforderlichen Felder korrekt ausgefüllt sind.</p>
                    )}
                    {formData && (
                        <ProfileForm
                            formData={formData}
                            setFormData={setFormData}
                            error={missing}/>
                    )}
                    <div className="form-options">
                        {props.firstLogin ?
                            (<button data-button="flat" onClick={() => props.onClose()}>Überspringen</button>)
                            : (<button data-button="flat" onClick={() => props.onClose() }>Abbrechen</button> )
                        }
                        <button data-button onClick={() => checkAndPostRequest()}>Speichern</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileModal
