import { useState, useEffect } from 'react'
import useStateWithSessionStorage from "./SessionStorage";
import * as moment from 'moment';


const useFilter = (localStorageKey, initialState) => {

    const [value, setValue] = useState(
        deserializeDates(JSON.parse(sessionStorage.getItem(localStorageKey))) || initialState || null
    )

    useEffect(() => {
        console.log('hook value', value)
        if (value)
            sessionStorage.setItem(localStorageKey, JSON.stringify(serializeDates(value)));
        else
            sessionStorage.removeItem(localStorageKey)
    }, [value])

    return [value, setValue]
}

const serializeDates = filter => {
    //converts moments to ms
    if (filter.startDate)
        filter.startDate = filter.startDate.valueOf()

    if (filter.endDate)
        filter.endDate = filter.endDate.valueOf()

    return filter
}

const deserializeDates = filter => {
    if (!filter) return null

    //converts ms to dates
    if (typeof filter.startDate === 'number') {
        filter.startDate = new Date(filter.startDate)
    }

    if (typeof filter.endDate === 'number') {
        filter.endDate = new Date(filter.endDate)

    }
    return filter

}


export default useFilter
export {deserializeDates}