import React, {useState} from 'react'
import {formatDates} from "./EventCard";

import {formatDate, isDeadlineReached} from "./../pages/Detail";

import Popup from "./Popup";
import {associations} from "../constants/constants";

const EventDetail = ({event, admin}) => {
    const prefixLink = link => link.startsWith('http') ? link : `https://${link}`
    const [ creditsVisible, setCreditsVisible ] = useState(true)

    return (
        <>
            <div className="detail-content">
                <div className="wrap">
                    <div /*className={admin ? 'halves' : ''}*/>
                        <div>
                            <div className="detail-meta">
                                <b>{event.type}</b><span> {event.departments.length ? '—':''} </span>{event.departments.join(', ')}
                            </div>
                            <div className="detail-header">
                                <div className="detail-sub">
                                    <div>
                                        {formatDates(event.start, event.end)}
                                        {event.startTime && event.endTime && `, ${event.startTime}–${event.endTime}`}
                                    </div>
                                </div>
                                <div>
                                    <h1 className="detail-title">{event.title}</h1>
                                </div>
                            </div>
                            {!admin && (
                                <div className="sharing-buttons-mobile">
                                    <div className="options">
                                        <Popup icon="calendar">
                                            <ul>
                                                <li><a href={getGoogleCalendarLink(event)} target="_blank" rel="noopener noreferrer">Google Calendar</a></li>
                                                <li><a href={`/api/events/${event.id}/ics`} rel="noopener noreferrer">iCal</a></li>
                                            </ul>
                                        </Popup>
                                        <span className="icon-print" onClick={() => window.print()} />
                                        <Popup icon="share">
                                            <ul>
                                                <li><a href={`mailto:?body=${encodeURIComponent(window.location.href)}`} target="_blank" rel="noopener noreferrer"><span className="icon-envelope" /> Mail</a></li>
                                                <li><a href={`https://wa.me/?text=${encodeURIComponent(window.location.href)}`} target="_blank" rel="noopener noreferrer"><span className="icon-whatsapp" /> WhatsApp</a></li>
                                                <li><a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}`} target="_blank" rel="noopener noreferrer"><span className="icon-linkedin" /> LinkedIn</a></li>
                                            </ul>
                                        </Popup>
                                    </div>
                                </div>
                            )}
                            {!admin && (event.desktop || event.mobile) && (
                                <div className="detail-item">
                                    {event.desktop && <img className={'img-desktop'} src={event.desktop} />}
                                    {event.mobile && <img className={'img-mobile'} src={event.mobile} />}
                                </div>
                            )}

                            <div className="detail-item">
                                <h4>Beschreibung</h4>
                                <p>{event.description}</p>
                            </div>
                            {admin && event.printSummary && (
                                <div className="detail-item">
                                    <h4>Kurzbeschreibung für Printversion</h4>
                                    <p>{event.printSummary}</p>
                                </div>
                            )}
                            <div className="detail-item">
                                {event.speakers && event.speakers.length ? (
                                    <>
                                        <h4>Speaker</h4>
                                        <ul>
                                            {event.speakers.map(s => (
                                                <li key={s}>{s}</li>
                                            ))}
                                        </ul>
                                    </>
                                ):''}

                            </div>
                            <div className="detail-item">
                                {event.languages && event.languages.length ? (
                                    <>
                                        <h4>Sprache</h4>
                                        <ul>
                                            <li>{event.languages.join(', ')}</li>
                                        </ul>
                                    </>
                                ):''}
                            </div>
                            {(event.program || event.website) && (
                                <div className="detail-item more-info">
                                    <h4>Mehr Informationen</h4>
                                    {event.program && (
                                        <a href={prefixLink(event.program)} target="_blank" rel="noopener noreferrer">
                                            <span className="icon-document" />
                                            Programm (pdf)
                                        </a>
                                    )}
                                    {event.program && event.website && (
                                        <br />
                                    )}
                                    {event.website && (
                                        <a href={prefixLink(event.website)} target="_blank" rel="noopener noreferrer">
                                            <span className="icon-external" />
                                            {event.website}
                                        </a>
                                    )}
                                </div>
                            )
                            }
                        </div>
                    </div>
                    <div className="halves">
                        <div>
                            <div className="detail-item">
                                <h4>Veranstaltungsort</h4>
                                {event.type === 'Online' && (
                                    <div>
                                        <b> Online</b>
                                    </div>
                                )}
                                {event.type !== 'Online' && (
                                    <div>
                                    <b>{event.venue}</b><br/>
                                    <span>{event.room}</span>
                                    </div>
                                    )
                                }

                            </div>
                            {event.address && (
                                <div className="detail-item">
                                    <h4>Adresse</h4>
                                    <address>
                                        {event.address.street} {event.address.street && (<br />)}
                                        {event.address.postalcode} {event.address.city} {(event.address.postalcode || event.address.city) && (<br />)}
                                        {event.address.country}
                                    </address>
                                    <a href={`https://maps.google.com/?q=${encodeURIComponent(event.address.street)},${encodeURIComponent(event.address.city)},${encodeURIComponent(event.address.country)}`} target="_blank" rel="noopener noreferrer"><span className="icon-marker" /> Google Maps</a>
                                </div>
                            )}
                        </div>
                        <div>
                            {event.company && event.company.length ? (
                                <div className="detail-item">
                                    <h4>Veranstalter</h4>
                                    <b>{event.company}</b>
                                </div>)
                                : ''}
                            <div className="detail-item">
                                <h4>Kontakt</h4>
                                <b>{event.contact}</b> {event.contact && (<br />)}
                                {event.phone} {event.phone && (<br />)}
                                <a className="printable" href={`mailto:${event.mail}`}>{event.mail}</a>
                            </div>
                        </div>
                    </div>
                </div>
                {!admin && (
                    <div className="sharing-buttons-desktop">
                        <div className="options">
                            <Popup icon="calendar">
                                <ul>
                                    <li><a href={getGoogleCalendarLink(event)} target="_blank" rel="noopener noreferrer">Google Calendar</a></li>
                                    <li><a href={`/api/events/${event.id}/ics`} rel="noopener noreferrer">iCal</a></li>
                                </ul>
                            </Popup>
                            <span className="icon-print" onClick={() => window.print()} />
                            <Popup icon="share">
                                <ul>
                                    <li><a href={`mailto:?body=${encodeURIComponent(window.location.href)}`} target="_blank" rel="noopener noreferrer"><span className="icon-envelope" /> Mail</a></li>
                                    <li><a href={`https://wa.me/?text=${encodeURIComponent(window.location.href)}`} target="_blank" rel="noopener noreferrer"><span className="icon-whatsapp" /> WhatsApp</a></li>
                                    <li><a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}`} target="_blank" rel="noopener noreferrer"><span className="icon-linkedin" /> LinkedIn</a></li>
                                </ul>
                            </Popup>
                        </div>
                    </div>
                )}
                {admin && (
                    <div>
                        <div className="detail-item">
                            {event.preview && (
                                <div className="logo">
                                    <img src={event.preview} alt={event.title} />
                                </div>
                            )}
                        </div>
                        {event.deadline && event.deadline.length ? (
                            <div className="detail-item">
                                <h4>Anmeldung</h4>
                                <div className="widget-line">
                                    {isDeadlineReached(event.deadline) ? (
                                        <>
                                            <span className="icon-times-circle-outline" />
                                            <div className="registration">
                                                <h3>Einschreibung geschlossen</h3>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <span className="icon-check-circle">
                                                    <span className="path1" />
                                                    <span className="path2" />
                                                </span>
                                            <div className="registration">
                                                <h3>Einschreibung geöffnet bis {formatDate(event.deadline)}</h3>
                                                <a href={prefixLink(event.signup)} target="_blank" rel="noopener noreferrer">{prefixLink(event.signup)}</a>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        ) : ''}
                        {event.credits && event.credits.length && (
                            <div className="detail-item">
                                <h4>Credits</h4>
                                <div className="widget-line">
                                    {event.credits.confirmed.length > 0 && (
                                        <span className="icon-check-circle">
                                                <span className="path1" />
                                                <span className="path2" />
                                            </span>
                                    )}
                                    {!event.credits.confirmed.length && !event.credits.notConfirmed.length && (
                                        <span className="icon-times-circle-outline" />
                                    )}
                                    {!event.credits.confirmed && event.credits.notConfirmed.length && (
                                        <span className="icon-check-circle">
                                            </span>
                                    )}
                                    <div className="credits">
                                        <h3>{event.credits.confirmed.length ? 'Credits bestätigt' : event.credits.notConfirmed.length ? 'Credits beantragt': 'keine Credits'}</h3>
                                    </div>
                                </div>
                                <div className="widget-credits" style={{ maxHeight: creditsVisible ? 'none' : '0' }}>
                                    {event.credits.confirmed.map(credit => (
                                        <div key={credit.association} className="widget-line">
                                            <div className="count-indicator">{credit.count}</div>
                                            <div>
                                                <b>{credit.association}</b><br />
                                                {associations[credit.association]}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {event.credits.notConfirmed.length > 0 && creditsVisible && (
                                    <div className="widget-line-unconfirmed" style={{display: creditsVisible ? 'none' : 'auto'}} style={{marginTop: creditsVisible ? '2em' : 'auto'}}>
                                                <span className="icon-circle">
                                                </span>
                                        <div>
                                            <h3>Credits beantragt</h3>
                                        </div>
                                    </div>
                                )}
                                <div className="widget-credits" style={{ maxHeight: creditsVisible ? 'none' : '0' }}>
                                    {event.credits.notConfirmed.map(credit => (
                                        <div key={credit.association} className="widget-line">
                                            <div className="count-indicator">{credit.count}</div>
                                            <div>
                                                <b>{credit.association}</b><br />
                                                {associations[credit.association]}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {/*{event.credits.length > 0 && (*/}
                                {/*<div className="widget-toggle" onClick={() => setCreditsVisible(state => !state)}>*/}
                                {/*<span className={`icon-arrow-down ${creditsVisible ? 'upside-down' : ''}`} />*/}
                                {/*{creditsVisible ? 'Schliessen' : 'Details'}*/}
                                {/*</div>*/}
                                {/*)}*/}
                            </div>
                        )

                        }</div>
                )}
            </div>
        </>
    )
}

const getGoogleCalendarLink = event => {
    let start = `${event.start.getFullYear()}${event.start.getMonth().toString().padStart(2, '0')}${event.start.getDate().toString().padStart(2, '0')}`
    let end = `${event.end.getFullYear()}${event.end.getMonth().toString().padStart(2, '0')}${event.end.getDate().toString().padStart(2, '0')}`

    if (event.startTime) {
        start += `T${event.startTime.split(':').join('')}00Z`
    }

    if (event.endTime) {
        end += `T${event.endTime.split(':').join('')}00Z`
    }

    const location = event.address ? `${event.address.street},${event.address.postalcode}+${event.address.city},${event.address.country}` : "";

    return `https://www.google.com/calendar/render?action=TEMPLATE&text=${event.title}&dates=${start}/${end}&location=${location}&sf=true&output=xml`
}

export default EventDetail;
