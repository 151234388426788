import React from 'react'

const AgbModal = props => {
    return (
        <div className="modal-container">
            <div className="modal-wrapper">
                <span className="icon-times-circle-outline" onClick={() => props.onClose()}/>
                <div className="modal-body">
                    <h1>Allgemeine Geschäftsbedingungen (AGB)</h1>
                    <h2>Bestellungen</h2>
                    <p>Eine Bestellung von Produkten über die Website erfolgt durch Klicken auf die Schaltfläche
                        „Veröffentlichen“. Nachdem Sie eine Bestellung für die kostenpflichtige Publikation einer
                        Veranstaltung oder eines Inserates auf der Website aufgegeben haben, wird EMH den Eingang der
                        Bestellung bestätigen.</p>
                    <h2>Bestellbestätigung</h2>
                    <p>Sobald Sie Ihre Bestellung abgeschickt haben, bestätigt die EMH Schweizerischer Ärzteverlag AG
                        den Eingang der Bestellung(en). Nach der Bestätigung des Eingangs Ihrer Bestellung(en) werden
                        wir Ihre Bestellung überprüfen. Ein Vertrag über den Verkauf von Produkten zwischen Ihnen und
                        der EMH Schweizerischer Ärzteverlag AG kommt nur dann zustande, wenn die EMH Schweizerischer
                        Ärzteverlag AG bestätigt, dass alle notwenigen Daten und Informationen vorhanden sind, und die
                        Bestätigung verschickt hat, dass der Vertrag abgeschlossen wurde.</p>
                    <p>Der Vertrag über den Verkauf von Produkten bezieht sich nur auf diejenigen Produkte, die wir
                        bestätigt haben.</p>
                    <h2>Preise</h2>
                    <p>Als zu zahlende Preise für die bestellte Publikation ihrer Veranstaltung/ ihres Inserats gelten
                        die Preise auf dieser Website an dem Datum, an dem Sie Ihre Bestellung aufgeben, einschliesslich
                        aller anwendbaren Umsatzsteuern.</p>
                    <p>EMH Media behält sich das Recht vor, die Preise der Produkte in der Zukunft zu ändern.</p>
                    <h2>Zahlung</h2>
                    <p>Die Zahlung Ihrer Bestellung erfolgt per Rechnung. Rechnungen werden nach Ausführung der
                        Bestellung verschickt und sind innerhalb von dreissig (30) Tagen vollständig zahlbar.</p>
                    <p>Die EMH Schweizerischer Ärzteverlag AG behält sich das Recht vor, jede neue Bestellung bis zur
                        vollständigen Bezahlung eines überfälligen Saldos abzulehnen. Wenn bei Zahlung auf Rechnung die
                        Zahlung nicht innerhalb einer Frist von dreissig (30) Tagen nach Fälligkeit vorgenommen wurde,
                        wird die EMH Schweizerischer Ärzteverlag AG ein Mahnverfahren einleiten. In diesem Fall behält
                        sich die EMH Schweizerischer Ärzteverlag AG das Recht vor, Mahngebühren und Verzugszinsen
                        zusätzlich zu der Rechnung zu erheben. Der Rechnungsbetrag sowie die Verzugszinsen und
                        Mahngebühren können an ein externes Inkasso-Unternehmen zum Forderungseinzug übertragen werden.
                        Sie sind ebenfalls verpflichtet, die Mahnkosten dieses Unternehmens zusätzlich zu den offenen
                        Beträgen bei EMH Schweizerischer Ärzteverlag zu begleichen.</p>
                    <h2>Publikation</h2>
                    <p>Alle Bestellungen, die von EMH Schweizerischer Ärzteverlag AG bestätigt werden, werden wie
                        abgesprochen publiziert.</p>
                    <p>Zum Zeitpunkt der Publikation werden Sie gebeten, zu überprüfen, ob die Publikation ihrer
                        Veranstaltung/Ihres Inserates mit den vereinbarten Abmachungen übereinstimmt.
                        Falls Sie feststellen, dass das nicht der Fall ist, müssen Sie uns innerhalb von sieben
                        (7) Tagen nach der Publikation benachrichtigen.</p>

                    <h2>Haftung</h2>
                    <p>Die Fotos und Texte, die die Produkte auf dieser Website veranschaulichen und
                        beschreiben, sind ausservertraglich und nur zu Informationszwecken. EMH
                        Schweizerischer Ärzteverlag AG haftet nicht für Fehler oder Auslassungen in den
                        Fotos oder Texten, die auf dieser Website angezeigt werden.</p>

                    <h2>Höhere Gewalt</h2>
                    <p>Die EMH Schweizerischer Ärzteverlag AG wird alle angemessenen Anstrengungen unternehmen,
                        ihren Verpflichtungen nachzukommen. EMH Schweizerischer Ärzteverlag AG kann jedoch nicht
                        für Verzögerungen oder Versäumnisse verantwortlich gehalten oder haftbar gemacht werden,
                        die durch Umstände verursacht werden, die ausserhalb ihrer angemessenen Kontrolle liegen
                        („höhere Gewalt“). Solche Umstände beinhalten ohne Einschränkung Streiks und
                        Tarifkonflikte durch Dritte, Bürgerkriege, Unruhen, Kriege, Naturkatastrophen oder
                        jegliche andere, die die Produktion, den Transport oder die Lieferung von Produkten
                        undurchführbar oder unmöglich machen.
                        Im Falle einer Verzögerung, die durch höhere Gewalt verursacht wird, werden die
                        Verpflichtungen von EMH Schweizerischer Ärzteverlag AG ausgesetzt und die Zeit für die
                        Erfüllung unserer Verpflichtungen für die Dauer des Ereignisses verlängert. EMH
                        Schweizerischer Ärzteverlag AG wird ihren Verpflichtungen sobald wie möglich nachkommen
                        und behält sich das Recht vor, den verbleibenden Produktbestand gerecht und angemessen
                        unter den Kunden aufzuteilen.</p>

                    <h2>Geltendes Recht und Gerichtsstand</h2>
                    <p>Die vorliegenden Allgemeinen Geschäftsbedingungen und der Kauf von Produkten von EMH
                        Schweizerischer Ärzteverlag AG unterliegen Schweizer Recht.
                        Alle Streitigkeiten oder Ansprüche aus oder in Zusammenhang mit den Allgemeinen
                        Geschäftsbedingungen oder dem Kauf von Produkten von EMH Schweizerischer Ärzteverlag AG
                        (einschliesslich ausservertraglicher Streitigkeiten oder Ansprüche) unterliegen der
                        Gerichtsbarkeit der Schweiz.</p>
                </div>
            </div>
        </div>
    )
}

export default AgbModal
