import React, {useState, useEffect} from 'react'
import {NavLink, withRouter} from 'react-router-dom'
import FloatingTextBox from '../components/FloatingTextBox'
import FloatingTextArea from '../components/FloatingTextArea'
import FloatingDatePicker from '../components/FloatingDatePicker'
import FloatingTimePicker from '../components/FloatingTimePicker'
import FloatingAddressBox from '../components/FloatingAddressBox'
import FloatingSingleSelect from '../components/FloatingSingleSelect'
import FloatingMultiSelect from '../components/FloatingMultiSelect'
import RadioGroup from '../components/RadioGroup'
import CheckboxGroup from '../components/CheckboxGroup'
import Checkbox from '../components/Checkbox'
import Credit from '../components/Credit'
import ImageUpload from '../components/ImageUpload'
import FileUpload from '../components/FileUpload'
import ProfileModal from '../modals/ProfileModal'
import PremiumModal from '../modals/PremiumModal'
import {validateProfile} from '../pages/Profile'
import useAccountInformation from '../hooks/AccountInformation'
import useClickOutside from '../hooks/ClickOutside'
import logo from '../images/logo.svg'
import axios from 'axios'
import FormData from 'form-data'
import {departments, issuesSMF} from '../constants/constants'
import {Helmet} from "react-helmet"
import useNotifications from '../hooks/Notifications'


// axios.interceptors.request.use(request => {
//     console.log('Server Request', request)
//     return request
// })

// axios.interceptors.response.use((response) => {
//     // Do something with response data
//     return response;
// }, error => {
//     console.log('Server Error Response', error.response)
//     return Promise.reject(error)
// })

const defaultFormData = {
    title: '',
    company: '',
    room: '',
    venue: '',
    contact: '',
    phone: '',
    mail: '',
    description: '',
    deadline: null,
    start: null,
    end: null,
    startTime: '',
    endTime: '',
    signup: '',
    speakers: '',
    website: '',
    printSummary: '',
    address: null,
    type: '',
    departments: [],
    languages: [],
    program: '',
    credits: [],
    premium: '',
    highlightOnline: false,
    highlightWeb: false,
    issue: ''
}

const testFormData = {
    title: 'TTTTT',
    company: 'What AG',
    room: '1A',
    venue: 'Host.com',
    contact: 'Mr. Contact',
    phone: '+15186486',
    mail: 'yo@me.com',
    description: 'ciruz boqeiruobeqv',
    deadline: new Date('2019-06-04'),
    start: new Date('2019-12-04'),
    end: new Date('2019-12-04'),
    startTime: '09:00',
    endTime: '16:00',
    signup: 'www.url.ch',
    speakers: 'Mr G.',
    website: 'www.what.com',
    printSummary: 'jkbcfgiaqvwu',
    address: {
        city: "Basel",
        country: "Switzerland",
        latitude: 47.5562851,
        longitude: 7.61275999999998,
        postalcode: "4052",
        street: "Farnsburgerstrasse",
        streetNr: "8"
    },
    type: 'Kongress',
    departments: ['Radiologie'],
    languages: ['Deutsch'],
    program: 'www.sup.com',
    credits: [
        {
            "confirmed": true,
            "count": "10",
            "association": "SGAIM",
            "area": "Allgemeine Innere Medizin",
            "status": "Bestätigt"
        }
    ],
    premium: 'print'
}

const defaultUploadData = {
    preview: null,
    desktop: null,
    mobile: null,
    printFile: null
}

const defaultCredit = {
    confirmed: false,
    count: '',
    association: '',
    area: ''
}

const languageOptions = ['Deutsch', 'Französisch', 'Italienisch', 'Englisch']
const typeOptions = ['Kongress', 'Seminar', 'Workshop', 'Online']


const issueOptions = issuesSMF.filter(e => new Date(e.deadline) > new Date().setHours(0,0,0,0)).map(e => e.value)

const premiumHints = {
    preview: {
        title: 'Vorschaubild',
        heading: 'Headline',
        text: 'Um die Qualität unserer Daten in diesem Kalender zu gewährleisten, ist für das Erstellen neuer Veranstaltungen eine kostenlose Registrierung notwendig.',
        images: [
            {
                title: 'Desktop',
                image: '/test.jpg',
                size: 'large'
            }
        ]
    },
    print: {
        title: 'Inserat',
        heading: 'Print',
        text: 'Um die Qualität unserer Daten in diesem Kalender zu gewährleisten, ist für das Erstellen neuer Veranstaltungen eine kostenlose Registrierung notwendig.',
        images: [
            {
                title: 'SMF – Ausgabe',
                image: '/test.jpg',
                size: 'large'
            }
        ]
    },
    content: {
        title: 'Beitragsbild',
        heading: 'Headline',
        text: 'Um die Qualität unserer Daten in diesem Kalender zu gewährleisten, ist für das Erstellen neuer Veranstaltungen eine kostenlose Registrierung notwendig.',
        images: [
            {
                title: 'Mobile',
                image: '/test.jpg',
                size: 'small'
            },
            {
                title: 'Desktop',
                image: '/test.jpg',
                size: 'large'
            }
        ]
    },
    highlightOnline: {
        title: 'Hervorgehoben',
        heading: 'Online',
        text: 'Um die Qualität unserer Daten in diesem Kalender zu gewährleisten, ist für das Erstellen neuer Veranstaltungen eine kostenlose Registrierung notwendig.',
        images: [
            {
                title: 'Mobile',
                image: '/test.jpg',
                size: 'small'
            },
            {
                title: 'Desktop',
                image: '/test.jpg',
                size: 'large'
            }
        ]
    },
    highlightPrint: {
        title: 'Hervorgehoben',
        heading: 'Print',
        text: 'Um die Qualität unserer Daten in diesem Kalender zu gewährleisten, ist für das Erstellen neuer Veranstaltungen eine kostenlose Registrierung notwendig.',
        images: [
            {
                title: 'Magazin',
                image: '/test.jpg',
                size: 'large'
            }
        ]
    }
}

const fileUploadMessage = {
    title: 'Datei hochgeladen',
    message: 'Ihre Datei wurde erfolgreich hochgeladen.'
}


const EventForm = (props) => {
    const [formData, setFormData] = useState(defaultFormData)
    const [missing, setMissing] = useState([])

    const [uploadData, setUploadData] = useState(defaultUploadData)
    const [premiumModalContent, setPremiumModalContent] = useState(null)

    const [isSidebarVisible, setIsSidebarVisible] = useClickOutside('sidebar')
    const [isProfileModalVisible, setIsProfileModalVisible] = useClickOutside('modal-wrapper')
    const [isPremiumModalVisible, setIsPremiumModalVisible] = useClickOutside('modal-wrapper')

    const [account, refresh] = useAccountInformation()

    const [createNotification, renderNotifications] = useNotifications()

    const augmentFormData = field => data => setFormData(state => ({...state, [field]: data}))

    const notifiyAssetUploaded = () => {
        createNotification(1, fileUploadMessage)
    }

    const editEvent = async id => {
        let event = await axios.get(`/api/events/${id}`)
        let formData = transformEvent(event.data)

        console.log('formdata', formData)
        setFormData(formData)
    }

    const transformEvent = event => {
        event.deadline = (new Date(event.deadline)).getTime()
        event.end = (new Date(event.end)).getTime()
        event.start = (new Date(event.start)).getTime()
        event.speakers = event.speakers.join('\n')
        event.address = event.address ? event.address : null

        return event
    }

    const validate = () => {
        const notEmpty = ['title', 'type', 'departments', 'languages', 'description', 'venue', 'mail', 'company']
        const notNull = ['start', 'end', 'address']

        console.log('check formdata', formData)
        const emptyValues = notEmpty.filter(f => !formData[f].length)
        const nullValues = notNull.filter(f => !formData[f])

        setMissing(emptyValues.concat(nullValues))
        console.log('missing', missing)

        return !notEmpty.some(f => !formData[f].length) && !notNull.some(f => !formData[f])
    }

    const formatAndPost = async (event, draft, save) => {
        await refresh()
        console.log('account??', account)

        event = formatEvent(event)
        // console.log('event about to send', event)
        // console.log(`${event.id ? 'put': 'post'} request,  event id: ${event.id} `)
        if (draft)
            event.draft = true

        let newEvent
        if (event.id)
            newEvent = await axios.put(`/api/admin/events/${event.id}`, {data: event})

        console.log('uploadData', uploadData)
        //iterate over object properties
        Object.keys(uploadData).map(type => {
            const asset = uploadData[type]
            const formData = new FormData()
            if (asset) {
                formData.append('file', asset.file)
                axios.post(`/api/events/${event.id}/assets/${type}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
            }
        })

        console.log('state', props.location.state)
        console.log('newEvent', newEvent.data)

        props.history.push(props.location.state && props.location.state.init ? props.location.state.init:'/review', {new: save ? newEvent.data : null, remove: newEvent.data.id})
    }


    const formatDate = date => {
        const d = new Date(date)
        const month = (d.getMonth() + 1).toString().padStart(2, '0')
        const day = d.getDate().toString().padStart(2, '0')
        const year = d.getFullYear()
        return [year, month, day].join('-')
    }

    const formatEvent = event => {
        event.deadline = event.deadline ? formatDate(event.deadline) : null
        event.signup = event.signup || null
        event.end = formatDate(event.end)
        event.start = formatDate(event.start)
        event.speakers = formatSpeakers(event.speakers)
        return event
    }

    const onRemove = type => {
        formData[type] && formData.id && axios.delete(`/api/events/${formData.id}/assets/${type}`) && setFormData({
            ...formData,
            [type]: null
        })
    }

    const formatSpeakers = speakers => speakers.split('\n')

    const save = async () => {
        await formatAndPost(formData, null, true)
        // const copy = deepCopy(formData)
        // props.history.push(props.location.state && props.location.state.init ? props.location.state.init : '/admin/review', {remove: copy.id, new: copy })
    }

    const saveAndAccept = async () => {
        let data = formData
        data.status = 'online'
        await formatAndPost(data, null, false)
        // const copy = deepCopy(formData)
        // props.history.push(props.location.state && props.location.state.init ? props.location.state.init : '/admin/review', {remove: copy.id, new: copy})
    }

    const deepCopy = object => JSON.parse(JSON.stringify(object))


    useEffect(() => {
        console.log(formData)
    }, [formData])

    useEffect(() => {
        console.log('props formmmm', props)
        console.log(formData)

        if (props.match.params.id) {
            editEvent(props.match.params.id)
        }
    }, [])

    useEffect(() => () => {
        Object.keys(uploadData).forEach(key => {
            if (uploadData[key]) {
                URL.revokeObjectURL(uploadData[key].preview)
            }
        })
    }, [])

    useEffect(() => {
        if (formData.start && !formData.end) {
            augmentFormData('end')(formData.start)
        }
    }, [formData.start])


    useEffect(() => {
        if (formData.premium === 'onlinePlus')
            setFormData({...formData, highlightOnline: true})
        else if (formData.premium === 'print')
            setFormData({...formData, highlightOnline: true, highlightPrint: true})
        else if (formData.premium === 'printPlus')
            setFormData({...formData, highlightOnline: true, highlightPrint: true})
        else if (formData.premium === '')
            setFormData({...formData, highlightOnline: false, highlightPrint: false})

    }, [formData.premium])

    return (
        <div className="site-content">
            <div className="side-bar">
                <button onClick={() => props.history.push(props.location.state && props.location.state.init ? props.location.state.init : '/admin/events')} className="back-link">
                    <span className="icon-arrow-left" />
                    Zur Übersicht
                </button>
                <div className="buttons-review">
                    <button data-button onClick={saveAndAccept}>Speichern und Veröffentlichen</button>
                    {formData.status === 'review' && (
                        <button data-button onClick={save}>Speichern</button>
                    )}
                </div>
            </div>

            <div className="form-content">
                <h2 className="h1">Event bearbeiten</h2>
                <div className="event-group">
                    <div className="wrap">
                        <h2>Allgemeine Informationen</h2>
                        <FloatingTextBox
                            value={formData.title}
                            placeholder="Titel*"
                            onChange={augmentFormData('title')}
                            error={missing.includes('title') ? 'Bitte ausfüllen' : null}
                        />
                        <div className="control-group">
                            <FloatingDatePicker
                                value={formData.start}
                                placeholder="Beginnt am*"
                                onChange={augmentFormData('start')}/>
                            <FloatingDatePicker
                                value={formData.end}
                                placeholder="Endet am*"
                                onChange={augmentFormData('end')}/>
                        </div>
                        {(formData.start && formData.end && formData.start === formData.end) && (
                            <div className="control-group">
                                <FloatingTimePicker
                                    value={formData.startTime}
                                    placeholder="Beginnt um*"
                                    onChange={augmentFormData('startTime')}/>
                                <FloatingTimePicker
                                    value={formData.endTime}
                                    placeholder="Endet um*"
                                    onChange={augmentFormData('endTime')}/>
                            </div>
                        )}
                        <label>Art der Veranstaltung</label>
                        <RadioGroup
                            selection={formData.type}
                            options={typeOptions}
                            onChange={augmentFormData('type')}/>
                        <FloatingMultiSelect
                            options={departments}
                            selection={formData.departments}
                            placeholder="Fachgebiete*"
                            onChange={augmentFormData('departments')}/>
                        <label>Sprachen</label>
                        <CheckboxGroup
                            selection={formData.languages}
                            options={languageOptions}
                            onChange={augmentFormData('languages')}/>
                    </div>
                </div>
                <div className="event-group">
                    <div className="wrap">
                        <h2>Kongress Informationen</h2>
                        <p>Ihr Basiseintrag wird kostenlos im EMH Online-Kalender und einmalig in unserer Zeitschrift
                            Siwss Medical Forum publiziert.</p>
                        <FloatingTextArea
                            value={formData.description}
                            placeholder="Beschreibung*"
                            onChange={augmentFormData('description')}
                            error={missing.includes('description') ? 'Bitte ausfüllen' : null}/>
                        <FloatingTextArea
                            value={formData.speakers}
                            placeholder="Speaker"
                            help="Bitte einen Speaker pro Zeile eingeben."
                            onChange={augmentFormData('speakers')}
                            error={missing.includes('speakers') ? 'Bitte ausfüllen' : null}/>
                        <div className="control-group">
                            <FloatingTextBox
                                value={formData.website}
                                placeholder="Webseite"
                                onChange={augmentFormData('website')}/>
                            <FloatingTextBox
                                value={formData.program}
                                placeholder="Link zum Programm"
                                onChange={augmentFormData('program')}/>
                        </div>
                        <FloatingTextArea
                            value={formData.printSummary}
                            placeholder="Kurzbeschreibung für gedrucktes Heft*"
                            help="Hinweis: Hier bitte nur Kurzbeschreibung zum Event. Titel, Datum, Adresse und Kontakt werden automatisch aus den Formulardaten regänzt. Zeilenumbrüche sind nicht erlaubt."
                            maxLength={250}
                            preventLineBreaks
                            onChange={augmentFormData('printSummary')}/>
                    </div>
                </div>
                <div className="event-group">
                    <div className="wrap">
                        <h2>Premium</h2>
                        <div className="top-option">
                            <Checkbox
                                selected={formData.premium === 'onlinePlus'}
                                label="Online Plus (+ CHF XX.—)"
                                readOnly={formData.status === 'online'}
                                onChange={() => setFormData(state => ({
                                    ...state,
                                    premium: state.premium === 'onlinePlus' ? '' : 'onlinePlus'
                                }))}/>
                            <div className="top-option-description">
                                <p>Sie können Ihren Event im EMH Online-Kalender hervorheben lassen und zusätzlich
                                    Bilder hochladen.</p>
                                <p><a href="https://emh.ch" target="_blank" rel="noopener noreferrer">Mehr erfahren</a>
                                </p>
                            </div>
                        </div>
                        {formData.premium === 'onlinePlus' && (
                            <>
                            <div className="uploads">
                                <div className="full-width">
                                    <div className="form-group-container">
                                        <Checkbox
                                            selected={formData.highlightOnline}
                                            label="Online hervorheben"
                                            onChange={() => setFormData(state => ({
                                                ...state,
                                                highlightOnline: !state.highlightOnline
                                            }))}/>
                                        <button data-button="flat"
                                                onClick={() => setPremiumModalContent(premiumHints.highlightOnline) || setIsPremiumModalVisible(true)}>
                                            (Anzeige-Beispiel)
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <OnlinePremiumSection
                                uploaded={formData}
                                uploadData={uploadData}
                                setUploadData={setUploadData}
                                setPremiumModalContent={setPremiumModalContent}
                                setIsPremiumModalVisible={setIsPremiumModalVisible}
                                notifiyAssetUploaded={notifiyAssetUploaded}
                            />
                            </>
                        )}
                        <div className="top-option">
                            <Checkbox
                                selected={formData.premium === 'print'}
                                label="Print (+ CHF XX.—)"
                                readOnly={formData.status === 'online'}
                                onChange={() => setFormData(state => ({
                                    ...state,
                                    premium: state.premium === 'print' ? '' : 'print'
                                }))}/>
                            <div className="top-option-description">
                                <p>Ihre Veranstaltung wird in der Printausgabe als Top-Event hervorgehoben.</p>
                                <p><a href="https://emh.ch" target="_blank" rel="noopener noreferrer">Mehr erfahren</a>
                                </p>
                            </div>
                        </div>
                        {formData.premium === 'print' && (
                            <>
                            <div className="uploads">
                                <div className="full-width">
                                    <div className="form-group-container">
                                        <Checkbox
                                            selected={formData.highlightOnline}
                                            label="Online hervorheben"
                                            readOnly={formData.status === 'online'}
                                            onChange={() => setFormData(state => ({
                                                ...state,
                                                highlightOnline: !state.highlightOnline
                                            }))}/>
                                        <button data-button="flat"
                                                onClick={() => setPremiumModalContent(premiumHints.highlightOnline) || setIsPremiumModalVisible(true)}>
                                            (Anzeige-Beispiel)
                                        </button>
                                    </div>
                                    <div className="form-group-container">
                                        <Checkbox
                                            selected={formData.highlightPrint}
                                            label="Print hervorheben"
                                            readOnly={formData.status === 'online'}
                                            onChange={() => setFormData(state => ({
                                                ...state,
                                                highlightPrint: !state.highlightPrint
                                            }))}/>
                                        <button data-button="flat"
                                                onClick={() => setPremiumModalContent(premiumHints.highlightPrint) || setIsPremiumModalVisible(true)}>
                                            (Anzeige-Beispiel)
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <OnlinePremiumSection
                                onRemove={onRemove}
                                uploaded={formData}
                                uploadData={uploadData}
                                setUploadData={setUploadData}
                                setPremiumModalContent={setPremiumModalContent}
                                setIsPremiumModalVisible={setIsPremiumModalVisible}
                                notifiyAssetUploaded={notifiyAssetUploaded}/>
                            </>
                        )}
                        <div className="top-option">
                            <Checkbox
                                selected={formData.premium === 'printPlus'}
                                label="Print Plus (+ CHF XX.—)"
                                readOnly={formData.status === 'online'}
                                onChange={() => setFormData(state => ({
                                    ...state,
                                    premium: state.premium === 'printPlus' ? '' : 'printPlus'
                                }))}/>
                            <div className="top-option-description">
                                <p>Ihre Veranstaltung wird in der Printausgabe zusätzlich als Top-Event hervorgehoben.</p>
                                <p><a href="https://emh.ch" target="_blank" rel="noopener noreferrer">Mehr erfahren</a>
                                </p>
                            </div>
                        </div>
                        {formData.premium === 'printPlus' && (
                            <>
                            <div className="uploads">
                                <div className="full-width">
                                    <div className="form-group-container">
                                        <Checkbox
                                            selected={formData.highlightOnline}
                                            label="Online hervorheben"
                                            onChange={() => setFormData(state => ({
                                                ...state,
                                                highlightOnline: !state.highlightOnline
                                            }))}/>
                                        <button data-button="flat"
                                                onClick={() => setPremiumModalContent(premiumHints.highlightOnline) || setIsPremiumModalVisible(true)}>
                                            (Anzeige-Beispiel)
                                        </button>
                                    </div>
                                    <div className="form-group-container">
                                        <Checkbox
                                            selected={formData.highlightPrint}
                                            label="Print hervorheben"
                                            onChange={() => setFormData(state => ({
                                                ...state,
                                                highlightPrint: !state.highlightPrint
                                            }))}/>
                                        <button data-button="flat"
                                                onClick={() => setPremiumModalContent(premiumHints.highlightPrint) || setIsPremiumModalVisible(true)}>
                                            (Anzeige-Beispiel)
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="uploads">
                                <div className="full-width">
                                    <div className="ausgabe">
                                        <FloatingSingleSelect
                                            option={issueOptions}
                                            selection={formData.issue}
                                            placeholder="Gewünschte Ausgabe*"
                                            onChange={augmentFormData('issue')}/>
                                    </div>
                                    <label>Druck-Datei für Werbe-Inserat im Heft</label>
                                    <FileUpload
                                        onRemove={onRemove}
                                        file={uploadData.printFile}
                                        onSelect={file => {setUploadData(state => ({
                                            ...state,
                                            printFile: {data: file}
                                        })) && notifiyAssetUploaded()}}
                                        onClear={() => {
                                            setUploadData(state => ({...state, printFile: null}))
                                        }}/>
                                    <div className="help-text">
                                        <p>
                                            Hinweis: Dies ist ein Hinweis.
                                            <br/>
                                            <button data-button="flat"
                                                    onClick={() => setPremiumModalContent(premiumHints.highlightPrint) || setIsPremiumModalVisible(true)}>
                                                Anzeige-Beispiel
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <OnlinePremiumSection
                                onRemove={onRemove}
                                uploaded={formData}
                                uploadData={uploadData}
                                setUploadData={setUploadData}
                                setPremiumModalContent={setPremiumModalContent}
                                setIsPremiumModalVisible={setIsPremiumModalVisible}
                                notifiyAssetUploaded={notifiyAssetUploaded}/>
                            </>
                        )}
                    </div>
                </div>
                <div className="event-group">
                    <div className="wrap">
                        <h2>Veranstaltungsort</h2>
                        <div className="control-group">
                            <FloatingTextBox
                                value={formData.venue}
                                placeholder="Firma/Unternehmen*"
                                onChange={augmentFormData('venue')}
                                error={missing.includes('venue') ? 'Bitte ausfüllen' : null}/>
                            <FloatingTextBox
                                value={formData.room}
                                placeholder="Raum/Gebäude"
                                onChange={augmentFormData('room')}
                                error={missing.includes(['room']) ? 'Bitte ausfüllen' : null}/>
                        </div>
                        <FloatingAddressBox
                            selection={formData.address}
                            placeholder="Adresse*"
                            onChange={augmentFormData('address')}
                            error={missing.includes('address') ? 'Bitte ausfüllen' : null}/>
                    </div>
                </div>
                <div className="event-group">
                    <div className="wrap">
                        <h2>Veranstalter</h2>
                        <div className="control-group">
                            <FloatingTextBox
                                value={formData.company}
                                placeholder="Veranstalter*"
                                onChange={augmentFormData('company')}
                                error={missing.includes('company') ? 'Bitte ausfüllen' : null}/>
                            <FloatingTextBox
                                value={formData.contact}
                                placeholder="Kontaktperson"
                                onChange={augmentFormData('contact')}
                                error={missing.includes('contact') ? 'Bitte ausfüllen' : null}/>
                        </div>
                        <div className="control-group">
                            <FloatingTextBox
                                value={formData.phone}
                                placeholder="Telefon"
                                onChange={augmentFormData('phone')}/>
                            <FloatingTextBox
                                value={formData.mail}
                                placeholder="E-Mail*"
                                onChange={augmentFormData('mail')}
                                error={missing.includes('mail') ? 'Bitte ausfüllen' : null}/>
                        </div>
                    </div>
                </div>
                <div className="event-group">
                    <div className="wrap">
                        <h2>Anmeldung</h2>
                        <div className="control-group">
                            <FloatingDatePicker
                                value={formData.deadline}
                                placeholder="Anmeldeschluss"
                                onChange={augmentFormData('deadline')}/>
                            <FloatingTextBox
                                value={formData.signup}
                                placeholder="Link zur Anmeldung"
                                onChange={augmentFormData('signup')}/>
                        </div>
                    </div>
                </div>
                <div className="event-group">
                    <div className="wrap">
                        <h2>Credits</h2>
                        {formData.credits.map((credit, index) => (
                            <Credit
                                key={index}
                                value={formData.credits[index]}
                                onChange={value => setFormData({
                                    ...formData,
                                    credits: formData.credits.map((c, i) => i === index ? value : c)
                                })}
                                onDelete={() => setFormData({
                                    ...formData,
                                    credits: formData.credits.map((c, i) => i === index ? null : c).filter(c => !!c)
                                })}
                                error={missing.includes('credits') ? 'Bitte ausfüllen' : null}
                            />
                        ))}
                        <button
                            data-button
                            onClick={() => setFormData({
                                ...formData,
                                credits: [...formData.credits, {...defaultCredit}]
                            })}>
                            Credits hinzufügen
                        </button>
                    </div>
                </div>
            </div>

            {isProfileModalVisible && (
                <ProfileModal
                    onClose={() => setIsProfileModalVisible(false)}
                    onConfirm={() => setIsProfileModalVisible(false) || formatAndPost(formData)}/>
            )}
            {isPremiumModalVisible && (
                <PremiumModal
                    content={premiumModalContent}
                    onClose={() => setIsPremiumModalVisible(false)}/>
            )}
        </div>
    )
}

const OnlinePremiumSection = ({uploaded, uploadData, setUploadData, setPremiumModalContent, setIsPremiumModalVisible, onRemove, notifiyAssetUploaded}) => {
    return (
        <div className="uploads">
            <div>
                <label>Vorschau Bild</label>
                <ImageUpload
                    file={uploadData.preview ? uploadData.preview : uploaded.preview ? {preview: uploaded.preview} : uploadData.preview}
                    onSelect={file => setUploadData(state => ({
                        ...state,
                        preview: {file: file, preview: URL.createObjectURL(file)}
                    })) && notifiyAssetUploaded()
                    }
                    onClear={() => {
                        uploadData.preview && URL.revokeObjectURL(uploadData.preview.preview)
                        onRemove('preview')
                        setUploadData(state => ({...state, preview: null}))
                    }}/>
                <div className="help-text">
                    <p>
                        Hinweis: Dies ist ein Hinweis.
                        <br/>
                        <button data-button="flat"
                                onClick={() => setPremiumModalContent(premiumHints.preview) || setIsPremiumModalVisible(true)}>
                            Anzeige-Beispiel
                        </button>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default withRouter(EventForm)
