import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import Dropdown from './Dropdown'

const MultiSelect = props => {
    const [ value, setValue ] = useState('')
    const [ options, setOptions ] = useState(props.options)
    const [ visibleOptions, setVisibleOptions ] = useState([])

    const [ hasFocus, setHasFocus ] = useState(false)
    const [ ignoreBlur, setIgnoreBlur ] = useState(false)

    const valueInput = useRef(null)

    const handleValueChange = e => {
        setValue(e.target.value)
    }

    const handleSelection = item => {
        setValue('')
        setHasFocus(false)

        console.log('selection!')

        if (props.selection.indexOf(item) === -1) {
            props.onChange([ ...props.selection, item ])
        } else {
            props.onChange(props.selection.filter(i => i !== item))
        }

        setIgnoreBlur(false)
        valueInput.current.blur()
    }

    const handleFocus = () => {
        setHasFocus(true)
    }

    const handleBlur = () => {
        if (!ignoreBlur) {
            setHasFocus(false)
            setValue('')
        }
    }

    const handleKeyDown = event => {
        if (event.key === 'Tab') {
            setIgnoreBlur(false)
        }
    }

    const fetchOptions = async () => {
        if (!props.source) return

        const { data } = await axios.get(props.source)

        setOptions(data)
        setVisibleOptions(data)
    }

    useEffect(() => {
        setVisibleOptions(
            options.filter( c =>
                c.toLowerCase().startsWith(value.toLowerCase())
                && props.selection.indexOf(c) === -1)
        )
    }, [ value, props.selection ])

    useEffect(() => {
        fetchOptions()
    }, [])

    useEffect(() => setIgnoreBlur(false), [ visibleOptions ])


    return (
        <>
            <div className={`form-group input-group ${hasFocus ? 'has-focus' : ''}`}>
                {props.prefix && <span className={`icon-${props.prefix}`} />}
                <input
                    type="text"
                    ref={valueInput}
                    value={value}
                    placeholder={props.placeholder}
                    onChange={handleValueChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown} />
                <span className="icon-arrow-down" onClick={() => valueInput.current.focus()}/>
                {props.suffix && <span className={`icon-${props.suffix}`} />}
                {!!(hasFocus && visibleOptions.length) && (
                    <Dropdown
                        items={visibleOptions}
                        onSelect={item => handleSelection(item)}
                        onMouseEnter={() => setIgnoreBlur(true)}
                        onMouseLeave={() => setIgnoreBlur(false)}
                    />
                )}
            </div>
            {!!props.selection.length && (
                <ul className="selection">
                    {props.selection.map(s => (
                        <li
                            key={s}
                            onClick={() => handleSelection(s)}>
                            {s}
                            <span className="icon-times-circle" />
                        </li>
                    ))}
                </ul>
            )}
        </>
    )
}

MultiSelect.defaultProps = {
    options: [],
    selection: [],
    onChange: () => {}
}

export default MultiSelect