import React, {useEffect, useState} from 'react'
import FloatingTextBox from "./FloatingTextBox";

const criteria = {
    length: false,
    letters: false,
}

const NewPasswordBox = props => {
    const [validationError, setValidationError] = useState('')
    const [value, setValue] = useState('')
    const [valid, setValid] = useState(false)
    const [validationColor, setValidationColor] = useState('')

    const errorText = {
        length: `Das Passwort muss mindestens 10 Zeichen enthalten (aktuell: ${value.length})`,
        letters: 'Das Passwort enthält keine Gross- und Kleinbuchstaben',
        number: 'Das Passwort enthält kein nummerisches Zeichen',
        specialChars: ' Das Passwort enhält kein Sonderzeichen aus: .,-_!?'
    }


    useEffect(() => {
        if (value.length)
            validate(value)
    },[value])

    const validate = password => {
        setValue(password)
        const lengthCheck = password.length >= 10
        const lettersCheck = /[a-z]/.test(password) && /[A-Z]/.test(password)
        const numberCheck = /\d/.test(password)
        const specialCharCheck = /[.,\-_!?]/.test(password)

        const ok = lengthCheck &&
                    lettersCheck &&
                    numberCheck &&
                    specialCharCheck
        setValid(ok)

        // setValidationColor(!lengthCheck || ok ? '#26b8ce' //blue
        //     : '#ea8d3d') //orange

        console.log('specialCharCheck', specialCharCheck)

        let validationText;
        if (!lengthCheck) {
            validationText = errorText.length
        }
        else if (!lettersCheck)
            validationText = errorText.letters
        else if (!numberCheck)
            validationText = errorText.number
        else if (!specialCharCheck)
            validationText = errorText.specialChars

        setValidationError(ok ? 'Das Passwort erfüllt alle Kriterien ✓' : validationText);
        props.valid(ok)
    }

    return (
        <FloatingTextBox
            value={props.value}
            placeholder="Neues Passwort"
            mask
            onChange={(value) => setValue(value)  || props.onChange(value)}
            // className={validationError ? 'validation-error': ''}
            error={validationError}
            errorColor={validationColor}
        />
    )
}
export default NewPasswordBox;