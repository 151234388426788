import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import Dropdown from './Dropdown'

const FloatingSingleSelect = props => {
    const [ value, setValue ] = useState('')
    const [ options, setOptions ] = useState(props.options)
    const [ visibleOptions, setVisibleOptions ] = useState(props.options)

    const [ hasFocus, setHasFocus ] = useState(false)
    const [ ignoreBlur, setIgnoreBlur ] = useState(false)

    const valueInput = useRef(null)

    const handleValueChange = e => {
        setValue(e.target.value)
    }

    const handleSelection = item => {
        setValue(item)
        setIgnoreBlur(false)
        setHasFocus(false)
        props.onChange(item)
    }

    const handleFocus = () => {
        setHasFocus(true)
    }

    const handleBlur = () => {
        if (ignoreBlur) {
            valueInput.current.focus()
        } else {
            if (!value) {
                props.onChange('')
            }

            setHasFocus(false)
            setValue(!value ? '' : props.selection || '')
        }
    }

    const handleKeyDown = event => {
        if (event.key === 'Tab') {
            setIgnoreBlur(false)
        }
    }

    const handleClear = () => {
        props.onChange('')
        setValue('')
        valueInput.current.focus()
        setIgnoreBlur(false)
    }

    const fetchOptions = async () => {
        const { data } = await axios.get(props.source)

        setOptions(data)
        setVisibleOptions(data)

        if (props.readOnly) {
            props.onChange(data[0])
        }
    }

    useEffect(() => {
        if (!props.readOnly) {
            setVisibleOptions(options.filter(c => c.toLowerCase().startsWith(value.toLowerCase())))
        }
    }, [ value ])

    useEffect(() => {
        setValue(props.selection)
    }, [ props.selection ])

    useEffect(() => {
        if (props.source) fetchOptions()

        // if (props.readOnly) {
        //     props.onChange(props.options[0])
        // }
    }, [])

    return (
        <div id={props.id}>
            <div className={`floating-group select-group ${value || props.selection || hasFocus && !props.fixed ? 'has-value' : ''} ${props.className} ${props.fixed ? 'fixed':''} ${props.error ? 'validation-error' : ''} `} onClick={() => !hasFocus && valueInput.current.focus()}>
                <input
                    type="text"
                    ref={valueInput}
                    value={props.readOnly ? (props.fixedValue ? props.fixedValue : props.selection) : value}
                    onChange={handleValueChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    readOnly={props.readOnly} />
                <div className={`placeholder ${props.fixed ? 'fixed':''}`}>
                    {props.placeholder}
                </div>
                {!!value && !props.readOnly && (
                    <span
                        className="icon-times-circle"
                        onClick={handleClear}
                        onMouseEnter={() => setIgnoreBlur(true)}
                        onMouseLeave={() => setIgnoreBlur(false)} />
                )}
                {!props.fixedValue && (
                    <span
                        className="icon-arrow-down"
                        onClick={() => valueInput.current.focus()}
                        onMouseEnter={() => setIgnoreBlur(true)}
                        onMouseLeave={() => setIgnoreBlur(false)} />
                )}
                {!!(hasFocus && visibleOptions.length) && (
                    <Dropdown
                    items={visibleOptions}
                    onMouseEnter={() => setIgnoreBlur(true)}
                    onMouseLeave={() => setIgnoreBlur(false)}
                    onSelect={handleSelection} />
                    )}
            </div>
            <div className="validation-text">
                {props.error}
            </div>
        </div>
    )
}

FloatingSingleSelect.defaultProps = {
    options: [],
    readOnly: false,
    onChange: () => {}
}

export default FloatingSingleSelect
