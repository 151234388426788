import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom'
import DataTable from 'react-data-table-component';
import axios from 'axios'

import * as moment from 'moment';

import {premiumLabel, formatEvents} from '../../components/Admin/EventList'

import {SelectField, Grid, Cell, Chip, Button, FontIcon, TextField} from 'react-md';

import {DateRangePicker, SingleDatePicker, DayPickerRangeController} from 'react-dates';

import {Card, CardTitle} from 'reactstrap';

import removeFromList from "../../pages/Manager";
import useFilter, {deserializeDates} from "../../hooks/Filter";

import {premiumLabelMappings} from '../../constants/constants'


const Review = ({location, history}) => {
    const [data, setData] = useState([]);
    const [visibleData, setVisibleData] = useState([]);

    const [loading, setLoading] = useState(true)

    const [filter, setFilter] = useFilter('admin_reviewlist_filter', defaultFilterData)

    useEffect(() => {
       loadEvents()
    }, [])

    useEffect(() => {
        if (filter) {
            if (!data.length)
                loadEvents()
            else
                filterEvents(data)
        }

    }, [filter])

    const loadEvents = async () => {
        filterEvents(await fetchEvents())
    }

    const fetchEvents = async remove => {
        const {data} = await axios.get('/api/admin/events/review')
        if (remove || location && location.state && location.state.remove) {
            if (!remove && location.state && location.state.remove)
                remove = location.state.remove

            if (remove)
                data.forEach((e, i) => {
                    if (e.id === remove)
                        data.splice(i, 1)
                })
        }

        if (location.state && location.state.new) {
            data.push(location.state.new)
        }

        data.sort((a, b) => {
            return new Date(a.creationDate) - new Date(b.creationDate)
        })

        setLoading(false)
        const eventsFormatted = formatEvents(data)
        console.log('data', eventsFormatted)
        setData(eventsFormatted)
        return eventsFormatted
    }

    const filterEvents = (events) => {
        console.log('filtering', filter)

        let visibleData = events.filter(e => {
            let criteria = {}

            criteria.premium = filter.premium ? e.premium === filter.premium : true
            criteria.type = filter.type ? e.type === filter.type : true
            criteria.user = filter.client ? e.user.email === filter.client : true
            criteria.query = filter.query ? e.title.toLowerCase().search(filter.query.toLowerCase()) > -1 || e.description.toLowerCase().search(filter.query.toLowerCase()) > -1 : true

            const startDate = e.start ? new Date(e.start) : null
            if (startDate) startDate.setHours(0, 0, 0, 0)

            const endDate = e.end ? new Date(e.end) : null
            if (endDate) endDate.setHours(0, 0, 0, 0)

            const filterFrom = filter.startDate && typeof filter.startDate === 'number' ? new Date(filter.startDate) : null
            const filterTo = filter.endDate && typeof filter.endDate === 'number' ? new Date(filter.endDate) : null
            filterFrom && filterFrom.setHours(0, 0, 0, 0)
            filterTo && filterTo.setHours(0, 0, 0, 0);

            criteria.date = filterFrom ?
                endDate >= filterFrom
                    && (filterTo ? startDate <= filterTo : true)
                : true;

            criteria.department = filter.department && !filter.department.toLowerCase().includes('alle fachrichtungen') ? e.departments.includes(filter.department) : true

            //logical AND of all criteria
            return Object.values(criteria).reduce((acc, curr) => {
                return  acc && curr
            }, true);
        })
        console.log('visibleData', visibleData)
        setVisibleData(visibleData)
    }

    const viewEvent = (event) => {
        history.push(`/admin/review/event/${event.webId}`, {event: event, init: '/admin/review'})
    }

    const editEvent = (event) => {
        history.push(`/admin/event/${event.webId}`, {event: event, init: '/admin/review'})
    }

    const premiumInstances = [...new Set(data.map(({premium}) => premium))]
    const premiumOptions = premiumInstances.map((e) => ({value: e, label: premiumLabelMappings[e]}))
    const allDepartments = data.reduce((results, {departments}) => results.concat(departments), [])
    const departmentOptions = [...new Set(allDepartments)]
    const typeOptions = [...new Set(data.map(({type}) => type))]
    const clientOptions = [...new Set(data.map(({user}) => user.email))]

    const deleteEvent = async (event) => {
        await axios.delete(`/api/events/${event.id}`)
        fetchEvents(event.id)
    }

    const columns = [
        {
            name: 'Angelegt',
            selector: 'creationDate',
            format: row => {
                const date = row.creationDate.substring(0, row.creationDate.indexOf(' '))
                const time = row.creationDate.substring(row.creationDate.indexOf(' '))
                const timeParts = time.split(':')
                const dateParts = date.split('-');
                return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]} - ${timeParts[0]}:${timeParts[1]}`
            },
            sortable: true,
            width: '140px'
        },
        {
            name: 'Premium',
            selector: 'premium',
            sortable: true,
            width: '120px',
            cell: row => (row.premium !== 'free' && (
                    <div className="colorbox" style={{backgroundColor: premiumColor(row.premium)}}>{premiumLabel(row.premium)}</div>
                )
            )
        },
        {
            name: 'Typ',
            selector: 'type',
            sortable: true,
            width: '100px'

        },
        {
            name: 'Titel',
            selector: 'title',
            sortable: true,
            width: '240px'

        },
        {
            name: 'Von',
            selector: 'start',
            format: row => {
                const dateParts = row.start.split('-');
                return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`
            },
            sortable: true,
            width: '110px'

        },
        {
            name: 'Bis',
            selector: 'end',
            format: row => {
                const dateParts = row.end.split('-');
                return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`
            },
            sortable: true,
            width: '110px'

        },
        {
            name: 'Sprache',
            selector: 'languagesJoined',
            sortable: true,
            width: '80px',

        },
        {
            name: 'Fachgebiet',
            selector: 'departmentsJoined',
            sortable: true,
            width: '100px'
        },
        {
            name: 'Credits',
            selector: 'creditsCount',
            sortable: true,
            width: '50px'
        },
        {
            allowOverflow: true,
            cell: row => (
                <>
                    <Button icon onClick={() => viewEvent(row)}>remove_red_eye</Button>
                    <Button icon onClick={() => editEvent(row)}>edit</Button>
                    <Button icon onClick={() => deleteEvent(row)}>delete</Button>
                </>
            ),
            width: '140px'
        },
        {
            name: 'PDF',
            allowOverflow: true,
            cell: row => (
                <>
                    {row.premium === 'printPlus' && (
                        <Button icon href={row.assets.printFile} target="_blank" rel="noopener noreferrer">save_alt</Button>)}
                </>
            ),
            compact: true,
            width: '60px'
        },
        {
            name: 'Ausgabe SMF',
            selector: 'issue',
            sortable: true
        }
    ];

    return (
        <div className="site-content review">
            <Card body>
                {!loading && (data.length ?
                    <CardTitle>{`${data.length} Veranstaltungen zur Überprüfung`}</CardTitle> : '')}

                <DataTable
                    columns={columns}
                    data={visibleData}
                    defaultSortField="creationDate"
                    progressPending={loading}
                    progressComponent={<div>Veranstaltungen werden geladen...</div>}
                    noDataComponent={<div>Keine Veranstaltungen gefunden</div>}
                    subHeader
                    subHeaderComponent={
                        data.length ? (
                            <Filters onChange={criteria => setFilter({...filter, ...criteria})}
                                     onClear={() => setFilter(defaultFilterData)}
                                     premiumOptions={premiumOptions}
                                     typeOptions={typeOptions}
                                     clientOptions={clientOptions}
                                     departmentOptions={departmentOptions}
                                     filterState={filter}
                            />
                        ) : ''
                    }
                    overflowY
                />
            </Card>
        </div>
    )
}
export default withRouter(Review);


const premiumColor = (premium => {
    switch (premium) {
        case 'onlinePlus':
            return '#C8E6C9'
        case 'print':
            return '#B3E5FC'
        case 'printPlus':
            return '#E1BEE7'
    }
})


const Filters = props => {
    const [focusedInput, setFocusedInput] = useState(null)

    const augmentFilterData = field => data => {
        props.onChange({[field]: data})
    }

    const setDates = (start, end) => {
        props.onChange({startDate: start, endDate: end})
    }

    return (
        <div className="filter-bar">
            {!Object.values(props.filterState).find(e => e && e !== '' ) ? (
                <div className="filter filters-label">Filter</div>
            ) : (
                <div className="filter button-clear">
                    <div onClick={() => props.onClear()}>Filter löschen</div>
                </div>
            )}
            <div className="filter filter-review-premium">
                <SelectField
                    id="select-premium"
                    // label="Premium"
                    placeholder="Premium"
                    value={props.filterState.premium}
                    // className="md-cell"
                    // position={SelectField.Positions.BELOW}
                    menuItems={props.premiumOptions}
                    onChange={augmentFilterData('premium')}
                    inputClassName={props.filterState.premium ? 'filter-active' : ''}
                />
            </div>
            <div className="filter filter-review-type">
                <SelectField
                    id="select-type"
                    // label="Filter"
                    placeholder="Typ"
                    // className="md-cell"
                    // position={SelectField.Positions.BELOW}
                    value={props.filterState.type}
                    menuItems={props.typeOptions}
                    onChange={augmentFilterData('type')}
                    inputClassName={props.filterState.type ? 'filter-active' : ''}
                />
            </div>
            <div className="filter filter-review-query">
                <TextField
                    id="placeholder-only-title"
                    placeholder="Textsuche"
                    leftIcon={<FontIcon>search</FontIcon>}
                    // className="md-cell md-cell--bottom"
                    onChange={augmentFilterData('query')}
                    value={props.filterState.query}
                    inputClassName={props.filterState.query ? 'filter-active' : ''}
                />
            </div>
            <div className={`filter filter-review-date ${props.filterState.startDate ? 'filter-active-start' : ''} ${props.filterState.endDate ? 'filter-active-end' : ''}`}>
                <DateRangePicker
                    small
                    noBorder
                    hideKeyboardShortcutsPanel
                    showClearDates={false}
                    renderDayContents={null}
                    focusedInput={focusedInput}
                    startDate={props.filterState.startDate ? moment(props.filterState.startDate) : null}
                    startDateId="id-startdate"
                    startDatePlaceholderText="Von"
                    endDatePlaceholderText="Bis"
                    endDate={props.filterState.endDate ? moment(props.filterState.endDate) : null}
                    endDateId="id-enddate"
                    onDatesChange={({startDate, endDate}) => setDates(startDate, endDate)}
                    onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                    renderCalendarInfo={() => (<DatePickerPresets onChange={(start, end) => setDates(start, end)}></DatePickerPresets>)}
                    isOutsideRange={day => false}
                    displayFormat="DD.MM.YYYY"
                />
            </div>
            <div className="filter filter-review-department">
                <SelectField
                    id="select-fachgebiet"
                    placeholder="Fachgebiet"
                    menuItems={props.departmentOptions}
                    value={props.filterState.department}
                    onChange={augmentFilterData('department')}
                    inputClassName={props.filterState.department ? 'filter-active' : ''}
                    position={SelectField.Positions.TOP_RIGHT}
                />
            </div>

            {/*<div className="filter">*/}
                {/*<SelectField*/}
                    {/*id="select-client"*/}
                    {/*// label="Kunde"*/}
                    {/*placeholder="Kunde"*/}
                    {/*// className="md-cell"*/}
                    {/*// position={SelectField.Positions.BELOW}*/}
                    {/*menuItems={props.clientOptions}*/}
                    {/*onChange={augmentFilterData('client')}*/}
                {/*/>*/}
            {/*</div>*/}
        </div>
    )
}

const defaultFilterData = {
    status: '',
    premium: '',
    type: '',
    query: '',
    startDate: null,
    endDate: null,
    client: '',
    department: ''
}

const DatePickerPresets = (props) => {

    const handleLastWeek = () => {
        backXDays(7)
    }

    const handleLastMonth = () => {
        backXDays(30)
    }

    const handleLastYear = () => {
        backXDays(365)
    }

    const backXDays = (d) => {
        const now = new Date()
        const pastDate = now.getDate()-d
        const past = new Date().setDate(pastDate)
        props.onChange(past, now)
    }
    return (
        <div className="datepicker-presets">
            <Button flat onClick={handleLastWeek}>Letzte Woche</Button>
            <Button flat onClick={handleLastMonth}>Letzter Monat</Button>
            <Button flat onClick={handleLastYear}>Letztes Jahr</Button>
        </div>
    )
}
export {DatePickerPresets};

