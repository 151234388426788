import React, { useState, useEffect } from 'react'

const months = [ 'Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez' ]
const fullMonths = [ 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember' ]

const FloatingDatePicker = props => {
    const [ hasFocus, setHasFocus ] = useState(false)

    const handleSelection = date => {
        props.onChange(date.getTime())
        setHasFocus(false)
    }


    useEffect(() => {
        if (hasFocus) {
            const clickHandler = e => {
                let target = e.target

                while (target && target.classList && !target.classList.contains('dropdown')) {
                    target = target.parentNode
                }

                if (target === document) {
                    setHasFocus(false)
                }
            }

            document.addEventListener('click', clickHandler)

            return () => {
                document.removeEventListener('click', clickHandler)
            }
        }
    }, [ hasFocus ])

    const date = props.value ? new Date(props.value) : null

    return (
        <div id={props.id}>
            <div className={`floating-group ${props.value ? 'has-value' : ''} ${props.error ? 'validation-error' : ''}`} onClick={() => !hasFocus && setHasFocus(true)}>
                <div className="input">
                    {date ? `${date.getDate()}. ${fullMonths[date.getMonth()]} ${date.getFullYear()}` : null}
                </div>
                <span className="icon-calendar" />
                <div className="placeholder">
                    {props.placeholder}
                </div>
                {hasFocus && (
                    <DateDropdown
                        onSelect={handleSelection}
                        onClose={() => setHasFocus(false)}
                        onClear={() => props.onChange(null) || setHasFocus(false)}
                    />
                )}
            </div>
            <div className="validation-text">
                {props.error}
            </div>
        </div>

    )
}

FloatingDatePicker.defaultProps = {
    onChange: () => {}
}

const currentDate = new Date()
currentDate.setDate(1)

const today = new Date()
today.setHours(0, 0, 0, 0)

const sameMonth = date => currentDate.getMonth() === date.getMonth() && currentDate.getFullYear() === date.getFullYear()

const DateDropdown = props => {
    const [ date, setDate ] = useState(currentDate)
    const [ days, setDays ] = useState([])

    const moveMonth = direction => {
        const d = new Date(date.getTime())

        if (direction === 'inc') {
            d.setMonth(d.getMonth() + 1)
        } else if (direction === 'dec') {
            d.setMonth(d.getMonth() - 1)
        }

        setDate(d)
    }

    const getDays = () => {
        let d = new Date(date.getTime())
        let days = []

        for (let i = (date.getDay() + 6) % 7; i > 0; i -= 1) {
            days = [ ...days, new Date(date.getTime() - 86400000 * i)]
        }

        while (d.getMonth() === date.getMonth()) {
            days = [ ...days, d]
            d = new Date(d.getTime() + 86400000)
        }

        for (let i = 0; i < (8 - d.getDay()) % 7; i += 1) {
            days = [ ...days, new Date(d.getTime() + 86400000 * i)]
        }

        setDays(days)
    }

    const handleSelect = d => {
        if (d.getMonth() < date.getMonth() || d.getMonth() > date.getMonth()) return
        props.onSelect(d)
    }

    const reset = () => {
        const d = new Date()
        d.setDate(1)
        setDate(d)
        props.onClose()
    }

    useEffect(() => {
        getDays()
    }, [ date ])

    return (
        <div className="dropdown day-picker">
            <div className="controls">
                <button onClick={() => moveMonth('dec')} disabled={sameMonth(date)}>
                    <span className="icon-chevron-left" />
                </button>
                <span className="current-year">{`${months[date.getMonth()]} ${date.getFullYear().toString().substr(2)}`}</span>
                <button onClick={() => moveMonth('inc')}>
                    <span className="icon-chevron-right" />
                </button>
            </div>
            <ul className="day-names">
                <li>Mo</li>
                <li>Di</li>
                <li>Mi</li>
                <li>Do</li>
                <li>Fr</li>
                <li>Sa</li>
                <li>So</li>
            </ul>
            <ul className="days">
                {days.map(d => (
                    <li
                        key={`${d.getDate()}/${d.getMonth()}`}
                        className={d < today || d.getMonth() < date.getMonth() || d.getMonth() > date.getMonth() ? 'is-other' : ''}
                        onClick={() => handleSelect(d)}>
                        {d.getMonth() < date.getMonth() || d.getMonth() > date.getMonth() ? '' : d.getDate()}
                    </li>
                ))}
            </ul>
            <div style={{display: 'flex'}}>
                <button className="close" onClick={reset}>Abbrechen</button>
                <button className="close" onClick={props.onClear}>Löschen</button>
            </div>
        </div>
    )
}

DateDropdown.defaultProps = {
    onClose: () => {},
    onSelect: () => {}
}

export default FloatingDatePicker
