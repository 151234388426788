import React from 'react'
import { render } from 'react-dom'

import './styles/style.scss'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import App from './App'

//disable all debug output
console.log = function() {}

render(
    <App/>,
	document.getElementById('root')
)
