import React from 'react'

const TextBox = props => {
    const handleValueChange = e => {
        props.onChange(e.target.value)
    }

    const handleKeyDown = event => {
        if (event.key === "Enter") {
            props.onSubmit()
        }
    }

    return (
        <div className="form-group text-group">
            {props.prefix && <span className={`icon-${props.prefix}`} onClick={() => props.onSubmit && props.onSubmit()} />}
            <input
                type="text"
                value={props.value}
                placeholder={props.placeholder}
                onChange={handleValueChange}
                onKeyDown={handleKeyDown} />
            {props.suffix && <span className={`icon-${props.suffix}`} onClick={() => props.onSubmit && props.onSubmit()} />}
        </div>
    )
}

TextBox.defaultProps = {
    onChange: () => {},
    onSubmit: () => {}
}

export default TextBox
