import React, {useState, useEffect} from 'react'
import {Route, Link, NavLink, withRouter} from 'react-router-dom'
import EventList from '../../components/Admin/EventList'
import AdminLoginModal from "../../modals/AdminLoginModal";
import useAdminAccountInformation from '../../hooks/AdminAccountInformation'

const Review = ({history}) => {

    return (
        <>
            <EventList/>
        </>
    )
}
export default withRouter(Review);