import {useState, useEffect} from 'react'

const useHiddenNotice = () => {

    const [ hidden, setHidden ] = useState(
        document.cookie.includes('notice=hidden') || false
    )

     useEffect(() => {
         if (hidden){
             document.cookie = 'notice=hidden;max-age=315360000;path=/'
         }
     }, [hidden])

    return [ hidden, setHidden ]
}
export default useHiddenNotice