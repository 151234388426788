import React, { useState, useEffect } from 'react'
import {Link, withRouter} from 'react-router-dom'
import useClickOutside from '../hooks/ClickOutside'
import {monthNames, notifications} from '../constants/constants'
import logo from '../images/logo.svg'
import axios from 'axios'
import Footer from '../components/Footer'
import EventDetail from '../components/EventDetail'
import LoginModal from '../modals/LoginModal'
import RegistrationModal from '../modals/RegistrationModal'
import RecoverModal from '../modals/RecoverModal'
import useAccountInformation from '../hooks/AccountInformation'
import { associations, oldAssociations } from '../constants/constants'
import { Helmet } from "react-helmet";
import useGoogleAnalytics, {userActions} from "../hooks/GoogleAnalytics";
import useNotifications from "../hooks/Notifications";

const isDeadlineReached = (deadline) => {
    const date = new Date()
    date.setHours(0, 0, 0, 0)
    return (typeof deadline === 'object' ? date > deadline : true)
}

const formatDate = date => {
    return `${date.getDate().toString().padStart(2, '0')}. ${monthNames[date.getMonth()]} ${date.getFullYear()}`
}

const prefixLink = link => link.startsWith('http') ? link : `http://${link}`

const Detail = (props) => {
    const [ event, setEvent ] = useState(null)
    const [ creditsVisible, setCreditsVisible ] = useState(false)
    const [ isNavVisible, setIsNavVisible ] = useClickOutside('site-nav')

    const [ account ] = useAccountInformation()

    const [ isLoginVisible, setIsLoginVisible ] = useClickOutside('modal-wrapper')
    const [ isRegistrationVisible, setIsRegistrationVisible ] = useClickOutside('modal-wrapper')
    const [ isRecoverVisible, setIsRecoverVisible ] = useClickOutside('modal-wrapper')

    const [emitGAEvent] = useGoogleAnalytics()

    const [createNotification, renderNotifications, renderIfDirty] = useNotifications()

    const allAssociations = {...associations, ...oldAssociations}

    const handleEnterEvent = () => {
        emitGAEvent(userActions.enterEvent)
        !account ? setIsLoginVisible(state => !state) : props.history.push('/manager/event')
    }

    const fetchDetail = async () => {
        const id = props.match.params.id

        const { data } = await axios.get(`/api/events/${id}`)
        let event = formatData(data)

        if (event.credits) {
            event.credits.confirmed = event.credits.filter(e => e.status === 'Bestätigt')
            event.credits.notConfirmed = event.credits.filter(e => e.status === 'Nicht bestätigt')
        } 
        setEvent(event)
    }

    const formatData = data => {
        data.start = new Date(data.start)
        data.end = new Date(data.end)

        if (data.deadline && data.deadline.length) {
            data.deadline = new Date(data.deadline)
        }

        // data.description = data.description.replace(/(?:\r\n|\r|\n)/g, '<br>')

        return data
    }

    useEffect(() => {
        fetchDetail()
        document.body.classList.add('is-alt')

        return () => {
            document.body.classList.remove('is-alt')
        }
    }, [])

    return (
        <>
            <Helmet>
                <title>{event ? event.title+' - EMH Kongresskalender' : ''}</title>
            </Helmet>
            <header className="site-header">
                <div>
                    <a href="/" className="header-link">
                        <div className="wrap">
                            <img src={logo} className="logo" alt="EMH Logo"/>
                            <h1 className="serif">Swiss Medical Events</h1>
                            <h2 className={'header-sub-title'}>Kongresse, Seminare<br />Weiter- und Fortbildung</h2>
                        </div>
                    </a>
                    <span className="icon-bars show-mobile" onClick={() => setIsNavVisible(state => !state)} />
                </div>
                <div>
                    <div className="wrap">
                        <nav className={`site-nav ${isNavVisible ? 'is-visible' : ''}`}>
                            <ul>
                                <li><a href="https://emh.ch" target="_blank">Über uns</a></li>
                                <li><button onClick={handleEnterEvent}>Event eintragen</button></li>
                            </ul>
                        </nav>
                        <div className="backdrop" />
                        <div>
                            <a href="/" className="back-link">
                                <span className="icon-arrow-left" />
                                Zurück
                            </a>
                        </div>
                    </div>
                </div>
            </header>
            <main className="site-main event-detail">
                <div className="sidebar">
                    {event && (
                        <div>
                            <div className="wrap">
                                <a href="/" className="back-link">
                                    <span className="icon-arrow-left" />
                                    Zur Übersicht
                                </a>
                                {event.preview && (
                                    <div className="widget widget-logo logo-desktop">
                                        <div className="widget-line">
                                            <div className="logo">
                                                    <img src={event.preview} alt={event.title} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {event.signup && (
                                    <div className="widget">
                                        <h4>Anmeldung</h4>
                                        <div className="widget-line">
                                            {event.deadline && isDeadlineReached(event.deadline) ? (
                                                <>
                                                    <span className="icon-times-circle-outline" />
                                                    <div className="registration">
                                                        <h3>Einschreibung geschlossen</h3>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <span className="icon-check-circle">
                                                        <span className="path1" />
                                                        <span className="path2" />
                                                    </span>
                                                    <div className="registration">
                                                        <h3>Einschreibung geöffnet {event.deadline ? `bis ${formatDate(event.deadline)}` : ''}</h3>
                                                        <a data-button href={prefixLink(event.signup)} onClick={() => {emitGAEvent(userActions.register); return true}} target="_blank" rel="noopener noreferrer">Zur Anmeldung</a>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className="widget">
                                    <h4>Credits</h4>
                                    <div className="widget-line">
                                        {event.credits.confirmed.length > 0 && (
                                            <span className="icon-check-circle">
                                                <span className="path1" />
                                                <span className="path2" />
                                            </span>
                                        )}
                                        {!event.credits.confirmed.length && !event.credits.notConfirmed.length && (
                                            <span className="icon-times-circle-outline" />
                                        )}
                                        {!event.credits.confirmed && event.credits.notConfirmed.length && (
                                            <span className="icon-check-circle">
                                                <span className="path1" />
                                            </span>
                                        )}
                                        <div className="credits">
                                            <h3>{event.credits.confirmed.length ? 'Credits bestätigt' : event.credits.notConfirmed.length ? 'Credits beantragt': 'keine Credits'}</h3>
                                        </div>
                                    </div>
                                    <div className="widget-credits" style={{ maxHeight: creditsVisible ? 'none' : '0' }}>
                                        {event.credits.confirmed.map(credit => (
                                            <div key={credit.association} className="widget-line">
                                                <div className="count-indicator">{credit.count}</div>
                                                <div>
                                                    <b>{credit.association}</b><br />
                                                    {allAssociations[credit.association]}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {event.credits.notConfirmed.length > 0 && creditsVisible && (
                                        <div className="widget-line-unconfirmed" style={{display: creditsVisible ? 'none' : 'auto'}} style={{marginTop: creditsVisible ? '2em' : 'auto'}}>
                                                <span className="icon-check-circle">
                                                    <span className="path1" />
                                                </span>
                                                <div>
                                                    <h3>Credits beantragt</h3>
                                                </div>
                                        </div>
                                    )}
                                    <div className="widget-credits" style={{ maxHeight: creditsVisible ? 'none' : '0' }}>
                                        {event.credits.notConfirmed.map(credit => (
                                            <div key={credit.association} className="widget-line">
                                                <div className="count-indicator">{credit.count}</div>
                                                <div>
                                                    <b>{credit.association}</b><br />
                                                    {allAssociations[credit.association]}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {event.credits.length > 0 && (
                                        <div className="widget-toggle" onClick={() => setCreditsVisible(state => !state)}>
                                            <span className={`icon-arrow-down ${creditsVisible ? 'upside-down' : ''}`} />
                                            {creditsVisible ? 'Schliessen' : 'Details'}
                                        </div>
                                    )}
                                </div>
                                <div className="halves location-mobile">
                                    <div>
                                        <div className="detail-item">
                                            <h4>Veranstaltungsort</h4>
                                            <b>{event.venue}</b><br />
                                            <span>{event.room}</span>
                                        </div>
                                        {event.address && (
                                            <div className="detail-item">
                                                <h4>Adresse</h4>
                                                <address>
                                                    {event.address.street} {event.address.streetNr}<br />
                                                    {event.address.postalcode} {event.address.city}<br />
                                                    {event.address.country}
                                                </address>
                                                <a href={`https://maps.google.com/?q=${encodeURIComponent(event.address.street)},${encodeURIComponent(event.address.city)},${encodeURIComponent(event.address.country)}`} target="_blank" rel="noopener noreferrer"><span className="icon-marker" /> Google Maps</a>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {event.company.length ? (
                                            <div className="detail-item">
                                                <h4>Veranstalter</h4>
                                                <b>{event.company}</b>
                                            </div>):''}
                                        <div className="detail-item">
                                            <h4>Kontakt</h4>
                                            <b>{event.contact}</b><br />
                                            {event.phone}<br />
                                            <a className="printable" href={`mailto:${event.mail}`}>{event.mail}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="site-content">
                    {event && event.preview && (
                        <div className="widget widget-logo logo-mobile">
                            <div className="widget-line">
                                <div className="logo">
                                        <img src={event.preview} alt={event.title} />
                                </div>
                            </div>
                        </div>
                    )}
                    {event && (
                        <EventDetail event={event} />
                    )}
                </div>
            </main>
            {!account && isLoginVisible && (
                <LoginModal
                    switchToRegistration={() => setIsLoginVisible(state => !state) || setIsRegistrationVisible(state => !state)}
                    switchToRecover={() => setIsLoginVisible(state => !state) || setIsRecoverVisible(state => !state)}
                    onClose={() => setIsLoginVisible(state => !state)}
                    onLoginSuccess={(email) => setIsLoginVisible(state => !state) || createNotification(notifications.login, 1, email) || props.history.push('/manager')} />
            )}
            {!account && isRegistrationVisible && (
                <RegistrationModal
                    switchModal={() => setIsLoginVisible(state => !state) || setIsRegistrationVisible(state => !state)}
                    onClose={() => setIsRegistrationVisible(state => !state)}
                    onRegistered={() => setIsRegistrationVisible(state => !state) || setIsLoginVisible(true)}/>
            )}
            {!account && isRecoverVisible && (
                <RecoverModal
                    switchModal={() => setIsRecoverVisible(state => !state) || setIsLoginVisible(state => !state)}
                    onClose={() => setIsRecoverVisible(state => !state)} />
            )}
            <Footer setIsLoginVisible={setIsLoginVisible} />
        </>
    )
}

export default withRouter(Detail)
export {formatDate, isDeadlineReached}
