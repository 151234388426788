import React, { useState } from 'react'

const IconButton = ({ href, icon, message, onClick }) => {
    const [ isVisible, setIsVisible ] = useState(false)

    const renderContent = () => (
        <>
            <span className={`icon-${icon}`} />
            <div className={`tooltip-content ${isVisible ? 'is-visible' : ''}`}>
                <span className="small">{message}</span>
            </div>
        </>
    )

    return (
        <>
            {href ? (
                <a
                    className="tooltip"
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    onMouseEnter={() => setIsVisible(true)}
                    onMouseLeave={() => setIsVisible(false)}>
                    {renderContent()}
                </a>
            ) : (
                <span
                    className="tooltip"
                    onMouseEnter={() => setIsVisible(true)}
                    onMouseLeave={() => setIsVisible(false)}
                    onClick={onClick}>
                    {renderContent()}
                </span>
            )}
        </>
    )
}

IconButton.defaultProps = {
    onClick: () => {}
}

export default IconButton
