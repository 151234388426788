import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import Dropdown from './Dropdown'

const FloatingMultiSelect = props => {
    const [ value, setValue ] = useState('')
    const [ options, setOptions ] = useState(props.options)
    const [ visibleOptions, setVisibleOptions ] = useState(props.options)

    const [ hasFocus, setHasFocus ] = useState(false)
    const [ ignoreBlur, setIgnoreBlur ] = useState(false)

    const valueInput = useRef(null)

    const handleValueChange = e => {
        setValue(e.target.value)
    }

    const handleSelection = item => {
        setValue('')

        if (props.selection.indexOf(item) === -1) {
            props.onChange([ ...props.selection, item ])
        } else {
            props.onChange(props.selection.filter(i => i !== item))
        }

        valueInput.current.focus()
    }

    const handleFocus = () => {
        setHasFocus(!props.disabled)
    }

    const handleBlur = () => {
        if (ignoreBlur) {
            valueInput.current.focus()
        } else {
            setHasFocus(false)
            setValue('')
        }
    }

    const handleKeyDown = event => {
        if (event.key === 'Tab') {
            setIgnoreBlur(false)
        } else if (event.key === 'Backspace' && value === '' && props.selection.length) {
            props.onChange(props.selection.slice(0, props.selection.length - 1))
        }
    }

    const fetchOptions = async () => {
        if (!props.source) return

        const { data } = await axios.get(props.source)

        setOptions(data)
        setVisibleOptions(data)
    }

    useEffect(() => {
        setVisibleOptions(options.filter(c => c.toLowerCase().startsWith(value.toLowerCase()) && props.selection.indexOf(c) === -1))
    }, [ value, props.selection ])

    useEffect(() => {
        fetchOptions()
    }, [])

    return (
        <>
            <div id={props.id} className={`floating-group pill-group ${props.selection.length || value || hasFocus ? 'has-value' : ''} ${props.error ? 'validation-error' : ''}`} onClick={() => !hasFocus && valueInput.current.focus()}>
                {props.selection.map(s => (
                    <div
                        key={s}
                        className="pill"
                        onClick={() => setIgnoreBlur(false) || handleSelection(s)}
                        onMouseEnter={() => setIgnoreBlur(true)}
                        onMouseLeave={() => setIgnoreBlur(false)}>
                        {s}
                        <span className="icon-times-circle" />
                    </div>
                ))}
                <input
                    disabled={props.disabled}
                    type="text"
                    ref={valueInput}
                    value={value}
                    onChange={handleValueChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown} />
                <div className="placeholder">
                    {props.placeholder}
                </div>
                <span
                    style={{visibility: props.disabled && 'hidden'}}
                    className="icon-arrow-down"
                    onClick={() => valueInput.current.focus()}
                    onMouseEnter={() => setIgnoreBlur(true)}
                    onMouseLeave={() => setIgnoreBlur(false)} />
                {!!(hasFocus && visibleOptions.length) && (
                    <Dropdown
                        items={visibleOptions}
                        onSelect={handleSelection}
                        onMouseEnter={() => setIgnoreBlur(true)}
                        onMouseLeave={() => setIgnoreBlur(false)} />
                )}
            </div>
            <div className="validation-text">
                {props.error}
            </div>
        </>
    )
}

FloatingMultiSelect.defaultProps = {
    options: [],
    selection: [],
    onChange: () => {}
}

export default FloatingMultiSelect