import {useState, useEffect, useContext} from 'react'
import axios from 'axios'
import useStateWithSessionStorage from "./SessionStorage";

const useAdminAccountInformation = () => {
    const [account, setAccount] = useStateWithSessionStorage('admin')

    const fetchAccountData = async () => {
        const {data} = await axios.get('/api/admin/');
        setAccount(data.email ? data : null)
        console.log('admin data fetched', data)
    }

    const refresh = async () => {
        fetchAccountData()
    }

    const logout = async () => {
        setAccount(null)
    }


    useEffect(() => {
        if (!account || account && Object.values(account).some(e => !e))
            fetchAccountData()
    }, [])


    return [account, refresh, logout]
}

export default useAdminAccountInformation
