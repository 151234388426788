import { useState, useEffect, useContext } from 'react'
import ReactGA from 'react-ga';
import useAccountInformation from '../hooks/AccountInformation'


const useGoogleAnalytics = () => {

    const [account] = useAccountInformation()

    const googleAnalyticsID = 'UA-119799120-4'

    const initializeReactGA = () => {
        console.log('init reactGA')
        ReactGA.initialize(googleAnalyticsID);
        ReactGA.pageview(window.location.pathname +
            window.location.search)
    }

    const emitTrackingEvent = (action, domain) => {
        ReactGA.event({
            category: domain ? domain : account ? 'Login User' : 'Unregistered User',
            action: action
        })
    }

    useEffect(() => {
        ReactGA.initialize(googleAnalyticsID, {
           gaOptions:
               account ? {userId: account.email} : null
        })
    }, [account])

    return [emitTrackingEvent, initializeReactGA]
}


export default useGoogleAnalytics

export const userActions = {
    enterEvent: '"Event Eintragen" geklickt',
    register: '"Zur Anmeldung" geklickt'

}