import React, {useState, useEffect} from 'react'
import {render} from 'react-dom'
import {Route, Link, NavLink, withRouter} from 'react-router-dom'
import Header from '../../components/Admin/Header'
import AdminLoginModal from '../../modals/AdminLoginModal'
import useClickOutside from "../../hooks/ClickOutside";
import useAdminAccountInformation from '../../hooks/AdminAccountInformation'


const Admin = ({history, children}) => {
    const [isLoginVisible, setIsLoginVisible] = useState(false)
    const [adminAccount, refresh, logout] = useAdminAccountInformation()
    const [scrolled, setScrolled] = useState(false)

    const checkRole = (account) => {
        console.log('check admin', account)
        return account && account.hasOwnProperty('roles') && account.roles.includes('Emh.EventManager:Admin')
    }

    const handleScroll = () => {
        // console.log(window.scrollY)
        setScrolled(window.scrollY > 90)
    }

    window.addEventListener('scroll', handleScroll);

    useEffect(() => {
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        console.log('adminAccount check!!', adminAccount)
        if (!adminAccount)
            setIsLoginVisible(true)
        else
            setIsLoginVisible(!checkRole(adminAccount))
    }, [adminAccount])

    const handleLogout = () => {
        console.log('logout!')
        logout()
        setIsLoginVisible(true)
        window.location.href = '/admin/logout'
    }

    return (
        <>
            <div className="site-admin">
                {!isLoginVisible && (
                    <>
                        <Header scrolled={scrolled} handleLogout={handleLogout}/>
                        <main className="">
                            {children}
                        </main>
                    </>
                )
                }
            </div>
            {isLoginVisible && (
                <AdminLoginModal
                    onClose={() => {
                    }}
                    onLoginSuccess={() => setIsLoginVisible(state => !state) || history.push('/admin')}/>
            )}
        </>
    )
}

export default withRouter(Admin);