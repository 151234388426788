import React from 'react'

const PolicyModal = props => {
    return (
        <div className="modal-container">
            <div className="modal-wrapper">
                <span className="icon-times-circle-outline" onClick={() => props.onClose()}/>
                <div className="modal-body">
                    <h1>Disclaimer & Datenschutz</h1>
                    <h2>Haftungsbeschränkung</h2>
                    <p>Verantwortlich für dieses Informationsangebot ist die Herausgeberin, die EMH Schweizerischer
                        Ärzteverlag AG. Die Informationen auf dieser Website werden mit höchster Sorgfalt
                        zusammengestellt und auf dem neuesten Stand gehalten. Für Richtigkeit, Vollständigkeit und
                        Aktualität der bereitgestellten Informationen wird keine Gewähr übernommen. Die Herausgeberin
                        behält sich sodann ausdrücklich vor, jederzeit Inhalte ohne Ankündigung ganz oder teilweise zu
                        ändern, zu löschen oder zeitweise nicht zu veröffentlichen. Aus diesem Grund ist jegliche
                        Haftung für eventuelle, die aufgrund von Missbrauch oder technischen Störungen sowie generell im
                        Zusammenhang mit der Nutzung des Informationsangebots entstanden sind, ausgeschlossen.</p>

                    <h2>Hinweis zum Urheberrecht</h2>
                    <p>Der gesamte Inhalt dieser Website sowie ihre einzelnen Teile und Dokumente sind urheberrechtlich
                        geschützt und dürfen ausschliesslich zum privaten, wissenschaftlichen und nicht kommerziellen
                        Gebrauch heruntergeladen, kopiert und ausgedruckt werden. Jede vom Urheberrechtsgesetz nicht
                        zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des Herausgebers. Unter
                        der strikten Voraussetzung einer korrekten Quellenangabe sind das Zitieren der Dokumente und
                        Website sowie das Setzen von Links erlaubt.</p>

                    <h2>Datenschutz</h2>
                    <p>
                        Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und die datenschutzrechtlichen
                        Bestimmungen des Bundes hat jede Person Anspruch auf Schutz ihrer Privatsphäre sowie auf Schutz
                        vor Missbrauch ihrer persönlichen Daten. Die EMH Schweizerischer Ärzteverlag AG hält diese
                        Bestimmungen ein. Persönliche Daten werden entsprechend den Vorschriften des Bundesgesetzes über
                        den Datenschutz behandelt.
                    </p>
                    <p>
                        Durch den Besuch dieser Website können anonymisierte Informationen über den Zugriff (Datum,
                        Uhrzeit, betrachtete Seite) auf dem Server gespeichert werden. In so genannten Log-Files werden
                        unter anderem auch verwendete Browsertypen, Betriebssysteme, die Internetseite, von welcher Sie
                        auf diese Website weitergeleitet wurden, eine Internet-Protokoll-Adresse und sonstige ähnliche
                        Daten gespeichert. Diese Daten dienen der technischen Analyse im Falle von Wartungsarbeiten und
                        auch der Abwehr von Gefahren im Falle von Angriffen auf unsere Systeme. Bei der Nutzung dieser
                        Daten ziehen wir keine Rückschlüsse auf die betroffene Person. Die Daten werden statistisch und
                        zum Schutze der Datensicherheit verwendet.
                    </p>
                    <p>
                        Diese Nutzungsdaten bilden die Basis für anonyme statistische Auswertungen, sodass Trends
                        erkennbar sind, anhand derer die EMH Schweizerischer Ärzteverlag AG ihr Angebot entsprechend
                        verbessern kann. Gemäss Bundesgesetz betreffend die Überwachung des Post- und Fernmeldeverkehrs
                        (BÜPF) besteht eine gesetzliche Aufbewahrungspflicht für Verbindungsdaten der letzten sechs
                        Monate.
                    </p>
                    <h2>Hinweis zu externen Links</h2>
                    <p>Diese Website enthält Verknüpfungen zu Websites Dritter ("externe Links"). Für die verlinkten
                        Websites haftet ausschliesslich der jeweilige Betreiber. Der Herausgeber hat keinen Einfluss und
                        übernimmt keine Verantwortung für den Inhalt und die Gestaltung der verknüpften Seiten. Mit dem
                        Link ist auch in keiner Weise ein Einverständnis des Herausgebers zum jeweiligen Inhalt der
                        verlinkten Seiten verbunden. Eine ständige Kontrolle dieser externen Links und Inhalte ist für
                        den Herausgeber zudem nicht zumutbar. Bei konkreten Hinweisen auf Rechtsverstösse bleibt eine
                        jederzeitige Löschung des entsprechenden externen Links vorbehalten.</p>

                    <h2>SSL-Verschlüsselung</h2>
                    <p>Die EMH verwendet bei der Übermittlung von allen Daten ihrer Nutzer eine SSL-Verschlüsselung. Der
                        Einsatz der Verschlüsselung kann wie folgt erkannt werden: Die Anzeige in Ihrer Browserzeile
                        wechselt von «http://» zu «https://». Die über SSL verschlüsselten Daten sind nicht von Dritten
                        lesbar.</p>

                    <h2>Cookies</h2>
                    <p>Diese Website verwendet Cookies. Cookies sind kleine Textdateien, die beim Besuch dieser Website
                        auf Ihrem Computer, Tablet oder mobilen Gerät dauerhaft oder temporär gespeichert werden. Zweck
                        der Cookies ist insbesondere die Analyse der Nutzung dieser Website zur statistischen Auswertung
                        sowie für kontinuierliche Verbesserungen. Sie können Cookies in Ihren Browser Einstellungen
                        jederzeit ganz oder teilweise deaktivieren. Bei deaktivierten Cookies stehen Ihnen allenfalls
                        nicht mehr alle Funktionen dieser Website zur Verfügung.</p>
                    <p>Ferner wird darauf hingewiesen, dass diese Website Cookies von Drittanbietern, enthalten kann.
                        Mit der Nutzung dieser Website erklären Sie sich ausdrücklich mit der Nutzung aller Cookies
                        einverstanden.</p>

                    <h2>Google Analytics</h2>
                    <p>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc., USA (Google).
                        Google Analytics verwendet Cookies. Die durch die Cookies erzeugten Informationen über Ihre
                        Benutzung der Website (Datum und Zeit der Anfrage, Ort und Häufigkeit des Aufrufs der Website,
                        Herkunft, Sprache, Betriebssystem, Browser, Klickverhalten, Besucherquelle, Auflösung des
                        Computers, Aufenthaltsdauer auf Website einschliesslich Ihrer anonymisierten IP-Adresse) werden
                        an einen Server von Google in den USA übertragen und dort gespeichert.</p>
                    <p>Die durch Google Analytics gesammelten Informationen werden benutzt, um Ihre Nutzung der Website
                        auszuwerten, Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und
                        um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu
                        erbringen.</p>
                    <p>Google wird diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich
                        vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google bearbeiten. Die im Rahmen
                        von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird, gemäss eigenen Angaben von
                        Google, nicht mit anderen Daten von Google zusammengeführt.</p>
                    <p>Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer
                        Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                        gegebenenfalls nicht sämtliche Funktionen unserer Website vollumfänglich nutzen können.</p>
                    <p>Google bietet für die gängigsten Browser eine Deaktivierungsoption an. Sollten Sie diese
                        Deaktivierungsoption aktivieren, werden keine Informationen zum Website-Besuch an Google
                        Analytics übermittelt. Die Aktivierung der Deaktivierungsoption verhindert aber nicht, dass
                        Informationen an uns oder an andere von uns gegebenenfalls eingesetzte Webanalyse-Services
                        übermittelt werden. Weitere Informationen zu der von Google bereitgestellten
                        Deaktivierungsoption sowie zu der Aktivierung dieser Deaktivierungsoption, erhalten Sie über
                        nachfolgenden Link.</p>
                    <p>Mit der Nutzung dieser Website stimmen Sie ausdrücklich der Bearbeitung Ihrer Daten durch Google
                        gemäss den Nutzungsbedingungen Google Analytics zu.</p>

                </div>
            </div>
        </div>
    )
}

export default PolicyModal
