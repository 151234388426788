import { useEffect, useState } from 'react'

const useClickOutside = (className) => {
    const [ isVisible, setIsVisible ] = useState(false)

    useEffect(() => {
        const listener = e => {
            let target = e.target

            while (target && target.classList && !target.classList.contains(className)) {
                target = target.parentNode
            }

            if (target === document) {
                setIsVisible(false)
            }
        }

        if (isVisible) {
            document.addEventListener('click', listener)
        } else {
            document.removeEventListener('click', listener)
        }

        return () => document.removeEventListener('click', listener)
    }, [ isVisible ])

    return [ isVisible, setIsVisible ]
}

export default useClickOutside
