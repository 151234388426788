import React, { useState, useEffect } from 'react'
import { NavLink, withRouter, Link } from 'react-router-dom'
import isEqual from 'react-fast-compare'
import axios from 'axios'
import MultiSelect from '../components/MultiSelect'
import SingleSelect from '../components/SingleSelect'
import CheckboxGroup from '../components/CheckboxGroup'
import Checkbox from '../components/Checkbox'
import DatePicker from '../components/DatePicker'
import RangeSlider from '../components/RangeSlider'
import TextBox from '../components/TextBox'
import EventCard from '../components/EventCard'
import Footer from '../components/Footer'
import Paginate from '../components/Paginate'
import LoginModal from '../modals/LoginModal'
import RegistrationModal from '../modals/RegistrationModal'
import RecoverModal from '../modals/RecoverModal'
import useClickOutside from '../hooks/ClickOutside'
import useAccountInformation from '../hooks/AccountInformation'
import logo from '../images/logo.svg'
import {departments, regions} from '../constants/constants'
import { Helmet } from "react-helmet";
import {Snackbar} from "react-md";
import useHiddenNotice from '../hooks/Cookie'
import {CookiesNotice} from "../components/CookiesNotice";
import { useSnackbar } from 'notistack';
import useNotifications, {notifications} from '../hooks/Notifications'
import useGoogleAnalytics, {userActions} from '../hooks/GoogleAnalytics'

const languageOptions = [ 'Deutsch', 'Französisch', 'Italienisch', 'Englisch' ]
const typeOptions = [ 'Kongress', 'Seminar', 'Workshop', 'Online']
const timeOptions = [ 'Mittags', 'Abends', 'Ganztägig' ]

const defaultCriteria = {
    query: '',
    departments: [],
    region: '',
    location: '',
    distance: 10,
    timeframe: {
        start: null,
        end: null
    },
    types: [],
    time: [],
    credits: false,
    languages: []
}

const Search = (props) => {
    const [ events, setEvents ] = useState([])
    const [ results, setResults ] = useState([])
    const [ criteria, setCriteria ] = useState(defaultCriteria)
    const [ isDirty, setIsDirty ] = useState(false)

    const [ alerts, setAlerts ] = useState([])
    const [ cityOptions, setCityOptions ] = useState([])

    const [ createNotification, renderNotifications, renderIfDirty ] = useNotifications()

    const [ emitGAEvent ] = useGoogleAnalytics()

    const fetchEvents = async (args) => {
        console.log('filters', urlParameters())
        const url = `/api/events?${urlParameters()}`
        console.log('url', url)
        const result = await axios.get(url)
        result.data.sort((a, b) => {
            return   new Date(a.start) - new Date(b.start)
        })

        let data = result.data.filter(e => {
            return new Date(e.end) >= new Date() && e.status === 'online'
        })

        setEvents(data)
        if (args.filter){
            console.log('filtering')
        }
        setResults(args.filter ? filterEvents(data) : data)

    }

    const filterEvents = (data) => {
        return data.filter(e => {
            const start = new Date(e.start);
            const end = new Date(e.end);

            const dataRangeFilter = criteria.timeframe.start && criteria.timeframe.end ?
                start.getFullYear() >= criteria.timeframe.start.year
                && start.getMonth() >= criteria.timeframe.start.month
                && end.getFullYear() <= criteria.timeframe.end.year
                && end.getMonth() <= criteria.timeframe.end.month
                : true;

            const hasTimes = e.startTime && e.endTime
            const midday = criteria.time.includes('Mittags') ? hasTimes ? e.startTime.substring(0, 2) < 12 : false : true;
            const evening = criteria.time.includes('Abends') ? hasTimes ? e.startTime.substring(0, 2) > 12 : false : true;
            const allday = criteria.time.includes('Ganztätig') ? hasTimes ?  e.startTime.substring(0, 2) < 12 && e.endTime.substring(0, 2) > 12 : false: true;
            // console.log('times', midday, evening, allday, dataRangeFilter, start, new Date())

            const credits = criteria.credits ?  e.credits.length : true;
            const type = criteria.types.length ? criteria.types.some(t => t === e.type) : true;
            const languages = criteria.languages.length ? e.languages.some(e => criteria.languages.includes(e)) : true;

            const location = criteria.location && criteria.location.length ? e.address && e.address.city.toLowerCase().indexOf(criteria.location.toLowerCase()) > -1  : true
            const region = criteria.region.length ? e.address && e.address.region && criteria.region.includes(regions[e.address.region]) : true

            return  (start >= new Date()) && location && region && dataRangeFilter && midday && evening && allday && credits && type && languages && e.status !== 'draft'
        })
    }

    const urlParameters = () => {
        let args = []
        Object.keys(defaultCriteria).forEach(p => {
            const value = criteria[p];
            if (p === 'timeframe') {
                const start = value.start
                args.push(`start=${encodeURIComponent(start ? `${start.month}/${start.year}` : '')}`)
                const end = value.end
                args.push(`end=${encodeURIComponent(end ? `${end.month}/${end.year}` : '')}`)
            } else if (Array.isArray(value)) {
                 value.forEach( e => {
                    args.push(`${p}[]=${encodeURIComponent(e)}`)
                })
            }
            else if (! (p === 'query' && p.length <= 3))
                args.push(`${p}=${encodeURIComponent(criteria[p])}`)

        })
        return args.join('&')
    }

    useEffect(() => {
        let options = [...new Set(events.map(({address}) => address ? address.city.trim() : null))]
        options = options.filter(e => e != null)
        options.sort((a, b) => {
            return a.slice(0, 1) < b.slice(0, 1) ? -1 : a.slice(0, 1) > b.slice(0, 1) ? 1 : 0
        })
        setCityOptions(options)
    }, [events])

    useEffect(() => {
        console.log('criteria fetch new', criteria)
        if (criteria.query && criteria.query.length < 3)
            return

        fetchEvents({filter: true})
    }, [ criteria.departments, criteria.query ])

    useEffect(() => {
        console.log('criteria filter changed', criteria)
        setResults(filterEvents(events))
    }, [criteria.timeframe, criteria.time, criteria.languages, criteria.types, criteria.credits, criteria.region, criteria.location])

    useEffect(() => {
        setIsDirty(!isEqual(criteria, defaultCriteria))
    }, [criteria])

    const augmentCriteria = field => data => setCriteria({ ...criteria, [field]: data })
    const resetCriteria = () => setCriteria(defaultCriteria)

    const [ account ] = useAccountInformation()

    const [ isNavVisible, setIsNavVisible ] = useClickOutside('site-nav')
    const [ isSidebarVisible, setIsSidebarVisible ] = useClickOutside('filter-container')

    const [ isLoginVisible, setIsLoginVisible ] = useState(false)
    const [ isRegistrationVisible, setIsRegistrationVisible ] = useState(false)
    const [ isRecoverVisible, setIsRecoverVisible ] = useState(false)

    const regionOptions = Object.values(regions)

    const handleEnterEvent = () => {
        emitGAEvent(userActions.enterEvent)
        !account ? setIsLoginVisible(state => !state) : props.history.push('/manager/event')
    }

    return (
        <>
            <Helmet>
                <title>Suche - Swiss Medical Events</title>
            </Helmet>
            <header className="site-header">
                <div>
                    <a href="/" className="header-link">
                        <div className="wrap">
                        <img src={logo} className="logo" alt="EMH Logo" />
                        <h1 className="serif">Swiss Medical Events</h1>
                        <h2 className={'header-sub-title'}>Kongresse, Seminare<br />Weiter- und Fortbildung</h2>
                        </div>
                    </a>
                    <span className="icon-bars show-mobile" onClick={() => setIsNavVisible(state => !state)} />
                </div>
                <div>
                    <div className="wrap">
                        <nav className={`site-nav ${isNavVisible ? 'is-visible' : ''}`}>
                            <ul>
                                <li><a href="https://emh.ch" target="_blank">Über uns</a></li>
                                <li><button onClick={handleEnterEvent}>Event eintragen</button></li>
                            </ul>
                        </nav>
                        <div className="backdrop" />
                        <div className="search-container">
                            <TextBox
                                value={criteria.query}
                                placeholder="Suchbegriff"
                                prefix="search"
                                onChange={augmentCriteria('query')} />
                            <button data-button onClick={() => setIsSidebarVisible(state => !state)} className="show-mobile">FILTERN</button>
                        </div>
                    </div>
                </div>
            </header>
            <main className="site-main">
                {!isRecoverVisible && !isLoginVisible && !isRegistrationVisible && (
                    <>
                        <div className={`sidebar filter-container ${isSidebarVisible ? 'is-visible' : ''}`}>
                            <div className="wrap">
                                <div className="sidebar-header">
                                    <div className="h2">{results.length} {results.length === 1 ? 'Ergebnis' : 'Ergebnisse'}</div>
                                    <button data-button onClick={() => setIsSidebarVisible(state => !state)}>FERTIG</button>
                                </div>
                                <h3 className="filter-header">Filtern nach</h3>
                                <div className="form-element">
                                    <label>Fachgebiet</label>
                                    <MultiSelect
                                        options={departments}
                                        selection={criteria.departments}
                                        placeholder="Fachgebiet wählen"
                                        onChange={augmentCriteria('departments')} />
                                </div>
                                <div className="form-element">
                                    <label>Zeitraum</label>
                                    <DatePicker
                                        selection={criteria.timeframe}
                                        prefix="calendar"
                                        onChange={augmentCriteria('timeframe')}
                                        bold={criteria.timeframe}
                                    />
                                </div>
                                <div className="form-element">
                                    <label>Region</label>
                                    <MultiSelect
                                        options={regionOptions}
                                        selection={criteria.region}
                                        placeholder="Region wählen"
                                        // prefix="marker"
                                        onChange={augmentCriteria('region')}/>
                                </div>
                                <div className="form-element">
                                    <label>Ort</label>
                                    <SingleSelect
                                        options={cityOptions}
                                        selection={criteria.location}
                                        placeholder="Ort eingeben"
                                        prefix="marker"
                                        onChange={augmentCriteria('location')}
                                        bold={criteria.location}/>
                                </div>
                                <div className="form-element">
                                    <label>Credits</label>
                                    <Checkbox
                                        label="Gibt Credits"
                                        selected={criteria.credits}
                                        onChange={() => augmentCriteria('credits')(!criteria.credits)}
                                        bold={criteria.credits}
                                    />
                                </div>
                                <div className="form-element">
                                    <label>Art der Veranstaltung</label>
                                    <CheckboxGroup
                                        options={typeOptions}
                                        selection={criteria.types}
                                        onChange={augmentCriteria('types')}
                                        bold={criteria.types}
                                    />
                                </div>
                                <div className="form-element">
                                    <label>Uhrzeit</label>
                                    <CheckboxGroup
                                        options={timeOptions}
                                        selection={criteria.time}
                                        onChange={augmentCriteria('time')}
                                        bold={criteria.time}
                                    />
                                </div>
                                <div className="form-element">
                                    <label>Sprache</label>
                                    <CheckboxGroup
                                        options={languageOptions}
                                        selection={criteria.languages}
                                        onChange={augmentCriteria('languages')}
                                        bold={criteria.languages}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="backdrop" />
                        <div className="site-content">
                            <div className="wrap">
                                {isDirty && (
                                    <>
                                        <div className="result-header">
                                            <div className="count h2">{results.length} {results.length === 1 ? 'Veranstaltung' : 'Veranstaltungen'} gefunden</div>
                                            <button onClick={resetCriteria}>Filter löschen</button>
                                        </div>
                                        <div className="result-list">
                                            <Paginate
                                                items={results}
                                                itemRenderer={item => (
                                                    <EventCard key={item.id} event={item} />
                                                )} />
                                        </div>
                                    </>
                                )}
                                {!isDirty && (
                                    <>
                                        {results.find(r => r.highlightOnline) && (
                                            <div className="result-header">
                                                <div className="count h2">Aktuelle Top Events</div>
                                            </div>
                                        )}
                                        <div className="result-list">
                                            {results.filter(r => r.highlightOnline).map(event => (
                                                <EventCard key={event.id} event={event} />
                                            ))}
                                        </div>
                                        <div className="result-header">
                                            <div className="count h2">Alle Veranstaltungen</div>
                                        </div>
                                        <div className="result-list">
                                            <Paginate
                                                items={results.filter(r => !r.highlightOnline)}
                                                itemRenderer={(item, index) => (
                                                    <EventCard key={index} event={item} />
                                                )} />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </>

                )}
            </main>

            {!account && isLoginVisible && (
                <LoginModal
                    switchToRegistration={() => setIsLoginVisible(state => !state) || setIsRegistrationVisible(state => !state)}
                    switchToRecover={() => setIsLoginVisible(state => !state) || setIsRecoverVisible(state => !state)}
                    onClose={() => setIsLoginVisible(state => !state)}
                    onLoginSuccess={(email) => setIsLoginVisible(state => !state) || createNotification(notifications.login, 1, email) || props.history.push('/manager')} />
            )}
            {!account && isRegistrationVisible && (
                <RegistrationModal
                    switchModal={() => setIsLoginVisible(state => !state) || setIsRegistrationVisible(state => !state)}
                    onClose={() => setIsRegistrationVisible(state => !state)}
                    onRegistered={() => setIsRegistrationVisible(state => !state) || setIsLoginVisible(true)}/>
            )}
            {!account && isRecoverVisible && (
                <RecoverModal
                    switchModal={() => setIsRecoverVisible(state => !state) || setIsLoginVisible(state => !state)}
                    onClose={() => setIsRecoverVisible(state => !state)} />
            )}
            {!isRecoverVisible && !isLoginVisible && !isRegistrationVisible && (
                <Footer setIsLoginVisible={setIsLoginVisible} />
             )
            }

        </>
    )
}

export default withRouter(Search)
