import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import useStateWithSessionStorage from "./SessionStorage";
import {useSnackbar} from "notistack";
import Card from '@material-ui/core/Card';
import {useGlobalState} from "../App";
import {notifications} from '../constants/constants'



const useNotifications = () => {
    const { enqueueSnackbar }  = useSnackbar()


    const [notificationsDirty, setNotificationsDirty] = useGlobalState('notificationsDirty');

    const renderNotifications = async () => {
        const {data} = await axios.get(`/api/notifications?timestamp=${new Date().getTime()}`, {
            headers: {
                'Cache-Control': 'no-cache, no-store'
            }
        })
        setNotificationsDirty(false)
        data.forEach((n, i) => {
            createNotification(n, i)
        })
    }

    const renderIfDirty = () => {
        console.log('renderIfDirty, dirty:', notificationsDirty)
        if (notificationsDirty) {
            renderNotifications()
        }
    }

    useEffect(() => {
        console.log('init useNotis')
    }, [])

    const createNotification = (data, i, arg) => {
        if (arg) {
            data.message = data.message.replace('%s', `${arg}`)
        }

        enqueueSnackbar(i, {
            children: (key) => (
                <Notification id={key} title={data.title} message={data.message}/>
            )
        })
    }

    return [createNotification, renderNotifications, renderIfDirty]

}
export default useNotifications
export {notifications}

const Notification = React.forwardRef((props, ref) => {
    const { closeSnackbar } = useSnackbar()

    const handleDismiss = () => {
        closeSnackbar(props.id);
    }

    return (
        <Card ref={ref} style={{maxWidth: 400}}>
            <div className="notification-wrap">
                <span className="icon-times-circle-outline" onClick={handleDismiss} />
                <div className="notification-title">{props.title}</div>
                <div className="notification-body">{props.message}</div>
            </div>
        </Card>
    )

})