import React, {useEffect, useState} from 'react'
import { withRouter } from 'react-router-dom'
import EventDetail from '../../components/EventDetail'
import axios from "axios";

const Detail = (props) => {
    const [ event, setEvent ] = useState(null)

    const fetchDetail = async () => {
        const id = props.match.params.id

        const { data } = await axios.get(`/api/events/${id}`)
        let event = formatData(data)

        if (event.credits) {
            event.credits.confirmed = event.credits.filter(e => e.status === 'Bestätigt')
            event.credits.notConfirmed = event.credits.filter(e => e.status === 'Nicht bestätigt')
        }

        console.log('event detail', event)

        setEvent(event)
    }

    const formatData = data => {
        data.start = new Date(data.start)
        data.end = new Date(data.end)

        data.deadline = new Date(data.deadline)

        // if (data.deadline && data.deadline.length) {
        //     data.deadline = new Date(data.deadline)
        // }

        return data
    }

    const formatDate = date => {
        const d = new Date(date)
        const month = (d.getMonth() + 1).toString().padStart(2, '0')
        const day = d.getDate().toString().padStart(2, '0')
        const year = d.getFullYear()

        return [ year, month, day ].join('-')
    }

    const formatEvent = event => {
        event.deadline = event.registration && event.registration.deadline ? formatDate(event.registration.deadline.date) : null
        event.signup = event.registration && event.registration.signup || null
        event.end = formatDate(event.end)
        event.start = formatDate(event.start)
        return event
    }

    const formatSpeakers = speakers => speakers.split('\n')


    const acceptEvent = async () => {
        event.status = 'online'
        await axios.put(`/api/admin/events/${event.id}`, {data: formatEvent(event)})
        props.history.push(props.location.state && props.location.state.init, {remove: event.id})
    }

    const declineEvent = async () => {
        event.status = 'rejected'
        await axios.delete(`/api/events/${event.id}`, {data: formatEvent(event)})
        props.history.push(props.location.state && props.location.state.init, {remove: event.id})
    }

    useEffect(() => {
        fetchDetail()
        document.body.classList.add('is-alt')

        return () => {
            document.body.classList.remove('is-alt')
        }
    }, [])
    return (
        <>
            <div className="site-content">
                {event && (
                    <>
                        <div className="side-bar">
                            <button onClick={() => props.history.push(props.location.state && props.location.state.init ? props.location.state.init : '/admin/events')} className="back-link">
                                <span className="icon-arrow-left" />
                                Zur Übersicht
                            </button>
                            <div className="buttons-review">
                                {(event.status === 'review' || event.status === 'Überprüfen') && (<button data-button onClick={acceptEvent}>Veröffentlichen</button>)}
                                <button data-button onClick={declineEvent}>Löschen</button>
                            </div>
                            {/*<a href={`/admin/event/${event.webId}`} rel="noopener noreferrer" className="edit-event">*/}
                                {/*<span className="icon-pen" />*/}
                                {/*Bearbeiten*/}
                            {/*</a>*/}
                            <button onClick={() => props.history.push(`/admin/event/${event.webId}`, {init: props.location.state && props.location.state.init ? props.location.state.init : 'admin/events'})} className="edit-event">
                                <span className="icon-pen" />
                                Bearbeiten</button>

                        </div>
                        <EventDetail event={event} admin/>
                    </>
                )}
            </div>
        </>
    )
}
export default withRouter(Detail);
