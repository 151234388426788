import React, {useEffect, useState} from 'react'
import {withRouter, NavLink, Link} from 'react-router-dom'
import FloatingSingleSelect from '../components/FloatingSingleSelect'
import FloatingTextBox from '../components/FloatingTextBox'
import FloatingTextArea from '../components/FloatingTextArea'
import useAccountInformation from '../hooks/AccountInformation'
import useClickOutside from '../hooks/ClickOutside'
import logo from '../images/logo.svg'
import axios from 'axios'
import ChangePasswordModal from '../modals/ChangePasswordModal'
import {Helmet} from 'react-helmet';
import useSnackBar from '../hooks/SnackBar'
import useNotifications, {notifications} from "../hooks/Notifications";
import Checkbox from "../components/Checkbox";
import CheckboxGroup from "../components/CheckboxGroup";
import ValidatedCheckbox from "../components/ValidatedCheckbox";
import useGoogleAnalytics, {userActions} from "../hooks/GoogleAnalytics";
import {useGlobalState} from "../App";


export const defaultFormData = {
    email: '',
    salutation: '',
    title: '',
    firstname: '',
    organisation: '',
    lastname: '',
    street: '',
    postbox: '',
    postalcode: '',
    city: '',
    country: '',
    phone: '',
    reference: '',
    notes: '',
    policyAccepted: false
}

export const validateProfile = data => {
    const notEmpty = ['salutation', 'firstname', 'lastname', 'organisation', 'phone', 'email', 'street', 'postalcode', 'city', 'country']
    // const notEmptyAddress = ['street', 'postalcode', 'city', 'country']
    const result = !notEmpty.some(f => !(data[f] && data[f].length));
    // const resultAddress = !notEmptyAddress.some(f => !(data.address && data.address[f] && data.address[f].length));
    console.log('result val profile', result)
    // console.log('resultAddress', resultAddress)
    return result
}

export const getMissing = (formData) => {
    const notEmpty = ['salutation', 'firstname', 'lastname', 'organisation', 'phone', 'email', 'street', 'postalcode', 'city', 'country']
    const missingEmpty = notEmpty.filter(f => !(formData[f] && formData[f].length > 0))
    !formData.policyAccepted && missingEmpty.push('policyAccepted')
    return missingEmpty;
}

export const validateEmail = email => {
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let result =  re.test(String(email).toLowerCase())
    console.log(`validate email "${email}": `, result)
    return result
}

const Profile = (props) => {
    const [account] = useAccountInformation()

    const [formData, setFormData] = useState(account || defaultFormData)
    const [user, setUser] = useState(account)
    const [missing, setMissing] = useState([])
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [isNavVisible, setIsNavVisible] = useClickOutside('site-nav')
    const [ isSidebarVisible, setIsSidebarVisible ] = useClickOutside('manager')

    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false)

    const [createNotification, renderNotifications] = useNotifications()

    const [emitGAEvent] = useGoogleAnalytics()

    const [notificationsDirty, setNotificationsDirty] = useGlobalState('notificationsDirty');


    const validateProfileMissing = () => {
        const missingFields = getMissing(formData)
        setMissing(missingFields)
        // const eMailOk = validateEmail(formData.email);
        // setInvalidEmail(!eMailOk)

        return !missingFields.length
    }

    const editProfileData = async id => {
        let {data} = await axios.get(`/api/user/`)

        setUser(data)
        setFormData(data)
    }

    useEffect(() => {
        editProfileData()
    }, [account])

    useEffect(() => {
        console.log('formData update', formData)
        // if (!formData.dataNotice)
        //     setFormData({...formData, dataNotice: [] })
    }, [formData])

    const postRequest = async () => {
        let data = formData;
        console.log('profile data', {data: data})
        await axios.post('/api/user/info', {data: data})
        createNotification(notifications.userdataUpdated)
        props.history.push('/manager', {renderNotifications: true})
    }

    const onLogout = async () => {
        await axios.get('/logout')
        props.history.push('/')
        createNotification(notifications.logout)
    }

    return (
        <>
            <Helmet>
                <title>Ihr Profil - Swiss Medical Events</title>
            </Helmet>
            <header className="site-header">
                <div>
                    <a href="/" className="header-link">
                        <div className="wrap">
                            <img src={logo} className="logo" alt="EMH Logo"/>
                            <h1 className="serif">Swiss Medical Events</h1>
                            <h2 className={'header-sub-title'}>Kongresse, Seminare<br />Weiter- und Fortbildung</h2>
                        </div>
                    </a>
                    <span className="icon-bars show-mobile" onClick={() => setIsNavVisible(state => !state)}/>
                </div>
                <div>
                    <div className="wrap">
                        <nav className="site-nav">
                            <ul>
                                <li><a href="http://emh.ch" target="_blank">Über uns</a></li>
                                <li><button onClick={() => emitGAEvent(userActions.enterEvent) || props.history.push('/manager/event')}>Event eintragen</button></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
            <main className="site-main manager">
                <div className={`sidebar ${isNavVisible ? 'is-visible' : ''}`}>
                    <div className="wrap">
                        <div className="sidebar-header">
                            <span className="sidebar-close icon-times-circle-outline" onClick={() => setIsSidebarVisible(state => !state)} />
                        </div>
                        <nav className="sidebar-nav">
                            <ul>
                                <li>
                                    <span style={{cursor: 'default'}} className="icon-account"/>
                                    <div>
                                        {account && (
                                            <>
                                                <p className="name">{user ? user.firstname : ''} {user ? user.lastname : ''}</p>
                                                <p className="user">{account.email}</p>
                                            </>
                                        )}
                                        <p className="logout"><a style={{cursor: 'pointer'}}  onClick={onLogout}>Abmelden</a></p>
                                    </div>
                                </li>
                                <li>
                                    <NavLink className="nav-link" to="/manager">
                                        <span className="icon-calendar"/>
                                        Event Manager
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="nav-link highlighted" to="/manager/profile">
                                        <span className="icon-badge"/>
                                        Ihr Profil
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="backdrop"/>
                <div className="site-content">
                    <div className="event-group">
                        <div className="wrap">
                            <h2>Anmeldeinformationen</h2>
                            <FloatingTextBox
                                value={formData.email}
                                name="email"
                                readOnly
                                placeholder="E-Mail*"
                                onChange={() => {}}
                            />
                            <div className="help-text" style={{marginBottom: '2.75em'}}>
                                <p>Wenn Sie Ihre E-Mail Adresse ändern möchten, kontaktieren Sie bitte <a href="mailto:events@emh.ch">events@emh.ch</a></p>
                            </div>
                            <div>
                                <button data-button
                                        onClick={() => setIsChangePasswordModalOpen(true)}>Passwort ändern
                                </button>
                            </div>
                        </div>
                    </div>
                    <ProfileForm
                        formData={formData}
                        setFormData={setFormData}
                        error={missing}
                        invalidEmail={invalidEmail}
                    />
                    <div className="page-controls">
                        <div className="wrap">
                            <div>
                                <button data-button="flat"
                                        onClick={() => setFormData(defaultFormData) || props.history.push('/manager')}>Abbrechen
                                </button>
                                <button data-button="flat"
                                        onClick={() => setFormData(defaultFormData) || props.history.push('/manager')}><span
                                    className="icon-times-circle-outline"/></button>
                            </div>
                            <div>
                                <button data-button
                                        onClick={() => validateProfileMissing() && postRequest()}>Speichern
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {isChangePasswordModalOpen && (
                <ChangePasswordModal onChanged={() => createNotification(notifications.passwordUpdated)} onClose={() => setIsChangePasswordModalOpen(false)}/>
            )}
        </>
    )
}

export const ProfileForm = props => {
    const augmentFormData = field => data => props.setFormData(state => ({...state, [field]: data}))

    useEffect(() => {
        console.log('init formdatat', props.formData)
    }, [])

    return (
        <>
            <div className="event-group">
                <div className="wrap">
                    <h2>Persönliche Angaben</h2>
                    <div className="control-group">
                        <FloatingSingleSelect
                            selection={props.formData.salutation}
                            options={['', 'Herr', 'Frau']}
                            placeholder="Anrede*"
                            readOnly
                            onChange={augmentFormData('salutation')}
                            error={props.error && props.error.includes('salutation') ? 'Bitte ausfüllen' : null}
                        />
                        <FloatingTextBox
                            value={props.formData.title}
                            name="title"
                            placeholder="Titel"
                            onChange={augmentFormData('title')}/>
                    </div>
                    <div className="control-group">
                        <FloatingTextBox
                            value={props.formData.firstname}
                            name="firstname"
                            placeholder="Vorname*"
                            onChange={augmentFormData('firstname')}
                            error={props.error && props.error.includes('firstname') ? 'Bitte ausfüllen' : null}
                        />
                        <FloatingTextBox
                            value={props.formData.lastname}
                            name="lastname"
                            placeholder="Name*"
                            onChange={augmentFormData('lastname')}
                            error={props.error && props.error.includes('lastname') ? 'Bitte ausfüllen' : null}
                        />
                    </div>
                    <FloatingTextBox
                        value={props.formData.phone}
                        name="phone"
                        placeholder="Telefon*"
                        onChange={augmentFormData('phone')}
                        error={props.error && props.error.includes('phone') ? 'Bitte ausfüllen' : null}
                    />
                </div>
            </div>
            <div className="event-group">
                <div className="wrap">
                    <h2>Adresse</h2>
                    <FloatingTextBox
                        value={props.formData.organisation}
                        name="organisation"
                        placeholder="Organisation*"
                        onChange={augmentFormData('organisation')}
                        error={props.error && props.error.includes('organisation') ? 'Bitte ausfüllen' : null}

                    />
                    <div className="control-group">
                        <FloatingTextBox
                            value={props.formData.street}
                            name="street"
                            placeholder="Strasse*"
                            onChange={augmentFormData('street')}
                            error={props.error && props.error.includes('street') ? 'Bitte ausfüllen' : null}
                        />
                        <FloatingTextBox
                            value={props.formData.postbox}
                            name="postbox"
                            placeholder="Postfach"
                            autocomplete="off"
                            onChange={augmentFormData('postbox')}/>
                    </div>
                    <div className="control-group">
                        <FloatingTextBox
                            value={props.formData.postalcode}
                            name="postalcode"
                            placeholder="PLZ*"
                            onChange={augmentFormData('postalcode')}
                            error={props.error && props.error.includes('postalcode') ? 'Bitte ausfüllen' : null}
                        />
                        <FloatingTextBox
                            value={props.formData.city}
                            name="city"
                            placeholder="Ort*"
                            onChange={augmentFormData('city')}
                            error={props.error && props.error.includes('city') ? 'Bitte ausfüllen' : null}
                        />
                    </div>
                    <FloatingSingleSelect
                        selection={props.formData.country}
                        options={['', 'Schweiz', 'Deutschland', 'Österreich', 'Frankreich']}
                        placeholder="Land*"
                        readOnly
                        onChange={augmentFormData('country')}
                        error={props.error && props.error.includes('country') ? 'Bitte ausfüllen' : null}
                    />
                </div>
            </div>
            <div className="event-group">
                <div className="wrap">
                    <h2>Zusatzinformationen</h2>
                    <FloatingTextArea
                        value={props.formData.notes}
                        placeholder="Interne Notiz"
                        onChange={augmentFormData('notes')}/>
                </div>
            </div>
            <div className="event-group">
                <div className="wrap">
                    <h2>Einwilligung zur Datenspeicherung</h2>
                    <p>Ich willige ein, dass meine Angaben zur Kontaktaufnahme und Zuordnung für eventuelle Rückfragen dauerhaft elektronisch gespeichert werden.</p>
                    <ValidatedCheckbox
                        id="eventform-policyAccepted"
                        selected={props.formData.policyAccepted}
                        key={true}
                        label={'Einverstanden'}
                        validated={true}
                        onChange={() => props.setFormData({...props.formData, policyAccepted: !props.formData.policyAccepted})}
                        error={props.error && props.error.includes('policyAccepted') ? 'Bitte einwilligen' : null}
                        style={{marginBottom: '2em'}}
                    />

                </div>
            </div>
        </>
    )
}

export default withRouter(Profile)