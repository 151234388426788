import React, {useEffect} from 'react'
import FloatingSingleSelect from './FloatingSingleSelect'
import SingleSelect from './SingleSelect'
import FloatingTextBox from './FloatingTextBox'
import RadioGroup from './RadioGroup'
import { associations } from '../constants/constants'

const statusOptions = [ 'Bestätigt', 'Nicht bestätigt' ]

const Credit = props => {
    const augmentCreditData = field => data => {
        props.onChange({ ...props.value, [field]: data })
    }

    const handleDelete = () => {
        props.onDelete()
    }

    useEffect(() => {
        console.log('credit', props.value)
    }, [props])

    return (
        <div className="credit">
            <div>
                <label>Status</label>
                <RadioGroup
                    options={statusOptions}
                    selection={props.value.status}
                    onChange={augmentCreditData('status')}
                    readOnly />
                <div className="control-group">
                    <FloatingTextBox className="credit-points"
                        value={props.value.count}
                        placeholder="Anzahl*"
                        onChange={augmentCreditData('count')}
                        error={props.error}
                        number
                    />
                    <FloatingSingleSelect className="credit-abreviation"
                        readOnly
                        options={Object.keys(associations)}
                        selection={props.value.association}
                        placeholder="Fachgesellschaft"
                        onChange={augmentCreditData('association')}
                        error={props.error}
                    />
                    <FloatingSingleSelect className="credit-department"
                        fixed
                        fixedValue = {associations[props.value.association]}
                        readOnly
                        options={[]}
                        selection={associations[props.value.association]}
                        placeholder="Fachgebiet"
                        onChange={() => {}}
                        error={props.error}
                    />
                </div>
            </div>
            <span
                className={`icon-times-circle ${props.error ? 'validation-error' : ''}`}
                onClick={handleDelete} />
        </div>
    )
}

Credit.defaultProps = {
    value: {},
    onChange: () => {},
    onDelete: () => {}
}

export default Credit

