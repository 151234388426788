import { useState, useEffect } from 'react'


const useStateWithSessionStorage = (localStorageKey, initialState) => {

    const [value, setValue] = useState(
        JSON.parse(sessionStorage.getItem(localStorageKey)) || initialState || null
    )

    useEffect(() => {
        if (value)
            sessionStorage.setItem(localStorageKey, JSON.stringify(value));
        else
            sessionStorage.removeItem(localStorageKey)
    }, [value])

    return [value, setValue]
}

export default useStateWithSessionStorage