import React from 'react'
import useClickOutside from "../hooks/ClickOutside";
import PolicyModal from '../modals/PolicyModal'


export const CookiesNotice = props => {
    const [isPolicyModalVisible, setIsPolicyModalVisible] = useClickOutside('modal-wrapper')

    return (
            <>
            <div className="cookies-notice">
                <div className="wrap">
                    <p>Um Ihnen ein besseres Nutzererlebnis zu bieten, verwenden wir Cookies. Weitere Informationen dazu finden Sie <span style={{color: '#28b8ce', textDecoration: 'none', cursor: 'pointer'}} onClick={() => setIsPolicyModalVisible(true)}>hier</span>.</p>
                    <button data-button className="button" onClick={() => setIsPolicyModalVisible(false) || props.onHide()}>Einverstanden</button>
                </div>
            </div>
            {isPolicyModalVisible && (
                <PolicyModal onClose={() => {
                    setIsPolicyModalVisible(false)
                }}>
                </PolicyModal>)}
            </>
    )
}
