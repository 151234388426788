import React from 'react'

const Checkbox = props => {
    const handleToggle = () => {
        if (!props.readOnly)
            props.onChange(props.label)
    }

    return (
        <div style={props.style} id={props.id} className={`form-group checkbox-group`}>
            {props.selected ? (
                <span className={`${props.flag ? 'icon-check-circle' : 'icon-check-circle-filled'} ${props.readOnly ? 'readonly' : ''}`} onClick={handleToggle}>
                <span className="path1" />
                <span className="path2" />
            </span>
            ) : (
                <span className={`${props.flag ? 'icon-times-circle-outline' : 'icon-circle'}  ${props.readOnly ? 'readonly' : ''}`} onClick={handleToggle} />
            )}
            {props.bold}
            <label className={`${props.bold ? 'bold':''} ${props.readOnly ? 'readonly' : ''}`} onClick={handleToggle}>{props.label}</label>
        </div>
    )
}

Checkbox.defaultProps = {
    onChange: () => {}
}

export default Checkbox
