import React, {useState, useEffect} from 'react'

const ConfirmDeleteModal = props => {

    return (
        <div className="modal-container" style={{paddingTop: '15%'}}>
            <div className="modal-wrapper" style={{maxWidth: '25em'}}>
                <span className="icon-times-circle-outline" onClick={() => props.onDismiss()}/>
                <div className="modal-body" style={{padding: '1em'}}>
                    <h2>Event löschen</h2>
                    <p>Wollen Sie den Event "{props.event.title}" wirklich löschen?</p>
                    <p>Diese Aktion kann nicht rückgängig gemacht werden.</p>
                    <div className="form-options">
                        <button data-button="flat" onClick={() => props.onDismiss()}>Abbrechen</button>
                        <button data-button onClick={() => props.onConfirm()}>Löschen</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmDeleteModal
