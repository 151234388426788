import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import useStateWithSessionStorage from "./SessionStorage";

const useAccountInformation = () => {
    const [ account, setAccount ] = useStateWithSessionStorage('account')

    const fetchAccountData = async () => {
        const { data } = await axios.get('/api/user');
        setAccount(data.email ? data : null)
        console.log('account data fetched', account)
    }

    const refresh = async () => {
        fetchAccountData()
    }

    const logout = async () => {
        setAccount(null)
    }

    const setUser = (user) => {
        setAccount(user)
    }

    useEffect(() => {
        if (!account || account && Object.values(account).some(e => !e))
            fetchAccountData()
    },[])


    return [account, refresh, setUser, logout]
}

export default useAccountInformation
