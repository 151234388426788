import React, {useState, useRef} from 'react'

const FloatingTextBox = props => {
    const [hasFocus, setHasFocus] = useState(false)
    const valueInput = useRef(null)

    const handleValueChange = e => {
        props.onChange(!props.max ? e.target.value : e.target.value.substring(0, props.max))
    }

    const handleKeyPress = e => {
        if (e.which === 13 && props.onEnter) {
            props.onEnter()
        }

        if (props.number) {
            const keyCode = e.keyCode || e.which;
            const keyValue = String.fromCharCode(keyCode);
            if (/\+|-/.test(keyValue))
                e.preventDefault();
        }
    }

    return (
        <div>
            <div
                id={props.id}
                className={`floating-group ${props.value || hasFocus ? 'has-value' : ''}
                    ${props.error ? 'validation-error' : ''}
                    ${props.readOnly ? 'readonly' : ''}`}
                style={props.errorColor ? {borderBottom: `0.15em inset ${props.errorColor}`} : {}}
                onClick={() => valueInput.current.focus()}>
                <input
                    readOnly={props.readOnly}
                    autoComplete={props.autoComplete}
                    type={props.mask ? 'password' : 'text'}
                    name={props.name}
                    ref={valueInput}
                    value={props.value}
                    onChange={handleValueChange}
                    onFocus={() => setHasFocus(true)}
                    onBlur={() => setHasFocus(false)}
                    onKeyPress={handleKeyPress}
                />
                <div className="placeholder">
                    {props.placeholder}
                </div>
            </div>
            <div className="validation-text" style={props.errorColor ? {color: props.errorColor} : {}}>
                {props.error}
            </div>
        </div>
    )
}

FloatingTextBox.defaultProps = {
    onChange: () => {
    }
}

export default FloatingTextBox
