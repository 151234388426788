import React, { useState, useEffect } from 'react'
import history from 'react-dom'
import axios from 'axios'
import FloatingTextBox from '../components/FloatingTextBox'
import NewPasswordBox from '../components/NewPasswordBox'
import {PasswordHint} from '../components/PasswordHint'


const defaultFormData = {
    oldPassword: '',
    password: '',
    passwordRepeat: ''
}


const ChangePasswordModal = props => {
    const [ formData, setFormData ] = useState(defaultFormData)

    const [ oldPasswordError, setOldPasswordError ] = useState('');
    const [ passwordError, setPasswordError] = useState(null)
    const [ passwordRepeatError, setPasswordRepeatError ] = useState('');

    const changeAction = async () => {
        if (!validate())
            return;

        await axios.post(`/api/user/password`, {data: formData})
            .then(
                ok => {
                    if (!ok.status === 200)
                        setOldPasswordError(true)

                    props.onChanged()
                    props.onClose()
                }).catch( error => {
                    setOldPasswordError(true)
                })
    }

    const validate = () => {
        if (passwordError)
            return

        if (formData.password !== formData.passwordRepeat) {
            setPasswordRepeatError(true)
        }

        return !(passwordRepeatError && oldPasswordError)
    }

    return (
        <div className="modal-container">
            <div className="modal-wrapper">
                <span className="icon-times-circle-outline" onClick={() => props.onClose()} />
                <div className="modal-body">
                    <h1>Passwort ändern</h1>
                    <FloatingTextBox
                        value={formData.oldPassword}
                        placeholder="Altes Passwort"
                        mask
                        onChange={(val) => setFormData({...formData, oldPassword: val})}
                        className={oldPasswordError ? 'validation-error':''}
                        error={oldPasswordError ? 'Altes Passwort nicht korrekt':null} />
                    <PasswordHint/>
                    <NewPasswordBox
                        value={formData.password}
                        onChange={(val) => setFormData({...formData, password: val})}
                        valid={val => setPasswordError(!val)}
                        />
                    <FloatingTextBox
                        value={formData.passwordRepeat}
                        placeholder="Neues Passwort wiederholen"
                        mask
                        onEnter={changeAction}
                        onChange={(val) => setFormData({...formData, passwordRepeat: val})}
                        className={passwordRepeatError ? 'validation-error':''}
                        error={passwordRepeatError ? 'Passwörter stimmen nicht überein':null} />
                    <div className="form-options">
                        <button data-button onClick={changeAction}>Ändern</button>
                    </div>
                    </div>
            </div>
        </div>
    )
}

export default ChangePasswordModal
