import React, { useState, useEffect } from 'react'
import history from 'react-dom'
import FloatingTextBox from '../components/FloatingTextBox'
import axios from 'axios'
import useAccountInformation from '../hooks/AccountInformation'

const defaultFormData = {
    email: '',
    password: ''
}

const LoginModal = props => {
    const [ formData, setFormData ] = useState(defaultFormData)
    const [ validationError, setValidationError ] = useState(null);
    const augmentFormData = field => data => setFormData(state => ({ ...state, [field]: data }))

    const [ account, refresh ] = useAccountInformation()

    const loginAction = async () => {
        const route = '__authentication[Neos][Flow][Security][Authentication][Token][UsernamePassword]'
        const params = {
            email: `[username]=${formData.email}`,
            password : `[password]=${formData.password}`
        }
        await axios.post(`/authenticate?${route}${params.email}&${route}${params.password}`)
            .then(
                res => {
                    refresh()
                    if (res.status === 200)
                        props.onLoginSuccess(formData.email)
                    else
                        props.setValidationError(true)
                    console.log('accoutn', account)
                    // console.log('ok res', ok)
                }).catch( error => {
                if (error.response.status === 428) {
                        setValidationError('Bitte aktivieren Sie Ihren Account zuerst mit dem Aktivierungslink den Sie per E-Mail erhalten haben')
                    } else {
                        setValidationError('Anmeldung nicht erfolgreich')
                    }
                })
    }

    return (
        <div className="modal-container">
            <div className="modal-wrapper">
                <span className="icon-times-circle-outline" onClick={() => props.onClose()} />
                <div className="modal-body">
                    <h1>Login</h1>
                    <h2>Benutzeranmeldung</h2>
                    <FloatingTextBox
                        value={formData.email}
                        placeholder="Benutzername (E-Mail)"
                        onChange={augmentFormData('email')}
                        onEnter={loginAction}
                        className={validationError ? 'validation-error':''}
                        error={validationError}
                    />
                    <FloatingTextBox
                        value={formData.password}
                        placeholder="Passwort"
                        mask
                        onChange={augmentFormData('password')}
                        onEnter={loginAction}
                        className={validationError ? 'validation-error':''}
                        error={validationError}
                    />
                    <div className="form-options">
                        <button data-button="flat" onClick={props.switchToRecover}>Passwort vergessen?</button>
                        <button data-button onClick={loginAction}>Anmelden</button>
                    </div>
                    <h2>Noch keinen Account?</h2>
                    <p>Um die Qualität der Daten in <em>Swiss Medical Events</em> zu gewährleisten, ist für das Erstellen neuer Veranstaltungen eine kostenlose Registrierung notwendig.</p>
                    <button data-button onClick={props.switchToRegistration}>Kostenlos registrieren</button>
                </div>
            </div>
        </div>
    )
}

export default LoginModal
