import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import Dropdown from './Dropdown'

const SingleSelect = props => {
    const [value, setValue] = useState('')
    const [options, setOptions] = useState(props.options)
    const [visibleOptions, setVisibleOptions] = useState(props.options)

    const [hasFocus, setHasFocus] = useState(false)
    const [ignoreBlur, setIgnoreBlur] = useState(false)

    const valueInput = useRef(null)

    const handleValueChange = e => {
        setValue(e.target.value)
    }

    const handleSelection = item => {
        setValue(item)
        setIgnoreBlur(false)
        setHasFocus(false)
        props.onChange(item)
    }

    const handleFocus = () => {
        setHasFocus(true)
    }

    const handleBlur = () => {
        if (ignoreBlur) {
            valueInput.current.focus()
        } else {
            if (!value) {
                props.onChange('')
            }

            setHasFocus(false)
            setValue(!value ? '' : props.selection || '')
        }
    }

    const handleKeyDown = event => {
        if (event.key === 'Tab') {
            setIgnoreBlur(false)
        }
    }

    const handleClear = () => {
        props.onChange('')
        setValue('')
        setIgnoreBlur(false)
        valueInput.current.focus()
    }

    const fetchOptions = async () => {
        if (!props.source) return

        const {data} = await axios.get(props.source)

        setOptions(data)
        setVisibleOptions(data)
    }

    useEffect(() => {
        setVisibleOptions(options.filter(c => c.toLowerCase().startsWith(value.toLowerCase())))
    }, [value])

    useEffect(() => {
        setValue(props.selection)
    }, [props.selection])

    useEffect(() => {
        setOptions(props.options)
        setVisibleOptions(props.options)
    }, [props.options])

    useEffect(() => {
        fetchOptions()
    }, [])

    useEffect(() => setIgnoreBlur(false), [visibleOptions])

    return (
        <div className={`form-group input-group ${hasFocus ? 'has-focus' : ''}`}>
            {props.prefix && <span className={`icon-${props.prefix}`} onClick={() => valueInput.current.focus()}/>}
            <input
                type="text"
                ref={valueInput}
                value={value}
                placeholder={props.placeholder}
                onChange={handleValueChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                className={props.bold ? 'bold' : ''}
            />
            {!!value && (
                <span
                    className="icon-times-circle"
                    onMouseEnter={() => setIgnoreBlur(true)}
                    onMouseLeave={() => setIgnoreBlur(false)}
                    onClick={handleClear}/>
            )}
            {props.suffix && <span className={`icon-${props.suffix}`}/>}
            {!!(hasFocus && visibleOptions.length) && (
                <Dropdown
                    items={visibleOptions}
                    onMouseEnter={() => setIgnoreBlur(true)}
                    onMouseLeave={() => setIgnoreBlur(false)}
                    onSelect={item => handleSelection(item) || setIgnoreBlur(false)}/>
            )}
        </div>
    )
}

SingleSelect.defaultProps = {
    options: [],
    onChange: () => {
    }
}

export default SingleSelect
