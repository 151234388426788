import React from 'react'

const ImpressumModal = props => {
    return (
        <div className="modal-container">
            <div className="modal-wrapper">
                <span className="icon-times-circle-outline" onClick={() => props.onClose()}/>
                <div className="modal-body">
                    <h1>Impressum</h1>
                    <h2>Herausgeber</h2>
                    <p>
                        <div>EMH Schweizerischer Ärzteverlag AG</div>
                        <div>Farnsburgerstrasse 8</div>
                        <div>CH-4132 Muttenz</div>
                        <div>Tel. +41 (0)61 467 85 55</div>
                        <div>Fax +41 (0)61 467 85 56</div>
                        <div>E-Mail: <a href="mailto:verlag@emh.ch">verlag@emh.ch</a></div>
                        <div><a href="http://emh.ch" target="_blank">www.emh.ch</a></div>
                    </p>

                    <h2>Redaktion</h2>
                    <p>Für die Redaktion der Website ist die Gruppe Marketing & Kommunikation der EMH verantwortlich.</p>
                    <p>Kontakt: <a href="mailto:events@emh.ch">events@emh.ch</a></p>
                </div>
            </div>
        </div>
    )
}

export default ImpressumModal
