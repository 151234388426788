import React, { useState, useRef, useEffect } from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import Dropdown from './Dropdown'

const getAddressComponent = (components, field) => {
    const match = components.filter(component => component.types.indexOf(field) !== -1)
    return match.length ? match[0].long_name : ''
}

const FloatingAddressBox = props => {
    const [ value, setValue ] = useState('')
    const [ hasFocus, setHasFocus ] = useState(false)
    const [ ignoreBlur, setIgnoreBlur ] = useState(false)
    const valueInput = useRef(null)

    const handleValueChange = e => {
        setValue(e)
    }

    const handleSelection = async item => {
        setValue(item)
        setIgnoreBlur(false)
        setHasFocus(false)

        const [ result ] = await geocodeByAddress(item)
        const { lat, lng } = await getLatLng(result)

        props.onChange({
            street: (getAddressComponent(result.address_components, 'route') + ' ' + getAddressComponent(result.address_components, 'street_number')).trim(),
            city: getAddressComponent(result.address_components, 'locality'),
            postalcode: getAddressComponent(result.address_components, 'postal_code'),
            country: getAddressComponent(result.address_components, 'country'),
            latitude: lat,
            longitude: lng
        })
    }

    const handleFocus = () => {
        setHasFocus(true)
    }

    const handleBlur = () => {
        if (ignoreBlur) {
            valueInput.current.focus()
        } else {
            setHasFocus(false)
            setValue(!value ? '' : props.selection ?  getAddressString() : '')
        }
    }

    const getAddressString = () => {
        const parts = []
        if (props.selection.street)
            parts.push(`${props.selection.street}`.trim())
        if (props.selection.postalcode || props.selection.city)
            parts.push(`${props.selection.postalcode} ${props.selection.city}`.trim())
        parts.push(`${props.selection.country}`.trim())
        parts.filter(e => e)
        return parts.join(', ')
    }

    useEffect(() => {
        setValue(props.selection ? getAddressString() : '')
    }, [ props.selection ])

    return (
        <div id={props.id}>
            <PlacesAutocomplete
                value={value}
                onChange={handleValueChange}>
                {({ getInputProps, suggestions }) => (
                    <>
                        <div className={`floating-group address-group ${value || hasFocus ? 'has-value' : ''} ${props.error ? 'validation-error' : ''}`} onClick={() => !hasFocus && valueInput.current.focus()}>
                            <input
                                type="text"
                                ref={valueInput}
                                value={value}
                                onChange={getInputProps().onChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur} />
                            <div className="placeholder">
                                {props.placeholder}
                            </div>
                            <span className="icon-marker" />
                            {!!(hasFocus && suggestions.length) && (
                                <Dropdown
                                    items={suggestions.map(s => s.description)}
                                    onSelect={handleSelection}
                                    onMouseEnter={() => setIgnoreBlur(true)}
                                    onMouseLeave={() => setIgnoreBlur(false)} />
                            )}
                        </div>
                        <div className="validation-text">
                            {props.error}
                        </div>
                    </>
                )}
            </PlacesAutocomplete>
        </div>

    )
}

FloatingAddressBox.defaultProps = {
    onChange: () => {}
}

export default FloatingAddressBox
