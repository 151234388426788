import React from 'react'
import useClickOutside from '../hooks/ClickOutside'

const Popup = ({ icon, children, onClick }) => {
    const [ isVisible, setIsVisible ] = useClickOutside('tooltip-content')

    return (
        <span
            className="tooltip popup"
            onClick={() => setIsVisible(state => !state)}>
            <span className={`icon-${icon}`} />
            <div className={`tooltip-content ${isVisible ? 'is-visible' : ''}`}>
                {children}
            </div>
        </span>
    )
}

Popup.defaultProps = {
    onClick: () => {}
}

export default Popup
