export const defaultFormData = {
    title: '',
    company: '',
    room: '',
    venue: '',
    contact: '',
    phone: '',
    mail: '',
    description: '',
    deadline: null,
    start: null,
    end: null,
    startTime: '',
    endTime: '',
    signup: '',
    speakers: '',
    website: '',
    printSummary: '',
    address: null,
    type: '',
    departments: [],
    languages: [],
    program: '',
    credits: [],
    premium: '',
    highlightOnline: false,
    highlightWeb: false,
    issue: '',
    dataNotice: []
}

export const testFormData = {
    title: 'This is the title',
    company: 'What AG',
    room: '1A',
    venue: 'There and back again',
    contact: 'Mr. Contact',
    phone: '+15186486',
    mail: 'you@me.com',
    description: 'This is the description',
    deadline: new Date('2019-12-01'),
    start: new Date('2019-12-12'),
    end: new Date('2019-12-12'),
    startTime: '09:00',
    endTime: '16:00',
    signup: 'www.url.ch',
    speakers: 'Mr. Pink',
    website: 'www.what.com',
    printSummary: 'This is the print summary',
    address: {
        city: "Basel",
        country: "Switzerland",
        latitude: 47.5562851,
        longitude: 7.61275999999998,
        postalcode: "4052",
        street: "Farnsburgerstrasse",
        streetNr: "8"
    },
    type: 'Kongress',
    departments: ['Radiologie'],
    languages: ['Deutsch'],
    program: 'www.sup.com',
    credits: [
        {
            "confirmed": true,
            "count": "10",
            "association": "SGAIM",
            "area": "Allgemeine Innere Medizin",
            "status": "Bestätigt"
        }
    ],
    premium: 'free'
}

export const defaultUploadData = {
    preview: null,
    desktop: null,
    mobile: null,
    printFile: null
}

export const defaultCredit = {
    confirmed: false,
    count: '',
    association: '',
    area: '',
    status: 'Nicht bestätigt'

}

export const languageOptions = ['Deutsch', 'Französisch', 'Italienisch', 'Englisch']

export const typeOptions = {
    congress: 'Kongress',
    seminar: 'Seminar',
    workshop: 'Workshop',
    online: 'Online'
};
