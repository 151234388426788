import React, { useState, useEffect } from 'react'

const Paginate = props => {
    const [ items, setItems ] = useState(props.items.slice(0, props.itemsPerPage))
    const [ maxPages, setMaxPages ] = useState(1)
    const [ currentPage, setCurrentPage ] = useState(0)

    useEffect(() => {
        setItems(props.items.slice(props.itemsPerPage * currentPage, props.itemsPerPage * currentPage + props.itemsPerPage))
    }, [ currentPage, props.items ])

    useEffect(() => {
        setMaxPages(Math.ceil(props.items.length / props.itemsPerPage))
    }, [ items ])

    return (
        <>
            {items.map(props.itemRenderer)}
            {props.items.length > props.itemsPerPage && (
                <ul className="pagination">
                    <li>
                        <button className={currentPage === 0 ? 'is-active' : ''} onClick={() => setCurrentPage(0)}>1</button>
                    </li>
                    {renderItermediaryPages(currentPage, maxPages, setCurrentPage)}
                    <li>
                        <button className={currentPage === maxPages - 1 ? 'is-active' : ''} onClick={() => setCurrentPage(maxPages - 1)}>{maxPages}</button>
                    </li>
                </ul>
            )}
        </>
    )
}

Paginate.defaultProps = {
    items: [],
    itemsPerPage: 10
}

const renderItermediaryPages = (currentPage, maxPages, setCurrentPage) => {
    let pages = []

    let start = Math.max(currentPage - 1, 2)
    const end = Math.min(start + 4, maxPages - 1)

    if (end - start < 4) {
        start = Math.max(end - 4, 2)
    }

    if (start > 2) {
        pages = [ ...pages, <li key="start-dots">…</li> ]
    }

    for (let i = start; i <= end; i++) {
        pages = [ ...pages, (
            <li key={i}>
                <button className={currentPage === i - 1 ? 'is-active' : ''} onClick={() => setCurrentPage(i - 1)}>{i}</button>
            </li>
        ) ]
    }

    if (end < maxPages - 1) {
        pages = [ ...pages, <li key="end-dots">…</li> ]
    }

    return pages
}

export default Paginate
