import React, { useEffect } from 'react'
import Checkbox from './Checkbox'

const RadioGroup = props => {
    const handleSelection = item => {
        props.onChange(item)
    }

    useEffect(() => {
        props.onChange(props.options[0])
    }, [])

    if (!props.options.length) return

    return (
        <div id={props.id}>
            <div className={`checkboxes ${props.error ? 'validation-error' : ''}`}>
                {props.options.map(o => (
                    <Checkbox
                        key={o}
                        label={o}
                        selected={props.selection === o}
                        onChange={handleSelection} />
                ))}
            </div>
            <div className="validation-text">
                {props.error}
            </div>
        </div>
    )
}

RadioGroup.defaultProps = {
    options: [],
    onChange: () => {}
}

export default RadioGroup
