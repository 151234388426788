import React, {useEffect, useState} from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import Manager from "./pages/Manager";
import Profile from "./pages/Profile";
import EventForm from "./pages/EventForm";
import Detail from "./pages/Detail";
import RecoverSetPasswordModal from "./modals/RecoverSetPasswordModal";
import Search from "./pages/Search";
import Admin from "./pages/Admin/Admin";
import Review from "./pages/Admin/Review";
import DetailAdmin from "./pages/Admin/Detail";
import Events from "./pages/Admin/Events";
import EventFormAdmin from "./components/EventForm";
import Clients from "./pages/Admin/Clients";
import {SnackbarProvider, useSnackbar} from "notistack";
import axios from "axios";
import {CookiesNotice} from "./components/CookiesNotice";
import {MigrationNotice} from "./components/MigrationNotice";

import useHiddenNotice from './hooks/Cookie'
import { createGlobalState } from 'react-hooks-global-state';
import ReactGA from 'react-ga';
import useGoogleAnalytics, {pageView} from './hooks/GoogleAnalytics'
import { createBrowserHistory } from 'history';

const initialState = { notificationsDirty: false}
const { GlobalStateProvider, useGlobalState } = createGlobalState(initialState);
export {useGlobalState}

const history = createBrowserHistory();
history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});


const snackbarOptions = {
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
    autoHideDuration: 4500
}

const App = () => {
    const [ noticeHidden, setNoticeHidden ] = useHiddenNotice()
    const [ migrationNoticeHidden, setMigrationNoticeHidden ] = useState(false)

    const [ emitTrackingEvent, initializeReactGA ] = useGoogleAnalytics()

    useEffect(() => {
        initializeReactGA()
        // pageView()
    },[])

    return (
        <>
            <GlobalStateProvider>
                <SnackbarProvider {...snackbarOptions}>
                    <BrowserRouter>
                        <Route path="/manager" exact component={Manager} />
                        <Route path="/manager/profile" exact component={Profile} />
                        <Route path="/manager/event/:id?" exact component={EventForm} />
                        <Route path="/event/:id" exact component={Detail} />
                        <Route path="/recovery/:token" exact component={RecoverSetPasswordModal} />
                        <Route path="/" exact component={Search} />
                        <Route path="/admin" render={() =>
                            (<Admin>
                                <Switch>
                                    <Route path="/admin/review" exact component={Review} />
                                    <Route path="/admin/review/event/:id?" exact component={DetailAdmin} />
                                    <Route path="/admin/events" exact component={Events} />
                                    <Route path="/admin/event/:id?" exact component={EventFormAdmin} />
                                    <Route path="/admin/clients" exact component={Clients} />
                                    <Redirect to="/admin/review" />
                                </Switch>
                            </Admin>)
                        }>
                        </Route>
                    </BrowserRouter>
                </SnackbarProvider>
                {!noticeHidden && (
                    <CookiesNotice onHide={() => setNoticeHidden(true)}/>
                 )}
                {/*{!migrationNoticeHidden && (*/}
                    {/*<MigrationNotice onHide={() => setMigrationNoticeHidden(true)}/>*/}
                {/*)}*/}
            </GlobalStateProvider>
        </>
    )
}

export default App
