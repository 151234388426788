import React, {useState, useEffect} from 'react'

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']

const DatePicker = props => {
    const [dropdownVisible, setDropdownVisible] = useState(false)
    const [firstSelected, setFirstSelected] = useState(null)

    const handleSelection = (start, end) => {

        if (start && end) {
            let startdate = parseInt(start.year + '' + start.month)
            let enddate = parseInt(end.year + '' + end.month)

            if (startdate > enddate) {
                [start, end] = [end, start]
            }
        }

        props.onChange({start, end})
        setFirstSelected(null)
    }

    const handleFirstSelection = (s) => {
        setFirstSelected(s)
    }

    useEffect(() => {
        if (dropdownVisible) {
            const clickHandler = e => {
                let target = e.target

                while (target && target.classList && !target.classList.contains('dropdown')) {
                    target = target.parentNode
                }

                if (target === document) {
                    setDropdownVisible(false)
                }
            }

            document.addEventListener('click', clickHandler)

            return () => {
                document.removeEventListener('click', clickHandler)
            }
        }
    }, [dropdownVisible])

    useEffect(() => {
        console.log(firstSelected)
    }, [firstSelected])

    return (
        <div
            className={`form-group date-group ${dropdownVisible ? 'has-focus' : ''} ${props.selection.start && props.selection.end ? 'has-value' : ''}`}>
            {props.prefix && <span className={`icon-${props.prefix}`} onClick={() => setDropdownVisible(true)}/>}
            <div className="date-selection" onClick={() => setDropdownVisible(true)}>
                <span
                    className={`from ${firstSelected || props.selection.start ? 'bold' : ''}`}>{props.selection.start ? `${months[props.selection.start.month]} ${(props.selection.start.year + '').substr(2)}` : firstSelected ? `${months[firstSelected.month]} ${(firstSelected.year + '').substr(2)}` : 'Von'}</span>
                <span className="arrow">{' '} <i className="icon-arrow-left"></i> {' '}</span>
                <span
                    className={`to ${props.bold.end ? 'bold' : ''}`}>{props.selection.end ? `${months[props.selection.end.month]} ${(props.selection.end.year + '').substr(2)}` : 'Bis'}</span>
            </div>
            {props.selection.start && props.selection.end && (
                <span className="icon-times-circle"
                      onClick={() => handleSelection(null, null) || setDropdownVisible(false)}></span>
            )}
            {props.suffix && <span className={`icon-${props.suffix}`} onClick={() => setDropdownVisible(true)}/>}
            {dropdownVisible && (
                <DateDropdown
                    onSelect={handleSelection}
                    onClose={() => setDropdownVisible(false)}
                    onFirstSelected={handleFirstSelection}
                />
            )}
        </div>
    )
}

DatePicker.defaultProps = {
    selection: {},
    onChange: () => {
    }
}

const currentYear = (new Date()).getFullYear()
const currentMonth = (new Date()).getMonth()

const DateDropdown = props => {
    const [start, setStart] = useState({})
    const [end, setEnd] = useState({})
    const [displayedYear, setDisplayedYear] = useState((new Date()).getFullYear())

    const select = (month, year) => {
        if (!props.onSelect) return

        if (!start.month) {
            setStart({month, year})
            props.onFirstSelected({month, year})
        } else if (end.year >= start.year || (end.year === start.year && end.month >= start.month)) {
            props.onSelect(start, {month, year})
            reset()
        }
    }

    const reset = () => {
        setDisplayedYear(currentYear)
        setStart({})
        setEnd({})
        props.onClose()
    }

    const isDisabled = month => displayedYear === currentYear && month < currentMonth

    const moveYear = year => {
        setDisplayedYear(year)
        setEnd({})
    }

    return (
        <div className="dropdown">
            <div className="controls">
                <button onClick={() => moveYear(displayedYear - 1)} disabled={displayedYear === currentYear}>
                    <span className="icon-chevron-left"/>
                </button>
                <span className="current-year">{displayedYear}</span>
                <button onClick={() => moveYear(displayedYear + 1)}>
                    <span className="icon-chevron-right"/>
                </button>
            </div>
            <ul className="months">
                {months.map((month, index) => (
                    <li
                        key={index}
                        className={`${isDisabled(index) ? 'is-disabled' : ''} ${(index >= start.month && index <= end.month) || (end.year > start.year && index <= end.month) ? 'is-highlighted' : ''} ${start.year === displayedYear && start.month === index ? 'is-root' : ''}`}
                        onClick={!isDisabled(index) ? () => select(index, displayedYear) : null}
                        onMouseEnter={() => setEnd({month: index, year: displayedYear})}>
                        {month}
                    </li>
                ))}
            </ul>
            <button className="close" onClick={reset}>Abbrechen</button>
        </div>
    )
}

DateDropdown.defaultProps = {
    onClose: () => {
    },
    onSelect: () => {
    }
}

export default DatePicker
