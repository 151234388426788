import React, { useState, useEffect } from 'react'
import FloatingTextBox from '../components/FloatingTextBox'
import NewPasswordBox from '../components/NewPasswordBox'
import {PasswordHint} from '../components/PasswordHint'
import {validateEmail} from '../pages/Profile'
import axios from 'axios'

import useNotifications, {notifications} from "../hooks/Notifications";

// axios.interceptors.request.use(request => {
//     console.log('Server Request', request)
//     return request
// })

const defaultFormData = {
    email: '',
    password: '',
    passwordRepeat: ''
}

const RegistrationModal = props => {
    const [ formData, setFormData ] = useState(defaultFormData)
    const [ passwordRepeatError, setPasswordRepeatError] = useState(false)
    const [ emailError, setEmailError] = useState(null)
    const [ passwordError, setPasswordError] = useState(null)

    const [createNotification, renderNotifications, renderIfDirty] = useNotifications()

    const augmentFormData = field => data => setFormData(state => ({ ...state, [field]: data }))

    // useEffect(() => {
    //     if (formData.passwordRepeat.length >= 10)
    //     validatePasswordRepeat()
    // }, [ formData.passwordRepeat ])

    const postAndClose = async data => {
        if (!validateEmail(formData.email)) {
            setEmailError("Bitte geben sie eine gültige E-Mail Adresse ein")
        }

        console.log('passwordError', passwordError)
        if (passwordError)
            return

        if (!validatePasswordRepeat()) {
            setPasswordRepeatError(true)
            return
        }


        else if (!passwordRepeatError) {
            const res = await axios.post('/api/user', { data })
                .then(
                    ok => {
                        createNotification(notifications.accountCreated)
                        props.onRegistered()
                    }).catch(error => {
                        // console.log('error post /api/user:', error.response)
                        setEmailError('Es existiert bereits ein Konto mit dieser E-Mail Adresse')
                })
        }
    }

    const validatePasswordRepeat = () => {
        return formData.password && formData.passwordRepeat && formData.password === formData.passwordRepeat
    }

    return (
        <div className="modal-container">
            <div className="modal-wrapper">
                <span className="icon-times-circle-outline" onClick={() => props.onClose()} />
                <div className="modal-body">
                    <h1>Registrieren</h1>
                    <h2>Account erstellen</h2>
                    <FloatingTextBox
                        value={formData.email}
                        name="new-email"
                        placeholder="E-Mail Adresse"
                        onChange={augmentFormData('email')}
                        error={emailError || ''}
                    />
                    <PasswordHint/>
                    <NewPasswordBox
                        value={formData.password}
                        name="new-password"
                        placeholder="Passwort"
                        onChange={augmentFormData('password')}
                        valid={val => setPasswordError(!val)}
                        />
                    <FloatingTextBox
                        value={formData.passwordRepeat}
                        placeholder="Passwort wiederholen"
                        mask
                        onChange={augmentFormData('passwordRepeat')}
                        error={passwordRepeatError ? 'Passwörter stimmen nicht überein':''}
                    />
                    <p className="validation-text enabled">ValidationText</p>
                    <p>Bitte aktivieren Sie Ihren Account mit dem Aktivierungslink der Ihnen per E-Mail gesendet wird. </p>
                    <div className="form-options">
                        <button data-button="flat" onClick={props.switchModal}>Zurück zum Login</button>
                        <button data-button onClick={() => postAndClose(formData)}>Registrieren</button>
                    </div>
                    <h2>Haben Sie Fragen?</h2>
                    <p>Für Rückfragen zu <em>Swiss Medical Events</em> wenden Sie sich bitte an: </p>
                    <a href="mailto:events@emh.ch"><span className="icon-envelope" />events@emh.ch</a>
                </div>
            </div>
        </div>
    )
}

export default RegistrationModal
