import React, { useState, useEffect } from 'react'

const FloatingTimePicker = props => {
    const [ hasFocus, setHasFocus ] = useState(false)

    const handleSelection = value => {
        props.onChange(value)
        setHasFocus(false)
    }

    useEffect(() => {
        if (hasFocus) {
            const clickHandler = e => {
                let target = e.target

                while (target && target.classList && !target.classList.contains('dropdown')) {
                    target = target.parentNode
                }

                if (target === document) {
                    setHasFocus(false)
                }
            }

            document.addEventListener('click', clickHandler)

            return () => {
                document.removeEventListener('click', clickHandler)
            }
        }
    }, [ hasFocus ])

    return (
        <div id={props.id}>
            <div className={`floating-group ${props.value ? 'has-value' : ''} ${props.error ? 'validation-error' : ''}`} onClick={() => !hasFocus && setHasFocus(true)}>
                <div className="input">
                    {props.value}
                </div>
                <span className="icon-calendar" />
                <div className="placeholder">
                    {props.placeholder}
                </div>
                {hasFocus && (
                    <TimeDropdown
                        value={props.value}
                        onSelect={handleSelection}
                        onClose={() => setHasFocus(false)} />
                )}
            </div>
            <div className="validation-text">
                {props.error}
            </div>
        </div>
    )
}

FloatingTimePicker.defaultProps = {
    onChange: () => {}
}

const currentDate = new Date()

const TimeDropdown = props => {
    const [ valueHours, valueMinutes ] = props.value ? props.value.split(':').map(v => parseInt(v, 10)) : [ null, null ]
    const [ currentHours, setCurrentHours ] = useState(valueHours || currentDate.getHours())
    const [ currentMinutes, setCurrentMinutes ] = useState(valueMinutes || Math.ceil(currentDate.getMinutes() / 15) * 15 % 60)

    const moveHour = direction => {
        setCurrentHours(state => (direction === 'inc' ? state + 1 : state + 23) % 24)
    }

    const moveMinutes = direction => {
        setCurrentMinutes(state => (direction === 'inc' ? state + 15 : state + 45) % 60)
    }

    const reset = () => {
        props.onClose('')
    }

    const apply = () => {
        props.onSelect(`${currentHours.toString().padStart(2, '0')}:${currentMinutes.toString().padStart(2, '0')}`)
    }

    return (
        <div className="dropdown time-picker">
            <div className="controls">
                <button onClick={() => moveHour('inc')}>
                    <span className="icon-arrow-down" />
                </button>
                <button onClick={() => moveMinutes('inc')}>
                    <span className="icon-arrow-down" />
                </button>
            </div>
            <div className="current-time">
                <span>{currentHours.toString().padStart(2, '0')}</span> : <span>{currentMinutes.toString().padStart(2, '0')}</span>
            </div>
            <div className="controls">
                <button onClick={() => moveHour('dec')}>
                    <span className="icon-arrow-down" />
                </button>
                <button onClick={() => moveMinutes('dec')}>
                    <span className="icon-arrow-down" />
                </button>
            </div>
            <div className="actions">
                <button className="close" onClick={reset}>Abbrechen</button>
                <button className="close" onClick={apply}>Anwenden</button>
            </div>
        </div>
    )
}

TimeDropdown.defaultProps = {
    onSelect: () => {}
}

export default FloatingTimePicker
