import React from 'react'

const RangeSlider = props => {
    const handleValueChange = e => {
        props.onChange(e.target.value)
    }

    return (
        <div className="form-group range-group">
            {props.prefix && <span className={`icon-${props.prefix}`} />}
            <div className="range-wrapper">
                <input
                    type="range"
                    value={props.value}
                    min={props.min || 0}
                    max={props.max || 100}
                    onChange={handleValueChange} />
            </div>
            <div className="range-value">{props.transform ? props.transform(props.value) : props.value}</div>
            {props.suffix && <span className={`icon-${props.suffix}`} />}
        </div>
    )
}

RangeSlider.defaultProps = {
    onChange: () => {}
}

export default RangeSlider
