import React, {useState} from 'react'
import Dropzone from 'react-dropzone'
import pdfIcon from "../images/pdf.svg";

const ImageUpload = props => {

    const [ error, setError] = useState(false)
    const [isPdf, setIsPdf] = useState('false')

    const handleDrop = files => {
        if (files.length) {
            console.log('image file ', files[0]);
            props.onSelect(files[0])
            // setIsPdf(files[0].type === 'application/pdf')
        }
    }

    const handleClear = event => {
        event.stopPropagation()
        props.onClear()
        props.onRemove('preview')
    }

    const handleRejected = () => {
        console.log('drop rejected')
        setError("Die Datei darf maximal 1 Megabyte gross sein")
    }

    console.log('image upload', props)
    return (
        <Dropzone
            accept={['image/jpeg', 'image/png']}
            maxSize={1000000}
            multiple={false}
            onDrop={handleDrop}
            onDropRejected={handleRejected}
        >
            {({ getRootProps, getInputProps }) => (
                <>
                    <div className={`upload ${props.error || error ? 'validation-error': ''}`} {...getRootProps()}>
                        <input {...getInputProps()} />
                        {props.file ? (
                            <>
                                <img src={props.file.preview} alt="Upload" />
                                <div className="clear" onClick={handleClear}>
                                    <span className="icon-times-circle" />
                                </div>
                            </>
                        ) : (
                            <button data-button>Hochladen</button>
                        )}
                    </div>
                    <div className="validation-text">
                         {error ? error : props.error}
                    </div>
                </>
            )}
        </Dropzone>
    )
}

ImageUpload.defaultProps = {
    onSelect: () => {},
    onClear: () => {}
}

export default ImageUpload
