import React, {useState, useEffect} from 'react'
import FloatingTextBox from '../components/FloatingTextBox'
import NewPasswordBox from '../components/NewPasswordBox'
import {PasswordHint} from '../components/PasswordHint'

import axios from 'axios'

const defaultFormData = {
    username: ''
}

const RecoverSetPasswordModal = props => {
    const [formData, setFormData] = useState(defaultFormData)
    const [passwordRepeatError, setPasswordRepeatError] = useState(null)
    const [ passwordError, setPasswordError] = useState(null)

    const token = props.match.params.token


    const checkAndRequest = async () => {
        if (passwordError)
            return

        if (formData.password !== formData.passwordRepeat) {
            setPasswordRepeatError(true)
            return
        }

        let data = {token: token, password: formData.password, passwordRepeat: formData.passwordRepeat}

        await axios.post('/api/user/password', {data: data})
            .then(
                ok => {
                    props.history.push('/')
                }).catch(error => {
            })
    }

    const augmentFormData = field => data => setFormData(state => ({...state, [field]: data}))

    return (
        <div className="modal-container">
            <div className="modal-wrapper">
                <span className="icon-times-circle-outline" onClick={() => props.history.push('/')}/>
                <div className="modal-body">
                    <h1>Neues Passwort</h1>
                    <PasswordHint/>
                    <NewPasswordBox
                        value={formData.password}
                        onChange={augmentFormData('password')}
                        valid={val => setPasswordError(!val)}
                    />
                    <FloatingTextBox
                        value={formData.passwordRepeat}
                        placeholder="Neues Passwort wiederholen"
                        mask
                        onChange={augmentFormData('passwordRepeat')}
                        error={passwordRepeatError ? 'Passwörter stimmen nicht überein' : ''}
                    />
                    <div className="form-options">
                        <button data-button="flat" onClick={() => props.history.push('/')}>Zurück zum Login</button>
                        <button data-button onClick={() => checkAndRequest()}>Zurücksetzen</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecoverSetPasswordModal
