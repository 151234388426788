import React, {useEffect, useState} from 'react'
import { withRouter } from 'react-router-dom'
import axios from "axios";
import {Card, CardTitle} from "reactstrap";
import DataTable from "react-data-table-component";
import {Button, Chip, FontIcon, SelectField, TextField} from "react-md";
import {DateRangePicker} from "react-dates";
import {premiumLabel} from "../../components/Admin/EventList";
import {DatePickerPresets} from '../../components/Admin/ReviewList'
import * as moment from 'moment';


const Clients = ({history}) => {
    const [ data, setData] = useState([]);
    const [ loading, setLoading] = useState(true)

    const [visibleData, setVisibleData] = useState([]);

    const [filter, setFilter] = useState(defaultFilterData)

    useEffect(() => {
        fetchEvents()
    }, [])

    useEffect(() => {
        console.log('filter!!', filter)
        filterEvents(filter)
    }, [filter])

    const fetchEvents = async () => {
        const { data } =  await axios.get('/api/admin/events/')

        const results = formatData(data)
        console.log(results)

        setData(results)
        setVisibleData(results)
        setLoading(false)

    }


    const filterEvents = (filter) => {
        let visibleData = data.filter(e => {
            let premiumCriteria = filter.premium ? e.premium === filter.premium : true

            let queryEmailCriteria = filter.query ? (e.email.search(filter.query) > -1) : true
            let queryFirstNameCriteria = filter.query ? (e.firstname.search(filter.query)) > -1 : true
            let queryLastNameCriteria = filter.query ? (e.lastname.search(filter.query) > -1) : true
            let queryStreetCriteria = filter.query ? (e.street.search(filter.query) > -1) : true
            let queryCityCriteria = filter.query ? (e.city.search(filter.query) > -1) : true
            let queryCriteria = queryEmailCriteria
                || queryFirstNameCriteria
                || queryLastNameCriteria
                || queryStreetCriteria
                || queryCityCriteria

            let startCriteria = filter.startDate ? new Date(e.creationDate) >= filter.startDate.toDate() : true
            let endCriteria = filter.endDate ? new Date(e.creationDate) <= filter.endDate.toDate() : true


            return premiumCriteria
                && queryCriteria
                && startCriteria
                && endCriteria

        })
        console.log('visibleData', visibleData)
        setVisibleData(visibleData)
    }

    const premiumInstances = [...new Set(data.map(({premium}) => premium))]
    const premiumOptions = premiumInstances.map((e) => ({value: e, label: e.charAt(0).toUpperCase() + e.slice(1)}))


    const formatData = (data) => {
        return data.map(e => {
            return {
                creationDate: e.creationDate,
                premium: e.premium,
                salutation: e.user.salutation,
                title: e.user.title,
                firstname: e.user.firstname,
                lastname: e.user.lastname,
                phone: e.user.phone,
                email: e.user.email,
                organisation: e.user.organisation,
                street: e.user.street,
                postalcode: e.user.postalcode,
                city: e.user.city,
                country: e.user.country
            }
        })
    }

    const handleRowClicked = (event) => {
        console.log('clicked: ', event)
        history.push(`/admin/review/event/${event.webId}`, {event})
    }

    const premiumColor = (premium => {
        switch (premium) {
            case 'onlinePlus':
                return '#C8E6C9'
            case 'print':
                return '#B3E5FC'
            case 'printPlus':
                return '#E1BEE7'
        }
    })


    const columns = [
        {
            name: 'Event angelegt',
            selector: 'creationDate',
            format: row => {
                const date = row.creationDate.substring(0, row.creationDate.indexOf(' '))
                const dateParts = date.split('-');
                return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`
            },
            sortable: true,
            width: '150px'
        },
        {
            name: 'Premium',
            selector: 'premium',
            sortable: true,
            cell: row => (row.premium !== 'free' && (
                    <div className="colorbox"
                         style={{backgroundColor: premiumColor(row.premium)}}>{premiumLabel(row.premium)}</div>
                )
            ),
            width: '100px'

        },
        {
            name: 'Anrede',
            selector: 'salutation',
            sortable: true,
            width: '60px'

        },
        {
            name: 'Titel',
            selector: 'title',
            sortable: true,
            width: '60px'
        },
        {
            name: 'Vorname',
            selector: 'firstname',
            sortable: true,
        },
        {
            name: 'Nachname',
            selector: 'lastname',
            sortable: true
        },
        {
            name: 'E-Mail',
            selector: 'email',
            sortable: true
        },
        {
            name: 'Telefon',
            selector: 'phone',
            sortable: true
        },
        {
            name: 'Firma',
            selector: 'organisation',
            sortable: true,
        },
        {
            name: 'Strasse',
            selector: 'street',
            sortable: true
        },
        {
            name: 'Postleitzahl',
            selector: 'postalcode',
            sortable: true
        },
        {
            name: 'Ort',
            selector: 'city',
            sortable: true
        },
        {
            name: 'Land',
            selector: 'country',
            sortable: true
        }
    ]

    return (
        <div className="site-content">
            <Card body>
                {!loading && (data.length ? <CardTitle>Alle Kunden</CardTitle> : '')}

                <DataTable
                    columns={columns}
                    data={visibleData}
                    defaultSortField="creationDate"
                    defaultSortAsc={false}
                    // onRowClicked={handleRowClicked}
                    // highlightOnHover
                    progressPending={loading}
                    progressComponent={<div>Kunden werden geladen...</div>}
                    noDataComponent={<div>Keine Kunden gefunden</div>}
                    pagination
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[10, 50, 100, 300, 1000]	}
                    paginationComponentOptions={{rowsPerPageText: 'Einträge pro Seite:', rangeSeparatorText: 'von'}}
                    subHeader
                    subHeaderComponent={
                        data.length ? (
                            <Filters onChange={criteria => setFilter({...filter, ...criteria})}
                                 onClear={() => setFilter(defaultFilterData)}
                                 premiumOptions={premiumOptions}
                                 filterState={filter}
                        />
                        ) : ''

                    }
                />
            </Card>
        </div>
    )
}

const defaultFilterData = {
    premium: '',
    query: '',
    startDate: '',
    endDate: ''
}

const Filters = props => {
    const [focusedInput, setFocusedInput] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const augmentFilterData = field => data => {
        props.onChange({[field]: data})
    }

    useEffect(() => {
        props.onChange({startDate: startDate})
    }, [startDate])

    useEffect(() => {
        props.onChange({endDate: endDate})
    }, [endDate])

    return (
        <div className="filter-bar">
            {!Object.values(props.filterState).find(e => e && e !== '' ) ? (
                <div className="filter filters-label">Filter</div>
            ) : (
                <div className="filter button-clear">
                    <Button onClick={() => props.onClear() || setStartDate(null) || setEndDate(null)}>Filter löschen</Button>
                </div>
            )}
            {/*<div className="filter filter-clients-date">*/}
                {/*<DateRangePicker*/}
                    {/*small*/}
                    {/*noBorder*/}
                    {/*hideKeyboardShortcutsPanel*/}
                    {/*showClearDates={false}*/}
                    {/*// enableOutsideDays={false}*/}
                    {/*// initialStartDate={null}*/}
                    {/*// initialEndDate={null}*/}
                    {/*renderDayContents={null}*/}
                    {/*// renderCalendarInfo={<DatePickerPresets/>}*/}
                    {/*focusedInput={focusedInput}*/}
                    {/*startDate={startDate}*/}
                    {/*startDateId="id-startdate"*/}
                    {/*startDatePlaceholderText="Von"*/}
                    {/*endDatePlaceholderText="Bis"*/}
                    {/*endDate={endDate}*/}
                    {/*endDateId="id-enddate"*/}
                    {/*onDatesChange={({startDate, endDate}) => {*/}
                        {/*setStartDate(startDate) || setEndDate(endDate)*/}
                    {/*}}*/}
                    {/*onFocusChange={focusedInput => setFocusedInput(focusedInput)}*/}
                    {/*renderCalendarInfo={() => (<DatePickerPresets onChange={(start, end) => {setStartDate(moment(start)) || setEndDate(moment(end))}}></DatePickerPresets>)}*/}
                {/*/>*/}
            {/*</div>*/}
            <div className="filter filter-clients-premium">
                <SelectField
                    id="select-premium"
                    // label="Premium"
                    placeholder="Premium"
                    // className="md-cell"
                    // position={SelectField.Positions.BELOW}
                    menuItems={props.premiumOptions}
                    value={props.filterState.premium}
                    onChange={augmentFilterData('premium')}
                    inputClassName={props.filterState.premium ? 'filter-active' : ''}
                />
            </div>
            <div className="filter filter-clients-query">
                <TextField
                    id="placeholder-only-title"
                    placeholder="Textsuche"
                    leftIcon={<FontIcon>search</FontIcon>}
                    value={props.filterState.query}
                    // className="md-cell md-cell--bottom"
                    onChange={augmentFilterData('query')}
                    inputClassName={props.filterState.query ? 'filter-active' : ''}

                />
            </div>

        </div>
    )

}



export default withRouter(Clients);