import React from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'
import axios from 'axios'
import logo from "../../images/logo.svg";
// import './Header.css'


const HeaderAdmin = ({history, handleLogout, scrolled}) => {

    return (
        <header className={`site-header ${scrolled ? 'scrolled' : ''}`}>
            <div className="site-logo">
                <Link to="/admin/review">
                    <img src={logo} className="logo" alt="EMH Logo" />
                    <h1 className="serif" style={{display: scrolled ? 'none': 'block'}}>Swiss Medical Events</h1>
                    <h2 className={'header-sub-title'} style={{display: scrolled ? 'none': 'block'}}>Admin</h2>
                </Link>

            </div>
            <nav className="site-navigation">
                <ul className={`unstyled-list ${scrolled ? 'scrolled' : ''}`}>
                    <li><NavLink to="/admin/review" activeClassName="active">Überprüfen</NavLink></li>
                    <li><NavLink to="/admin/events" activeClassName="active">Veranstaltungen</NavLink></li>
                    <li><NavLink to="/admin/clients" activeClassName="active">Kunden</NavLink></li>
                    <li><a href="/admin/logout">Logout</a></li>
                </ul>
            </nav>
        </header>
    )
}

export default withRouter(HeaderAdmin)