import React, {useState, useEffect, useMemo, useCallback, createContext} from 'react'
import {Snackbar} from 'react-md';

export const SnackBarContext = createContext()

export const SnackBarProvider = ({children}) => {
    const [alerts, setAlerts] = useState([])

    useEffect(() => {
        console.log('alerts!', alerts)
    }, [alerts])

    const handleDismiss = () => {
        setAlerts(alerts.slice(1))
    }

    // const addAlert = useCallback(alert => setAlerts(alerts => [alert, ...alerts]), [])
    const addAlert = alert => console.log('alert!', alert) || setAlerts((alerts) => [alert, ...alerts])

    // const value = useMemo(() => {addAlert}, [addAlert])
    


    const value = {addAlert};

    return (
        <SnackBarContext.Provider value={value}>
            {children}
            <Snackbar
                id="snackbar"
                toasts={alerts}
                autohideTimeout={2000}
                onDismiss={handleDismiss}/>
        </SnackBarContext.Provider>
    )
}