import React, {useState} from 'react'
import Dropzone from 'react-dropzone'
import pdfIcon from '../images/pdf.svg'

const FileUpload = props => {
    const [ error, setError] = useState(false)

    const handleDrop = files => {
        if (files.length) {
            console.log('print-file', files[0]);
            props.onSelect(files[0])
        }
    }

    const handleRejected = () => {
        console.log('drop rejected')
        // setError("Die Datei darf maximal 1 Megabyte gross sein")
        setError("Die Datei konnte nicht hochgeladen werden")

    }

    const handleClear = event => {
        event.stopPropagation()
        props.onClear()
        props.onRemove('printFile')
    }

    return (
        <div id={props.id}>
            <Dropzone
                accept={['application/pdf']}
                multiple={false}
                onDrop={handleDrop}
                onDropRejected={handleRejected}
            >
                {({ getRootProps, getInputProps }) => (
                    <>
                        <div className={`upload ${props.error || error ? 'validation-error': ''}`} {...getRootProps()}>
                            <input {...getInputProps()} />
                            {props.file ? (
                                <>
                                    <img src={pdfIcon} alt="Upload" />
                                    <div className="clear" onClick={handleClear}>
                                        <span className="icon-times-circle" />
                                    </div>
                                </>
                            ) : (
                                <button data-button>Hochladen</button>
                            )}
                        </div>
                        <div className="validation-text">
                            {error ? error : props.error}
                        </div>
                    </>
                )}
            </Dropzone>

        </div>

    )
}

FileUpload.defaultProps = {
    onSelect: () => {},
    onClear: () => {}
}

export default FileUpload
