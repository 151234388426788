import React, {useState, useRef} from 'react'

const FloatingTextArea = props => {
    const [hasFocus, setHasFocus] = useState(false)
    const valueInput = useRef(null)

    const handleValueChange = e => {
        if (props.maxLength && e.target.value.length > props.maxLength) {
            return
        }

        props.onChange(e.target.value)
    }

    const handleKeyDown = event => {
        if (event.key === 'Enter' && props.preventLineBreaks) {
            event.preventDefault()
        }
    }

    return (
        <>
        <div id={props.id}
            className={`floating-group ${props.value || hasFocus ? 'has-value' : ''} ${props.error ? 'validation-error' : ''}`}
            onClick={() => valueInput.current.focus()}>
                <textarea
                    ref={valueInput}
                    value={props.value}
                    onChange={handleValueChange}
                    onKeyDown={handleKeyDown}
                    onFocus={() => setHasFocus(true)}
                    onBlur={() => setHasFocus(false)}/>
            <div className="placeholder">
                {props.placeholder}
            </div>
        </div>
        <div className="validation-text">
            {props.error}
        </div>
        <div className="help-text">
            {props.maxLength && (
                <div
                    className={props.value.length / props.maxLength === 1 ? 'is-reached' : props.value.length / props.maxLength >= 0.9 ? 'is-close' : ''}>
                    {`${props.value.length} / ${props.maxLength}`}</div>
            )}
            {props.help && <div><p>{props.help}</p></div>}

        </div>
        </>
    )
}

FloatingTextArea.defaultProps = {
    onChange: () => {
    }
}

export default FloatingTextArea
